/* eslint-disable no-shadow */
import React from 'react';
import { OutlinedInput } from '@mui/material';
import { t } from 'i18next';
import { TradePartnerStore } from '../../store/tradePartners/TradePartnerStore';
import { SelectDateTime } from '../select/SelectDatetime';
import { SelectTradePartner } from '../select/SelectTradePartner';
import styles from './Details.module.scss';

interface iTradePartnerDetailsProps {
  title: string;
  date: Date;
  tradePartner: TradePartnerStore | null;
  onTradePartnerChange: (tradePartner: TradePartnerStore | null) => void;
  onDateChange: (date: Date) => void;
}

export const TradePartnerDetails = ({
  title,
  tradePartner,
  date,
  onTradePartnerChange,
  onDateChange,
}: iTradePartnerDetailsProps) => (
  <section
    id={`TradePartner--${title} Details`}
    className={styles['SectionContainer']}
  >
    <span className={styles['SectionTitle']}>{title}</span>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.date')}:</label>
      <SelectDateTime value={date} onChange={(date) => onDateChange(date)} />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.trade_partner')}:</label>
      <SelectTradePartner
        chosenTradePartner={tradePartner}
        onChange={(tradePartner) => onTradePartnerChange(tradePartner)}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.nip')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.nip')}
        disabled
        value={tradePartner ? tradePartner.getNIP() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.regon')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.regon')}
        disabled
        value={tradePartner ? tradePartner.getREGON() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.email')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.email')}
        disabled
        value={tradePartner ? tradePartner.getEmail() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.phone_number')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.phone_number')}
        disabled
        value={tradePartner ? tradePartner.getPhoneNumber() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.contact_persons')}:</label>
      {tradePartner &&
        tradePartner.getContactPersons().map((contactPerson) => (
          <div
            key={contactPerson.getId()}
            className={styles['ContactPersonsDetails']}
          >
            <div className={styles['ContactPerson']}>
              <label className={styles['Label']}>{t('input.person')}:</label>
              <OutlinedInput
                size="small"
                placeholder={t('input.category')}
                disabled
                value={`${contactPerson.getFirstName()} ${contactPerson.getLastName()}`}
                sx={{
                  borderRadius: '0',
                  backgroundColor: 'rgba(0, 0, 0, 0.06)',
                }}
              />
            </div>
          </div>
        ))}
    </div>
  </section>
);
