/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { TruckStore } from '../store/trucksStore/TruckStore';

export const mockTruck_1: TruckStore = new TruckStore(
  1,
  'Mercedes',
  'C',
  'PL4334',
  'Red',
  'Fuel',
  2017,
  'JH4KA3160LC017215',
  dayjs().add(5, 'month').toDate(),
  dayjs().add(2, 'month').toDate()
);

export const mockTruck_2: TruckStore = new TruckStore(
  2,
  'Scania',
  'S',
  'PL4111',
  'Red',
  'Fuel',
  2015,
  'YS3ED48E5Y3070016',
  dayjs().add(5, 'month').toDate(),
  dayjs().add(2, 'month').toDate()
);

export const mockTruck_3: TruckStore = new TruckStore(
  3,
  'Scania',
  'R',
  'PL4555',
  'Blue',
  'Fuel',
  2019,
  'JN1CV6AP4CM626941',
  dayjs().add(5, 'month').toDate(),
  dayjs().add(2, 'month').toDate()
);

export const mockTruck_4: TruckStore = new TruckStore(
  4,
  'Volvo',
  'FH16',
  'PLSZG',
  'Gray',
  'Fuel',
  2022,
  '1GCCS1956Y8235348',
  dayjs().add(5, 'month').toDate(),
  dayjs().add(2, 'month').toDate()
);

export const mockTrucks: TruckStore[] = [
  mockTruck_1,
  mockTruck_2,
  mockTruck_3,
  mockTruck_4,
];

export const initTrucksLocalStore = () => {
  const initialized = localStorage.getItem('trucks');
  if (!initialized) {
    localStorage.setItem('trucks', JSON.stringify(mockTrucks));
  }
};

