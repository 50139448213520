import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  getArrayOfMonths,
  getArrayOfXShortWeekdays,
} from '../../utils/DateUtils';

interface iDatePickerProps {
  value: Date;
  onChange:
    | ((
        day: Date,
        modifiers: any,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
      ) => void)
    | undefined;
}

export const DatePicker = ({ value, onChange }: iDatePickerProps) => (
  <DayPicker
    showWeekNumbers
    month={value || new Date()}
    selectedDays={value}
    onDayClick={onChange}
    firstDayOfWeek={1}
    months={getArrayOfMonths()}
    weekdaysShort={getArrayOfXShortWeekdays()}
  />
);
