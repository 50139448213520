import React from 'react';
import dayjs from 'dayjs';
import { TransportStore } from '../../store/transportStore/TransportStore';
import styles from './PlaceAndTime.module.scss';

interface iPlaceAndTimeProps {
  transport: TransportStore;
  type: 'departure' | 'arrival';
}

export const PlaceAndTime = ({ transport, type }: iPlaceAndTimeProps) => {
  const getFormattedDate = (date: Date): string => {
    const dayJsDate = dayjs(date);
    const formattedDate = dayJsDate.format('DD.MM.YYYY - HH:mm');
    return formattedDate;
  };

  const date = getFormattedDate(
    type === 'departure'
      ? transport.getDepartureDate()
      : transport.getArrivalDate()
  );
  const company =
    type === 'departure'
      ? transport.getSourceTradePartner().getCompanyName()
      : transport.getDestinationTradePartner().getCompanyName();

  const country =
    type === 'departure'
      ? transport.getSourceTradePartner().getAddress().getCountry()
      : transport.getDestinationTradePartner().getAddress().getCountry();

  const city =
    type === 'departure'
      ? transport.getSourceTradePartner().getAddress().getCity()
      : transport.getDestinationTradePartner().getAddress().getCity();
  return (
    <div className={styles['TransportInfo']}>
      <span className={styles['Date']}>{date}</span>
      <span className={styles['Company']}>{company}</span>
      <span className={styles['Location']}>
        {country}
        ,&nbsp;
        {city}
      </span>
    </div>
  );
};
