import React from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { t } from 'i18next';
import { TransportStore } from '../../store/transportStore/TransportStore';
import { PlaceAndTime } from '../placeAndTime/PlaceAndTime';
import styles from './TransportTooltip.module.scss';

interface iTransportTooltip {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  transport: TransportStore;
}

export const TransportTooltip = ({
  transport,
  children,
}: iTransportTooltip) => {
  const getContent = () => (
    <div className={styles['TooltipContent']}>
      <header className={styles[`Header--${transport.getStatus()}`]}>
        {transport.getId()}
      </header>
      <div style={{ height: '15px' }} />
      <div className={styles['Detail']}>
        <label className={styles['Label']}>{t('tooltip.driver')}:</label>
        &nbsp;{transport.getDriver().getFirstName()}&nbsp;
        {transport.getDriver().getLastName()}
      </div>
      <div className={styles['Detail']}>
        <label className={styles['Label']}>{t('tooltip.phone_number')}:</label>
        &nbsp;{transport.getDriver().getPhoneNumber()}
      </div>
      <div className={styles['Detail']}>
        <label className={styles['Label']}>{t('tooltip.truck')}:</label>
        &nbsp;{transport.getTruck().getBrand()}&nbsp;
        {transport.getTruck().getModel()}
      </div>
      <div className={styles['Detail']}>
        <label className={styles['Label']}>{t('tooltip.trailer')}:</label>
        &nbsp;{transport.getTrailer().getBrand()}
      </div>
      <hr />
      <label className={styles['Label']}>{t('tooltip.transport_from')}:</label>
      <PlaceAndTime transport={transport} type="departure" />
      <div style={{ height: '15px' }} />
      <label className={styles['Label']}>{t('tooltip.transport_to')}:</label>
      <PlaceAndTime transport={transport} type="arrival" />
    </div>
  );

  return (
    <Tooltip
      title={getContent()}
      enterDelay={700}
      leaveDelay={200}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            color: '#000000',
            boxShadow:
              '0 0 0 1px rgb(0 0 0 / 0.1), 0 2px 16px rgb(0 0 0 / 0.1);',
            zIndex: '9999',
            fontFamily: 'unset',
          },
        },
        // arrow: {
        //   sx: {
        //     color: '#ffffff',
        //     boxShadow: '0 0 0 1px rgb(0 0 0 / 5%), 0 2px 16px rgb(0 0 0 / 5%);',
        //     zIndex: '999',
        //   },
        // },
      }}
      TransitionComponent={Zoom}
    >
      {children}
    </Tooltip>
  );
};
