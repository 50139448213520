import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { t } from 'i18next';
import styled from 'styled-components';
import { useTheme } from '../../theme/ThemeContext';
import { Logo } from '../Logo/Logo';
import { MainRootsNavigation } from '../mainRootsNavigation/MainRootsNavigation';
import { UserInfo } from '../userInfo/UserInfo';
import { MobileWidthsEnum } from '../../enums/Enums';
import { useWindowDimensions } from '../../utils/GetWindowDimensions';
import { SidebarToggleButton } from './sidebarToggleButton/SidebarToggleButton';
import { NavigationSidebar } from '../navigationSidebar/NavigationSidebar';
import styles from './Header.module.scss';

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 2.3em;
  left: 0;
`;
const Dropdown = styled.div`
  position: relative;
  display: block;
  &:hover ${DropdownContent} {
    display: block;
  }
  width: 9em;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(66, 68, 90, 0.52);
  -moz-box-shadow: 0px 0px 3px 0px rgba(66, 68, 90, 0.52);
  box-shadow: 0px 0px 3px 0px rgba(66, 68, 90, 0.52);
  height: 1em;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px 0;
  color: rgb(16, 58, 93);
  cursor: default;
`;
const DropdownItem = styled.span`
  display: block;
  height: 1em;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
  padding: 10px 0px;
  width: 9em;
  color: rgb(16, 58, 93);
  -webkit-box-shadow: 0px 0px 1px 0px rgba(66, 68, 90, 0.52);
  -moz-box-shadow: 0px 0px 1px 0px rgba(66, 68, 90, 0.52);
  box-shadow: 0px 0px 1px 0px rgba(66, 68, 90, 0.52);
  &:hover {
    background-color: #e5ebf2;
  }
`;
export const Header = () => {
  const { getTheme } = useTheme();
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();

  const isAddTransportPage = location.pathname === '/addTransport';
  const isTransportDetailsPage = location.pathname.includes('transportDetails');

  useEffect(() => {
    if (width > MobileWidthsEnum.mobilePhoneWidth) {
      setIsSidebarVisible(false);
    }
  }, [width]);

  return (
    <>
      <NavigationSidebar
        isExpanded={isSidebarVisible}
        close={() => setIsSidebarVisible(!isSidebarVisible)}
      />
      <header className={styles[`HeaderContainer--${getTheme()}`]}>
        <Logo />
        <div style={{ flexGrow: 1 }} />
        <MainRootsNavigation type="desktop" />
        <div style={{ flexGrow: 1 }} />

        <Dropdown>
          {t('navbar.configuration')}
          <DropdownContent>
            <NavLink to="/driverSettings">
              <DropdownItem>{t('navbar.drivers')}</DropdownItem>
            </NavLink>
            <NavLink to="/truckSettings">
              <DropdownItem>{t('navbar.trucks')}</DropdownItem>
            </NavLink>
            <NavLink to="/trailerSettings">
              <DropdownItem>{t('navbar.trailers')}</DropdownItem>
            </NavLink>
            <NavLink to="/tradePartnersSettings">
              <DropdownItem>{t('navbar.trade_partners')}</DropdownItem>
            </NavLink>
          </DropdownContent>
        </Dropdown>

        {!(isAddTransportPage || isTransportDetailsPage) && (
          <Button
            variant="contained"
            sx={{
              marginLeft: 'auto',
              marginRight: '8px',
              borderRadius: '0',
              // visibility:
              //   isAddTransportPage || isTransportDetailsPage
              //     ? 'hidden'
              //     : 'visible',
            }}
            onClick={() => navigate('/addTransport')}
            startIcon={<AddRounded />}
          >
            {t('button.new_transport')}
          </Button>
        )}
        <UserInfo />
        <SidebarToggleButton
          isSidebarVisible={isSidebarVisible}
          setIsSidebarVisible={() =>
            setIsSidebarVisible((prevState) => !prevState)
          }
        />
      </header>
    </>
  );
};
