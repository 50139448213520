/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Column, useTable } from 'react-table';
import { TableStyles, TextLink } from '../style/table';
import { InMemoryService } from '../../service/InMemoryService';
import { trailersStore } from '../../store/trailerStore/TrailersStore';
import {
  TrailerSnapshot,
  TrailerStore,
} from '../../store/trailerStore/TrailerStore';
import { DeleteForm } from '../style/form';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';

type Props = {
  trailers: TrailerStore[];
};

export const TrailersTable: FunctionComponent<Props> = observer(
  ({ trailers }) => {
    const data = trailers.map((x) => ({
      id: x.getId(),
      brand: x.getBrand(),
      licenseNumber: x.getLicenseNumber(),
      color: x.getColor(),
      year: x.getYear().toString(),
      vin: x.getVin(),
      serviceExpiryDate: x.getServiceExpiryDate().toString(),
      insuranceExpiryDate: x.getInsuranceExpiryDate().toString(),
      type: x.getType(),
      capacity: x.getCapacity().toString(),
      capacityUnit: x.getCapacityUnit(),
      refObject: TrailerStore.fromSnapshot(x.snapshot()),
    }));

    const columns = React.useMemo<Column<TrailerSnapshot>[]>(
      () => [
        {
          Header: 'Brand',
          accessor: 'brand', // accessor is the "key" in the data
        },
        {
          Header: 'License number',
          accessor: 'licenseNumber',
        },
        {
          Header: 'Color',
          accessor: 'color',
        },
        {
          Header: 'Year',
          accessor: 'year',
        },
        {
          Header: 'Vin',
          accessor: 'vin',
        },
        {
          Header: 'Service Expiry Date',
          accessor: 'serviceExpiryDate',
          Cell: (cell: any) => (
            <div>
              {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
            </div>
          ),
        },
        {
          Header: 'Insurance Expiry Date',
          accessor: 'insuranceExpiryDate',
          Cell: (cell: any) => (
            <div>
              {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
            </div>
          ),
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'Capacity',
          accessor: 'capacity',
        },
        {
          Header: 'Capacity Unit',
          accessor: 'capacityUnit',
        },
        {
          Header: ' ',
          Cell: (cell: any) => (
            <TextLink onClick={(e) => handleRowClick(cell)}>Edit</TextLink>
          ),
        },
        {
          Header: '  ',
          Cell: (cell: any) => (
            <Popup
              closeOnDocumentClick={false}
              modal={true}
              overlayStyle={{
                backgroundColor: 'rgba(224, 224, 224,.8)',
                zIndex: 10000,
              }}
              trigger={<TextLink>Delete</TextLink>}
            >
              {(close: any) => (
                <DeleteForm
                  onDelete={() => {
                    trailersStore.deleteTrailer(cell.row.original.id);
                    close();
                  }}
                  onCancel={close}
                  question={t('label.delete_confirmation')}
                />
              )}
            </Popup>
          ),
        },
      ],
      []
    );
    const tableInstance = useTable({
      columns,
      data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;

    const navigate = useNavigate();

    const handleRowClick = (cell: any) => {
      navigate(`trailerDetails/${cell.row.original.id}`);
    };

    return (
      <TableStyles>
        <h1>Trailers Settings</h1>
        <Button
          variant="contained"
          sx={{
            marginLeft: 'auto',
            marginRight: '8px',
            borderRadius: '0',
          }}
          onClick={() => navigate('createTrailer')}
          startIcon={<AddRounded />}
        >
          {t('button.new_trailer')}
        </Button>
        <table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => (
                        // Apply the cell props
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </TableStyles>
    );
  }
);
