import React from 'react';
import { useTheme } from '../../theme/ThemeContext';
import styles from './UserInfo.module.scss';

interface iUserInfoProps {
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

export const UserInfo = ({ className = '' }: iUserInfoProps) => {
  const { getTheme } = useTheme();

  return (
    <div className={`${styles[`Name--${getTheme()}`]} ${className}`}>
      Username
    </div>
  );
};
