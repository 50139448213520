/* eslint-disable no-shadow */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DriverDisplayDetails } from '../components/transportDetails/DriverDisplayDetails';
import { TradePartnerDetails } from '../components/transportDetails/TradePartnerDetails';
import { TrailerDetails } from '../components/transportDetails/TrailerDetails';
import { TruckDetails } from '../components/transportDetails/TruckDetails';
import { EmployeeStore } from '../store/employeeStore/EmployeeStore';
import { TradePartnerStore } from '../store/tradePartners/TradePartnerStore';
import { TrailerStore } from '../store/trailerStore/TrailerStore';
import { transportsStore } from '../store/transportStore/TransportsStore';
import { TransportStore } from '../store/transportStore/TransportStore';
import { TruckStore } from '../store/trucksStore/TruckStore';
import { transportStatusType } from '../types/Types';
import styles from './Dashboard.module.scss';

export const TransportDetailsPage = () => {
  const [priority, setPriority] = useState<boolean>(false);
  const [sourceTradePartner, setSourceTradePartner] =
    useState<TradePartnerStore | null>(null);
  const [destinationTradePartner, setDestinationTradePartner] =
    useState<TradePartnerStore | null>(null);
  const [driver, setDriver] = useState<EmployeeStore | null>(null);
  const [truck, setTruck] = useState<TruckStore | null>(null);
  const [trailer, setTrailer] = useState<TrailerStore | null>(null);
  const [departureDate, setDepartureDate] = useState<Date>(new Date());
  const [arrivalDate, setArrivalDate] = useState<Date>(new Date());
  const [status, setStatus] = useState<transportStatusType>('inProgress');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { transportId } = useParams();
  const [mode, setMode] = useState<'new' | 'edit'>('new');

  useEffect(() => {
    if (transportId) {
      setMode('edit');
      getTransportInitialData();
    } else {
      setMode('new');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tryToAutocomplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver]);

  const getTransportInitialData = () => {
    const transport = transportsStore.findById(Number(transportId));
    if (transport) {
      setPriority(transport.getPriority());
      setSourceTradePartner(transport.getSourceTradePartner());
      setDestinationTradePartner(transport.getDestinationTradePartner());
      setDriver(transport.getDriver());
      setTruck(transport.getTruck());
      setTrailer(transport.getTrailer());
      setDepartureDate(transport.getDepartureDate());
      setArrivalDate(transport.getArrivalDate());
      setStatus(transport.getStatus());
    }
  };

  const onSubmitClick = () => {
    if (!areFieldsCorrect()) {
      return;
    }
    setLoading(true);
    if (mode === 'new') {
      const newTransport = new TransportStore(
        transportsStore.getNewTransportId(),
        driver!,
        truck!,
        trailer!,
        priority,
        departureDate,
        arrivalDate,
        sourceTradePartner!,
        destinationTradePartner!,
        'inProgress'
      );

      transportsStore.createTransport(newTransport);
      setLoading(false);
      toast.success(t('toast.transport_created_successfully'));
    } else {
      const newTransport = new TransportStore(
        Number(transportId),
        driver!,
        truck!,
        trailer!,
        priority,
        departureDate,
        arrivalDate,
        sourceTradePartner!,
        destinationTradePartner!,
        status
      );

      transportsStore.updateTransport(newTransport);
      setLoading(false);
      toast.success(t('toast.transport_updated_successfully'));
    }
    navigate(-1);
  };

  const areFieldsCorrect = () => {
    if (departureDate.getTime() >= arrivalDate.getTime()) {
      toast.error(t('toast.start_date_can_not_be_lower'));
      return false;
    }
    if (
      !sourceTradePartner ||
      !destinationTradePartner ||
      !driver ||
      !truck ||
      !trailer ||
      !departureDate ||
      !arrivalDate
    ) {
      toast.error(t('toast.please_fill_all_fields'));
      return false;
    }
    return true;
  };

  const tryToAutocomplete = () => {
    if (!driver) {
      return;
    }
    const lastDriverTransport = transportsStore.getLastDriverTransport(
      driver.getId()
    );
    if (!lastDriverTransport) {
      return;
    }
    setTruck(lastDriverTransport.getTruck());
    setTrailer(lastDriverTransport.getTrailer());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: ' 100%' }}>
      <div className={styles['TransportTopSection']}>
        <span className={styles['TransportTopLabel']}>
          {mode === 'new' ? t('label.new_transport') : `Id: ${transportId}`}
        </span>
        <div
          className={styles['Priority']}
          style={{ visibility: priority ? 'visible' : 'hidden' }}
        >
          <span>P</span>
        </div>
        <FormControlLabel
          value="top"
          control={
            <Switch
              color="primary"
              value={priority}
              onChange={() => setPriority((priority) => !priority)}
            />
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          label={t('input.priority') as string}
          labelPlacement="top"
        />
      </div>
      <div id="Transport Details" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TradePartnerDetails
          title={t('tooltip.transport_from')}
          tradePartner={sourceTradePartner}
          onTradePartnerChange={(tradePartner) =>
            setSourceTradePartner(tradePartner)
          }
          date={departureDate}
          onDateChange={(date) => setDepartureDate(date)}
        />
        <TradePartnerDetails
          title={t('tooltip.transport_to')}
          tradePartner={destinationTradePartner}
          onTradePartnerChange={(tradePartner) =>
            setDestinationTradePartner(tradePartner)
          }
          date={arrivalDate}
          onDateChange={(date) => setArrivalDate(date)}
        />
        <DriverDisplayDetails
          driver={driver}
          onDriverChange={(driver) => setDriver(driver)}
        />
        <TruckDetails
          truck={truck}
          onTruckChange={(truck) => setTruck(truck)}
        />
        <TrailerDetails
          trailer={trailer}
          onTrailerChange={(trailer) => setTrailer(trailer)}
        />
      </div>
      <LoadingButton
        loading={loading}
        loadingPosition="start"
        variant="contained"
        sx={{
          borderRadius: '0',
          width: '170px',
          alignSelf: 'center',
          marginTop: '16px',
        }}
        onClick={() => onSubmitClick()}
        startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
      >
        {mode === 'new' ? t('button.new_transport') : t('button.save')}
      </LoadingButton>
    </div>
  );
};
