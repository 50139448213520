/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
import { AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import { Column, useTable } from 'react-table';
import Popup from 'reactjs-popup';
import { DriverLicenceForm } from '../../containers/DriverDetailsPage';
import { DrivingLicenceSnapshot } from '../../store/employeeStore/DrivingLicenseStore';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';
import { DeleteForm } from '../style/form';
import { TableStyles, TextLink } from '../style/table';
import { DriverLicencesForm } from './DriverLicencesForm';
import styles from '../style/Details.module.scss';

type Props = {
  driverLicences: DrivingLicenceSnapshot[];
  onLicenceEdit(licence: DrivingLicenceSnapshot): void;
  onLicenceDelete(licence: DrivingLicenceSnapshot): void;
  onLicenceCreate(licence: DrivingLicenceSnapshot): void;
};

export const DriverLicencesTable: FunctionComponent<Props> = ({
  driverLicences,
  onLicenceEdit,
  onLicenceDelete,
  onLicenceCreate,
}) => {
  const data = driverLicences;

  const columns = React.useMemo<Column<DrivingLicenceSnapshot>[]>(
    () => [
      {
        Header: 'Category',
        accessor: 'category', // accessor is the "key" in the data
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiryDate',
        Cell: (cell: any) => (
          <div>
            {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
          </div>
        ),
      },
      {
        Header: 'Edit',
        Cell: (cell: any) => (
          <Popup
            closeOnDocumentClick={false}
            modal={true}
            overlayStyle={{
              backgroundColor: 'rgba(224, 224, 224,.8)',
              zIndex: 1000,
            }}
            trigger={<TextLink>Edit</TextLink>}
          >
            {(close: any) => (
              <DriverLicencesForm
                category={cell.row.original.category}
                expiryDate={cell.row.original.expiryDate}
                mode="edit"
                onSave={(form: DriverLicenceForm) => {
                  onLicenceEdit({ ...form, id: cell.row.original.id });
                  close();
                }}
                cancel={close}
              />
            )}
          </Popup>
        ),
      },

      {
        Header: 'Delete',
        Cell: (cell: any) => (
          <Popup
            closeOnDocumentClick={false}
            modal={true}
            overlayStyle={{
              backgroundColor: 'rgba(224, 224, 224,.8)',
              zIndex: 1000,
            }}
            trigger={<TextLink>Delete</TextLink>}
          >
            {(close: any) => (
              <DeleteForm
                onDelete={() => {
                  onLicenceDelete({ ...cell.row.original });
                  close();
                }}
                onCancel={close}
                question={t('label.delete_confirmation')}
              />
            )}
          </Popup>
        ),
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <TableStyles>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className={styles['SectionTitle']}>
          {t('table.driver_licences')}
        </span>
        <div style={{ flex: 1 }}>{}</div>
        <Popup
          closeOnDocumentClick={false}
          modal={true}
          overlayStyle={{
            backgroundColor: 'rgba(224, 224, 224,.8)',
            zIndex: 1000,
          }}
          trigger={
            <Button
              style={{ marginBottom: 0 }}
              variant="contained"
              sx={{
                marginLeft: 'auto',
                borderRadius: '0',
              }}
              startIcon={<AddRounded />}
            >
              {t('button.new_Licence')}
            </Button>
          }
        >
          {(close: any) => (
            <DriverLicencesForm
              category=""
              expiryDate=""
              mode="new"
              onSave={(form: DriverLicenceForm) => {
                onLicenceCreate({
                  ...form,
                  id: Math.max(...driverLicences.map((x) => x.id)) + 1,
                });
                close();
              }}
              cancel={close}
            />
          )}
        </Popup>
      </div>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableStyles>
  );
};
