import React from 'react';
import { NavLink } from 'react-router-dom';
import { Pages } from '../../utils/Pages';
import { Si4Logo } from './Si4LogoSvg';
import { useTheme } from '../../theme/ThemeContext';
import styles from './Logo.module.scss';

export const Logo = () => {
  const { getTheme } = useTheme();

  return (
    <NavLink to={Pages.PAGES[0].to}>
      <div className={styles['LogoContainer']}>
        <Si4Logo />
        <div className={styles['TitleContainer']}>
          <h1 className={styles[`Title--${getTheme()}`]}>Plan your</h1>
          <h1 className={styles[`Title--${getTheme()}`]}>transports</h1>
        </div>
      </div>
    </NavLink>
  );
};
