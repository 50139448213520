/* eslint-disable react/jsx-props-no-spreading */
import { LoadingButton } from '@mui/lab';
import {
  OutlinedInput,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import styles from './Details.module.scss';

export const FormButtonContainer = styled.div`
  display: flex;
  flex-dircetion: column;
  padding: 20px;
`;

type Props = {
  question: string;
  onDelete(): void;
  onCancel(): void;
};

export const DeleteForm: FunctionComponent<Props> = ({
  question,
  onDelete,
  onCancel,
}) => (
  <section className={styles['SectionContainer']}>
    <span
      style={{ margin: 0, textAlign: 'center', width: '100%' }}
      className={styles['SectionTitle']}
    >
      {question}
    </span>

    <FormButtonContainer>
      <LoadingButton
        variant="contained"
        sx={{
          borderRadius: '0',
          width: '170px',
          alignSelf: 'center',
          display: 'flex',
          margin: '30px',
        }}
        onClick={() => onDelete()}
      >
        {t('button.delete')}
      </LoadingButton>
      <LoadingButton
        variant="contained"
        sx={{
          borderRadius: '0',
          width: '170px',
          alignSelf: 'center',
          display: 'flex',
          margin: '30px',
        }}
        onClick={() => onCancel()}
      >
        {t('button.cancel')}
      </LoadingButton>
    </FormButtonContainer>
  </section>
);
export const Form = styled.form`
  width: 60%;
  input {
    width: 300;
  }
`;

const FormInputWidth = 300;

export const FormOutlinedInput = (props: OutlinedInputProps) => (
  <OutlinedInput {...props} style={{ width: FormInputWidth }} />
);
export const FromTextInput = (props: TextFieldProps) => (
  <TextField {...props} style={{ width: FormInputWidth }} />
);
