/* eslint-disable no-shadow */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import styles from './Dashboard.module.scss';
import { trailersStore } from '../store/trailerStore/TrailersStore';
import { TrailerStore } from '../store/trailerStore/TrailerStore';
import { TrailerDetailsForm } from '../components/trailer/TrailerDetailsForm';
import { BackgroundPage, DetailsPage } from '../components/style/detailsPage';

export type TrailerForm = {
  brand: string;
  licenseNumber: string;
  color: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
  type: string;
  capacity: string;
  capacityUnit: string;
};

export const TrailerDetailsPage = observer(() => {
  const { trailerId } = useParams();
  const [mode, setMode] = useState<'new' | 'edit'>('new');
  const [loading, setLoading] = useState<boolean>(false);
  const [trailer, setTrailer] = useState<TrailerForm>({
    brand: '',
    licenseNumber: '',
    color: '',
    year: '',
    vin: '',
    serviceExpiryDate: '',
    insuranceExpiryDate: '',
    type: '',
    capacity: '',
    capacityUnit: '',
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (trailerId) {
      setMode('edit');
      getTrailerInitialData();
    } else {
      setMode('new');
    }
  }, [trailerId]);

  const getTrailerInitialData = () => {
    const trailer = trailersStore.getById(Number(trailerId));
    if (trailer) {
      const snapshot = trailer.snapshot();
      setTrailer({ ...snapshot });
    }
  };

  const onSubmitClick = (form: TrailerForm) => {
    setLoading(true);
    if (mode === 'new') {
      trailersStore.addTrailer(
        TrailerStore.fromSnapshot({
          id: trailersStore.getNextId(),
          ...form,
        })
      );
      toast.success(t('toast.trailer_created_successfully'));
    } else {
      trailersStore.updateTrailer(
        TrailerStore.fromSnapshot({
          id: Number(trailerId),
          ...form,
        })
      );
      toast.success(t('toast.trailer_updated_successfully'));
    }
    setLoading(false);
    navigate(-1);
  };

  return (
    <BackgroundPage>
      <DetailsPage>
        <div className={styles['TransportTopSection']}>
          <span className={styles['TransportTopLabel']}>
            {mode === 'new' ? t('button.new_trailer') : `Id: ${trailerId}`}
          </span>
        </div>
        <div
          id="Transport Details"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <TrailerDetailsForm
            {...trailer}
            loading={loading}
            mode={mode}
            onSave={onSubmitClick}
          />
        </div>
      </DetailsPage>
    </BackgroundPage>
  );
});
