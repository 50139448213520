/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from '@mui/lab';
import { OutlinedInput, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import styles from '../style/Details.module.scss';
import { FormOutlinedInput, FromTextInput } from '../style/form';

type Props = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  permissionsExpiryDate: string;
  tachographExpiryDate: string;
  pesel: string;
  onFirstnameChange(value: string): void;
  onLastnameChange(value: string): void;
  onPhoneNumberChange(value: string): void;
  onPermissionsExpiryDateChange(value: string): void;
  onTachographExpiryDateCange(value: string): void;
  onPeselChange(value: string): void;
};

export const DriverBasicForm: FunctionComponent<Props> = ({
  onFirstnameChange,
  onLastnameChange,
  onPhoneNumberChange,
  onPermissionsExpiryDateChange,
  onTachographExpiryDateCange,
  onPeselChange,
  ...props
}) => {
  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);
  return (
    <div>
      <span className={styles['SectionTitle']}>{t('input.driver')}</span>
      <section id="Driver Details" className={styles['SectionContainer']}>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.firstname')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.firstName === ''}
            onChange={handleInputValue(onFirstnameChange)}
            placeholder={t('input.firstname')}
            value={props.firstName}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>

        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.lastname')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.lastName === ''}
            onChange={handleInputValue(onLastnameChange)}
            placeholder={t('input.lastname')}
            value={props.lastName}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.pesel')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.pesel === ''}
            onChange={handleInputValue(onPeselChange)}
            placeholder={t('input.pesel')}
            value={props.pesel}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.phone_number')}:</label>
          <FormOutlinedInput
            size="small"
            type="tel"
            error={props.phoneNumber === ''}
            onChange={handleInputValue(onPhoneNumberChange)}
            placeholder={t('input.phone_number')}
            value={props.phoneNumber}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.permissions_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && onPermissionsExpiryDateChange(e)}
            value={
              props.permissionsExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(props.permissionsExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.permissions_expiry_date')}
              />
            )}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.tachograph_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && onTachographExpiryDateCange(e)}
            value={
              props.tachographExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(props.tachographExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.tachograph_expiry_date')}
              />
            )}
          />
        </div>
      </section>
    </div>
  );
};
