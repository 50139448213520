import { makeAutoObservable, set } from 'mobx';

export type TruckSnapshot = {
  id: number;
  brand: string;
  model: string;
  licenseNumber: string;
  color: string;
  fuel: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
};


export class TruckStore {
  constructor(
    private id: number,
    private brand: string,
    private model: string,
    private licenseNumber: string,
    private color: string,
    private fuel: string,
    private year: number,
    private vin: string,
    private serviceExpiryDate: Date,
    private insuranceExpiryDate: Date
  ) {
    makeAutoObservable(this);
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number): void {
    this.id = id;
  }

  public getBrand(): string {
    return this.brand;
  }

  public setBrand(brand: string): void {
    this.brand = brand;
  }

  public getModel(): string {
    return this.model;
  }

  public setModel(model: string): void {
    this.model = model;
  }

  public getLicenseNumber(): string {
    return this.licenseNumber;
  }

  public setLicenseNumber(licenseNumber: string): void {
    this.licenseNumber = licenseNumber;
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): void {
    this.color = color;
  }

  public getFuel(): string {
    return this.fuel;
  }

  public setFuel(fuel: string): void {
    this.fuel = fuel;
  }

  public getYear(): number {
    return this.year;
  }

  public setYear(year: number): void {
    this.year = year;
  }

  public getVin(): string {
    return this.vin;
  }

  public setVin(vin: string): void {
    this.vin = vin;
  }

  public getServiceExpiryDate(): Date {
    return this.serviceExpiryDate;
  }

  public setServiceExpiryDate(serviceExpiryDate: Date): void {
    this.serviceExpiryDate = serviceExpiryDate;
  }

  public getInsuranceExpiryDate(): Date {
    return this.insuranceExpiryDate;
  }

  public setInsuranceExpiryDate(insuranceExpiryDate: Date): void {
    this.insuranceExpiryDate = insuranceExpiryDate;
  }


  updateByKey(value: string, key?: string) {
    const copy: any = {
      ...this,
    };
    if (key) copy[key] = value;
    set(this, {
      ...copy,
    });
  }

  static fromSnapshot(snapshot: TruckSnapshot) {
    return new TruckStore(
      snapshot.id,
      snapshot.brand,
      snapshot.model,
      snapshot.licenseNumber,
      snapshot.color,
      snapshot.fuel,
      Number(snapshot.year),
      snapshot.vin,
      new Date(Date.parse(snapshot.serviceExpiryDate)),
      new Date(Date.parse(snapshot.insuranceExpiryDate))
    );
  }

  snapshot(): TruckSnapshot {
    return {
      id: this.id,
      brand: this.brand,
      model: this.model,
      licenseNumber: this.licenseNumber,
      color: this.color,
      fuel: this.fuel,
      year: this.year.toString(),
      vin: this.vin,
      serviceExpiryDate: this.serviceExpiryDate.toUTCString(),
      insuranceExpiryDate: this.insuranceExpiryDate.toUTCString(),
    };
  }

}
