/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { TransportStore } from '../store/transportStore/TransportStore';
import {
  mockEmployee_1,
  mockEmployee_2,
  mockEmployee_3,
  mockEmployee_4,
} from './EmployeesMock';
import {
  mockTradePartner_1,
  mockTradePartner_2,
  mockTradePartner_3,
} from './TradePartnersMock';
import {
  mockTrailer_1,
  mockTrailer_2,
  mockTrailer_3,
  mockTrailer_4,
} from './TrailersMock';
import {
  mockTruck_1,
  mockTruck_2,
  mockTruck_3,
  mockTruck_4,
} from './TrucksMock';

const mockTransport_1: TransportStore = new TransportStore(
  1,
  mockEmployee_1,
  mockTruck_1,
  mockTrailer_1,
  true,
  dayjs().subtract(30, 'minute').toDate(),
  dayjs().add(3, 'hour').toDate(),
  mockTradePartner_1,
  mockTradePartner_2,
  'inProgress'
);

const mockTransport_2: TransportStore = new TransportStore(
  2,
  mockEmployee_2,
  mockTruck_2,
  mockTrailer_2,
  false,
  dayjs().add(2.5, 'hour').toDate(),
  dayjs().add(5, 'hour').toDate(),
  mockTradePartner_2,
  mockTradePartner_1,
  'planned'
);

const mockTransport_3: TransportStore = new TransportStore(
  3,
  mockEmployee_3,
  mockTruck_3,
  mockTrailer_3,
  false,
  dayjs().subtract(2, 'hour').toDate(),
  dayjs().subtract(30, 'minute').toDate(),
  mockTradePartner_2,
  mockTradePartner_3,
  'finished'
);

const mockTransport_4: TransportStore = new TransportStore(
  4,
  mockEmployee_4,
  mockTruck_4,
  mockTrailer_4,
  false,
  dayjs().subtract(2, 'hour').toDate(),
  dayjs().subtract(1, 'minute').toDate(),
  mockTradePartner_3,
  mockTradePartner_1,
  'delayed'
);

const mockTransport_5: TransportStore = new TransportStore(
  5,
  mockEmployee_2,
  mockTruck_2,
  mockTrailer_2,
  true,
  dayjs().add(1, 'day').toDate(),
  dayjs().add(1, 'day').add(4, 'hour').toDate(),
  mockTradePartner_3,
  mockTradePartner_1,
  'planned'
);

export const mockTransports: TransportStore[] = [
  mockTransport_1,
  mockTransport_2,
  mockTransport_3,
  mockTransport_4,
  mockTransport_5,
];
export const initTransportsLocalStore = () => {
  const initialized = localStorage.getItem('transports');
  if (!initialized) {
    localStorage.setItem('transports', JSON.stringify(mockTransports));
  }
};
