/* eslint-disable no-shadow */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { DriverDetailsForm } from '../components/driver/DriverDetailsForm';
import { employeesStore } from '../store/employeeStore/EmployeesStore';
import { EmployeeStore } from '../store/employeeStore/EmployeeStore';
import { DrivingLicenceSnapshot } from '../store/employeeStore/DrivingLicenseStore';
import styles from './Dashboard.module.scss';
import {
  AddressSnapshot,
  AddressStore,
} from '../store/tradePartners/AddressStore';
import { ContactPersonSnapshot } from '../store/tradePartners/ContactPerson';
import { tradePartnersStore } from '../store/tradePartners/TradePartnersStore';
import { TradePartnerStore } from '../store/tradePartners/TradePartnerStore';
import { TradePartnerDetailsForm } from '../components/tradePartners/TradePartnerDetailsForm';
import { BackgroundPage, DetailsPage } from '../components/style/detailsPage';

export type TradePartnerForm = {
  companyName: string;
  NIP: string;
  REGON: string;
  address: AddressSnapshot;
  mailDropAddress: AddressSnapshot;
  paymentDueDate: string;
  paymentMethod: string;
  currency: string;
  email: string;
  phoneNumber: string;
  contactPersons: ContactPersonSnapshot[];
  rate: string;
};

export type ContactPersonForm = {
  firstName: string;
  lastName: string;
};

export const TradePartnerDetailsPage = observer(() => {
  const { tradePartnerId } = useParams();
  const [mode, setMode] = useState<'new' | 'edit'>('new');
  const [loading, setLoading] = useState<boolean>(false);
  const [tradePartner, setTradePartner] = useState<TradePartnerForm>({
    companyName: '',
    NIP: '',
    REGON: '',
    address: {
      id: 0,
      country: '',
      city: '',
      post: '',
      street: '',
      postcode: '',
      voivodeship: '',
    },
    mailDropAddress: {
      id: 0,
      country: '',
      city: '',
      post: '',
      street: '',
      postcode: '',
      voivodeship: '',
    },
    paymentDueDate: '',
    paymentMethod: '',
    currency: '',
    email: '',
    phoneNumber: '',
    contactPersons: [],
    rate: '',
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (tradePartnerId) {
      setMode('edit');
      getDriverInitialData();
    } else {
      setMode('new');
    }
  }, [tradePartnerId]);

  const getDriverInitialData = () => {
    const tradePartner = tradePartnersStore.getById(Number(tradePartnerId));
    if (tradePartner) {
      const snapshot = tradePartner.snapshot();
      setTradePartner({ ...snapshot });
    }
  };

  const onSubmitClick = (form: TradePartnerForm) => {
    setLoading(true);

    if (mode === 'new') {
      tradePartnersStore.addTradePartner(
        TradePartnerStore.fromSnapshot({
          id: tradePartnersStore.getNextId(),
          ...form,
        })
      );
      toast.success(t('toast.trade_partner_created_successfully'));
    } else {
      tradePartnersStore.updateTradePartner(
        TradePartnerStore.fromSnapshot({
          id: Number(tradePartnerId),
          ...form,
        })
      );
      toast.success(t('toast.trade_partner_updated_successfully'));
    }
    setLoading(false);
    navigate(-1);
  };

  return (
    <BackgroundPage>
      <DetailsPage>
        <div className={styles['TransportTopSection']}>
          <span className={styles['TransportTopLabel']}>
            {mode === 'new'
              ? t('button.new_trade_partner')
              : `Id: ${tradePartnerId}`}
          </span>
        </div>
        <div
          id="Transport Details"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <TradePartnerDetailsForm
            {...tradePartner}
            loading={loading}
            mode={mode}
            onSave={onSubmitClick}
          />
        </div>
      </DetailsPage>
    </BackgroundPage>
  );
});
