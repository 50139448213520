import React from 'react';
import { OutlinedInput } from '@mui/material';
import { t } from 'i18next';
import { EmployeeStore } from '../../store/employeeStore/EmployeeStore';
import { SelectEmployee } from '../select/SelectEmployee';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import styles from './Details.module.scss';

interface iDriverDetailsProps {
  driver: EmployeeStore | null;
  onDriverChange: (employee: EmployeeStore | null) => void;
}

export const DriverDisplayDetails = ({
  driver,
  onDriverChange,
}: iDriverDetailsProps) => (
  <section id="Driver Details" className={styles['SectionContainer']}>
    <span className={styles['SectionTitle']}>{t('input.driver')}</span>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.driver')}:</label>
      <SelectEmployee
        chosenDriver={driver}
        // eslint-disable-next-line no-shadow
        onChange={(driver) => onDriverChange(driver)}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.phone_number')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.phone_number')}
        disabled
        value={driver ? driver.getPhoneNumber() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.permissions_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.permissions_expiry_date')}
        disabled
        value={
          driver
            ? getFormattedDateWithNamedMonth(driver.getPermissionsExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.tachograph_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.tachograph_expiry_date')}
        disabled
        value={
          driver
            ? getFormattedDateWithNamedMonth(driver.getTachograpghExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.driving_licenses')}:</label>
      {driver &&
        driver.getDrivingLicense().map((drivingLicense) => (
          <div
            key={`category-${drivingLicense.getId()}__expiryDate-${drivingLicense.getExpiryDate()}`}
            className={styles['DrivingLicenseDetails']}
          >
            <div className={styles['DrivingLicenseCategory']}>
              <label className={styles['Label']}>{t('input.category')}:</label>
              <OutlinedInput
                size="small"
                placeholder={t('input.category')}
                disabled
                value={drivingLicense.getCategory()}
                sx={{
                  borderRadius: '0',
                  backgroundColor: 'rgba(0, 0, 0, 0.06)',
                }}
              />
            </div>
            <div className={styles['DrivingLicenseExpiryDate']}>
              <label className={styles['Label']}>
                {t('input.expiry_date')}:
              </label>
              <OutlinedInput
                size="small"
                placeholder={t('input.expiry_date')}
                disabled
                value={getFormattedDateWithNamedMonth(
                  drivingLicense.getExpiryDate()
                )}
                sx={{
                  borderRadius: '0',
                  backgroundColor: 'rgba(0, 0, 0, 0.06)',
                }}
              />
            </div>
          </div>
        ))}
    </div>
  </section>
);
