/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { TrailerStore } from '../store/trailerStore/TrailerStore';

export const mockTrailer_1: TrailerStore = new TrailerStore(
  1,
  'Mercedes',
  'PL4334',
  'Red',
  1998,
  '2G1AN35J4C1202219',
  dayjs().add(5, 'month').toDate(),
  dayjs().add(2, 'month').toDate(),
  'type',
  1.505,
  'm3'
);

export const mockTrailer_2: TrailerStore = new TrailerStore(
  2,
  'Scania',
  'PL4334',
  'Red',
  1998,
  '2FDKF38G3KCA42390',
  dayjs().add(2, 'month').toDate(),
  dayjs().add(1, 'month').toDate(),
  'type',
  900,
  'ton'
);

export const mockTrailer_3: TrailerStore = new TrailerStore(
  3,
  'Scania',
  'PL4867',
  'Yellow',
  2013,
  '1G8AZ52F23Z114214',
  dayjs().add(2, 'month').toDate(),
  dayjs().add(1, 'month').toDate(),
  'type',
  100,
  'ton'
);

export const mockTrailer_4: TrailerStore = new TrailerStore(
  4,
  'Volvo',
  'PL90734',
  'Red',
  2022,
  '1G8AZ52F23Z114214',
  dayjs().add(2, 'month').toDate(),
  dayjs().add(1, 'month').toDate(),
  'type',
  900,
  'm3'
);

export const mockTrailers: TrailerStore[] = [
  mockTrailer_1,
  mockTrailer_2,
  mockTrailer_3,
  mockTrailer_4,
];

export const initTrailersLocalStore = () => {
  
  const initialized = localStorage.getItem('trailers');
  if (!initialized) {
    localStorage.setItem('trailers', JSON.stringify(mockTrailers));
  }
};


