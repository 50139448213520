import React from 'react';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { navLinkType } from '../../types/Types';
import { Pages } from '../../utils/Pages';

export const AppRoutes = () => (
  <Routes>
    {recursiveRoutes(Pages.PAGES)}
    <Route path="/" element={<Navigate to={Pages.PAGES[0].to} replace />} />
  </Routes>
);

const recursiveRoutes = (pages: navLinkType[]) =>
  pages.map((page) => (
    <Route key={`${page.to} ${page.index}`} path={page.to}>
      <Route index element={page.children()} />
      {recursiveRoutes(page.nestedPaths)}
    </Route>
  ));
