import { makeAutoObservable } from 'mobx';
import { InMemoryService } from '../../service/InMemoryService';
import { EmployeeService } from '../../service/Service';
import { statusType } from '../../types/Types';
import { DrivingLicenceStore } from './DrivingLicenseStore';
import { EmployeeSnapshot, EmployeeStore } from './EmployeeStore';

class EmployeesStore {
  constructor(
    private httpService: EmployeeService,
    private employees: EmployeeStore[] = [],
    private status: statusType = 'complete'
  ) {
    makeAutoObservable(this);
  }

  getEmployees() {
    return this.employees;
  }

  getStatus() {
    return this.status;
  }

  async fetchEmployees() {
    try {
      this.status = 'loading';
      this.employees = await this.httpService.getEmployees();
      this.status = 'complete';
    } catch (error) {
      this.status = 'error';
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getById(id: number) {
    const employee = this.employees.find((x) => x.getId() === id);
    return employee;
  }

  getNextId() {
    const max = Math.max(...this.employees.map((x) => x.getId()));
    return max + 1;
  }

  addEmployee(employee: EmployeeStore) {
    this.httpService.creatEmployee(employee);
    this.employees.push(employee);
  }

  updateEmployee(employee: EmployeeStore) {
    const index = this.employees.findIndex(
      (x) => x.getId() === employee.getId()
    );
    if (index > -1) {
      this.httpService.updateEmployee(employee);
      this.employees[index] = employee;
    }
  }

  updateLicence(employee: EmployeeStore, licence: DrivingLicenceStore) {
    const index = this.employees.findIndex(
      (x) => x.getId() === employee.getId()
    );
    if (index > -1) {
      this.httpService.updateEmployee(employee);
      this.employees[index].updateLicence(licence);
    }
  }

  deleteEmployee(employeeID: number) {
    this.httpService.deleteEmployee(employeeID);
    this.employees = this.employees.filter((x) => x.getId() !== employeeID);
  }
}

export const employeesStore = new EmployeesStore(new InMemoryService());
