/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { OutlinedInput, TextField } from '@mui/material';
import { t } from 'i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import { DriverLicenceForm } from '../../containers/DriverDetailsPage';
import styles from '../style/Details.module.scss';
import {
  FormButtonContainer,
  FormOutlinedInput,
  FromTextInput,
} from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  category: string;
  expiryDate: string;
  onSave(form: DriverLicenceForm): void;
  cancel(): void;

  mode: 'new' | 'edit';
}

export const DriverLicencesForm: FunctionComponent<Props> = ({
  category,
  expiryDate,
  onSave,
  mode,
  cancel,
}) => {
  const [_category, setCategory] = useState(category);
  const [_expiryDate, setExpiryDate] = useState(expiryDate);

  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);

  return (
    <section
      id="Licnce Details"
      style={{ width: 600 }}
      className={styles['SectionContainer']}
    >
      <span style={{ margin: 0 }} className={styles['SectionTitle']}>
        {t('input.licence')}
      </span>
      <div className={styles['LabelWithInput']}>
        <label className={styles['Label']}>{t('input.category')}:</label>
        <FormOutlinedInput
          size="small"
          error={_category === ''}
          onChange={handleInputValue(setCategory)}
          placeholder={t('input.category')}
          value={_category}
          sx={{
            borderRadius: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
          }}
        />
      </div>
      <div className={styles['LabelWithInput']}>
        <label className={styles['Label']}>{t('input.expiry_date')}:</label>
        <DatePicker
          mask="__.__.____"
          inputFormat="DD.MM.YYYY"
          onChange={(e) => e && setExpiryDate(e)}
          value={
            _expiryDate &&
            getFormattedDateWithNamedMonth(new Date(Date.parse(_expiryDate)))
          }
          renderInput={(params) => (
            <FromTextInput
              {...params}
              helperText={t('input.permissions_expiry_date')}
            />
          )}
        />
      </div>
      <FormButtonContainer>
        <LoadingButton
          disabled={!allNotEmpty([_category, _expiryDate])}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              category: _category,
              expiryDate: new Date(Date.parse(_expiryDate)).toString(),
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_Licence') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => cancel()}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </section>
  );
};
