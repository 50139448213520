import { observer } from 'mobx-react-lite';
import React from 'react';
import { TrucksTable } from '../components/truck/TrucksTable';

import { trucksStore } from '../store/trucksStore/TrucksStore';

export const TrucksSettingsPage = observer(() => {
  const trucks = trucksStore.getTrucks();
  return <TrucksTable trucks={[...trucks]} />;
});
