import React from 'react';
import styles from './Icons.module.scss';

interface iIconProps {
  active: boolean;
}

interface iSvgIconProps {
  children: React.ReactNode;
}

const SvgIcon = ({ children }: iSvgIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    {children}
  </svg>
);

export const DotsHorizontalIcon = () => (
  <SvgIcon>
    <path
      fill="#000000"
      d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
    />
  </SvgIcon>
);

export const LeftArrow = () => (
  <SvgIcon>
    <path
      fill="#000000"
      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    />
  </SvgIcon>
);

export const RightArrow = () => (
  <SvgIcon>
    <path
      fill="#000000"
      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    />
  </SvgIcon>
);

export const CheckboxMarkedCircleOutlineIcon = () => (
  <SvgIcon>
    <path
      fill="#ffffff"
      d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
    />
  </SvgIcon>
);

export const TruckDeliveryIcon = () => (
  <SvgIcon>
    <path
      fill="#ffffff"
      d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
    />
  </SvgIcon>
);

export const CalendarMultipleIcon = ({ active }: iIconProps) => (
  <SvgIcon>
    <path
      className={active ? styles['Icon--active'] : styles['Icon']}
      d="M21,17V8H7V17H21M21,3A2,2 0 0,1 23,5V17A2,2 0 0,1 21,19H7C5.89,19 5,18.1 5,17V5A2,2 0 0,1 7,3H8V1H10V3H18V1H20V3H21M3,21H17V23H3C1.89,23 1,22.1 1,21V9H3V21M19,15H15V11H19V15Z"
    />
  </SvgIcon>
);

export const CheckAllIcon = ({ active }: iIconProps) => (
  <SvgIcon>
    <path
      className={active ? styles['Icon--active'] : styles['Icon']}
      d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
    />
  </SvgIcon>
);

export const ClockAlertOutlineIcon = ({ active }: iIconProps) => (
  <SvgIcon>
    <path
      className={active ? styles['Icon--active'] : styles['Icon']}
      d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22C14.25,22 16.33,21.24 18,20V17.28C16.53,18.94 14.39,20 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C15.36,4 18.23,6.07 19.41,9H21.54C20.27,4.94 16.5,2 12,2M11,7V13L16.25,16.15L17,14.92L12.5,12.25V7H11M20,11V18H22V11H20M20,20V22H22V20H20Z"
    />
  </SvgIcon>
);

export const CalendarTextIcon = ({ active }: iIconProps) => (
  <SvgIcon>
    <path
      className={active ? styles['Icon--active'] : styles['Icon']}
      d="M14,14H7V16H14M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M17,10H7V12H17V10Z"
    />
  </SvgIcon>
);
