/* eslint-disable no-shadow */
import React from 'react';
import { t } from 'i18next';
import { OutlinedInput } from '@mui/material';
import { TruckStore } from '../../store/trucksStore/TruckStore';
import { SelectTruck } from '../select/SelectTruck';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import styles from './Details.module.scss';

interface iTruckDetailsProps {
  truck: TruckStore | null;
  onTruckChange: (employee: TruckStore | null) => void;
}

export const TruckDetails = ({ truck, onTruckChange }: iTruckDetailsProps) => (
  <section id="Truck Details" className={styles['SectionContainer']}>
    <span className={styles['SectionTitle']}>{t('input.truck')}</span>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.truck')}:</label>
      <SelectTruck
        chosenTruck={truck}
        onChange={(truck) => onTruckChange(truck)}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.license_number')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.license_number')}
        disabled
        value={truck ? truck.getLicenseNumber() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.color')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.color')}
        disabled
        value={truck ? truck.getColor() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.fuel')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.fuel')}
        disabled
        value={truck ? truck.getFuel() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.year')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.year')}
        disabled
        value={truck ? truck.getYear() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.service_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.service_expiry_date')}
        disabled
        value={
          truck
            ? getFormattedDateWithNamedMonth(truck.getServiceExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.insurance_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.insurance_expiry_date')}
        disabled
        value={
          truck
            ? getFormattedDateWithNamedMonth(truck.getInsuranceExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
  </section>
);
