import dayjs from 'dayjs';
import i18n from '../assets/translations/i18n';

export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dayJsEndDate = dayjs(endDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  let dayJsCurrentDate = dayjs(startDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (dayJsCurrentDate.isAfter(dayJsEndDate)) {
    throw new Error('The first date cannot be after the second date');
  }

  const datesBetween: Date[] = [];

  while (
    dayJsCurrentDate.isBefore(dayJsEndDate, 'day') ||
    dayJsCurrentDate.isSame(dayJsEndDate, 'day')
  ) {
    datesBetween.push(dayJsCurrentDate.toDate());
    dayJsCurrentDate = dayJsCurrentDate.add(1, 'day');
  }

  return datesBetween;
};

/**
 * From 0 (Sunday) to 6 (Saturday)
 */
export const getShortWeekday = (day: number) => {
  switch (day) {
    case 0:
      return i18n.t('weekday_short.sunday');
    case 1:
      return i18n.t('weekday_short.monday');
    case 2:
      return i18n.t('weekday_short.tuesday');
    case 3:
      return i18n.t('weekday_short.wednesday');
    case 4:
      return i18n.t('weekday_short.thursday');
    case 5:
      return i18n.t('weekday_short.friday');
    case 6:
      return i18n.t('weekday_short.saturday');
    default:
      throw new Error('Wrong day index');
  }
};

/**
 * From 0 (January) to 11 (December)
 */
export const getShortMonth = (day: number) => {
  switch (day) {
    case 0:
      return i18n.t('month_short.january');
    case 1:
      return i18n.t('month_short.february');
    case 2:
      return i18n.t('month_short.march');
    case 3:
      return i18n.t('month_short.april');
    case 4:
      return i18n.t('month_short.may');
    case 5:
      return i18n.t('month_short.june');
    case 6:
      return i18n.t('month_short.july');
    case 7:
      return i18n.t('month_short.august');
    case 8:
      return i18n.t('month_short.september');
    case 9:
      return i18n.t('month_short.october');
    case 10:
      return i18n.t('month_short.november');
    case 11:
      return i18n.t('month_short.december');
    default:
      throw new Error('Wrong month index');
  }
};

export const getArrayOfDays = (): string[] => {
  const days = [
    i18n.t('weekday.sunday'),
    i18n.t('weekday.monday'),
    i18n.t('weekday.tuesday'),
    i18n.t('weekday.wednesday'),
    i18n.t('weekday.thursday'),
    i18n.t('weekday.friday'),
    i18n.t('weekday.saturday'),
  ];
  return days;
};

export const getArrayOfMonths = (): string[] => {
  const months = [
    i18n.t('month.january'),
    i18n.t('month.february'),
    i18n.t('month.march'),
    i18n.t('month.april'),
    i18n.t('month.may'),
    i18n.t('month.june'),
    i18n.t('month.july'),
    i18n.t('month.august'),
    i18n.t('month.september'),
    i18n.t('month.october'),
    i18n.t('month.november'),
    i18n.t('month.december'),
  ];
  return months;
};

export const getArrayOfXShortWeekdays = (): string[] => {
  const xShortWeekdays = [
    i18n.t('weekday_x_short.sunday'),
    i18n.t('weekday_x_short.monday'),
    i18n.t('weekday_x_short.tuesday'),
    i18n.t('weekday_x_short.wednesday'),
    i18n.t('weekday_x_short.thursday'),
    i18n.t('weekday_x_short.friday'),
    i18n.t('weekday_x_short.saturday'),
  ];
  return xShortWeekdays;
};

export const getFormattedDateWithNamedMonth = (date: Date): string => {
  const day = date.getDate();
  const month = getArrayOfMonths()[date.getMonth()];
  const year = date.getFullYear();

  const result = `${day} ${month} ${year}`;
  return result;
};

export const getFormattedDateWithNamedDayAndMonth = (date: Date): string => {
  const namedDay = getArrayOfDays()[date.getDay()];
  const day = date.getDate();
  const month = getArrayOfMonths()[date.getMonth()];
  const year = date.getFullYear();

  const result = `${namedDay}, ${day} ${month} ${year}`;
  return result;
};

export const getHHmm = (date: Date): string => {
  const dayJsDate = dayjs(date);
  const formattedDate = dayJsDate.format('HH:mm');
  return formattedDate;
};

/**
 * @category Range Helpers
 * @summary Is the given date range overlapping with another date range?
 *
 * @description
 * Copied from *date-fns*
 *
 * Is the given date range overlapping with another date range?
 *
 * @param {Date|String|Number} initialRangeStartDate - the start of the initial range
 * @param {Date|String|Number} initialRangeEndDate - the end of the initial range
 * @param {Date|String|Number} comparedRangeStartDate - the start of the range to compare it with
 * @param {Date|String|Number} comparedRangeEndDate - the end of the range to compare it with
 * @returns {Boolean} whether the date ranges are overlapping
 * @throws {Error} startDate of a date range cannot be after its endDate
 *
 * @example
 * // For overlapping date ranges:
 * areRangesOverlapping(
 *   new Date(2014, 0, 10), new Date(2014, 0, 20), new Date(2014, 0, 17), new Date(2014, 0, 21)
 * )
 * //=> true
 *
 * @example
 * // For non-overlapping date ranges:
 * areRangesOverlapping(
 *   new Date(2014, 0, 10), new Date(2014, 0, 20), new Date(2014, 0, 21), new Date(2014, 0, 22)
 * )
 * //=> false
 */
export const areRangesOverlapping = (
  initialRangeStartDate: Date,
  initialRangeEndDate: Date,
  comparedRangeStartDate: Date,
  comparedRangeEndDate: Date
) => {
  const initialStartTime = dayjs(initialRangeStartDate)
    .second(0)
    .millisecond(0)
    .toDate()
    .getTime();
  const initialEndTime = dayjs(initialRangeEndDate)
    .second(0)
    .millisecond(0)
    .toDate()
    .getTime();
  const comparedStartTime = dayjs(comparedRangeStartDate)
    .second(0)
    .millisecond(0)
    .toDate()
    .getTime();
  const comparedEndTime = dayjs(comparedRangeEndDate)
    .second(0)
    .millisecond(0)
    .toDate()
    .getTime();

  if (
    initialStartTime > initialEndTime ||
    comparedStartTime > comparedEndTime
  ) {
    throw new Error(
      'The start of the range cannot be after the end of the range'
    );
  }

  const result =
    initialStartTime < comparedEndTime && comparedStartTime < initialEndTime;
  return result;
};
