/* eslint-disable no-shadow */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import styles from './Dashboard.module.scss';
import { trucksStore } from '../store/trucksStore/TrucksStore';
import { TruckStore } from '../store/trucksStore/TruckStore';
import { TruckDetailsForm } from '../components/truck/TruckDetailsForm';
import { BackgroundPage, DetailsPage } from '../components/style/detailsPage';

export type TruckForm = {
  brand: string;
  model: string;
  licenseNumber: string;
  color: string;
  fuel: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
};

export const TruckDetailsPage = observer(() => {
  const { truckId } = useParams();
  const [mode, setMode] = useState<'new' | 'edit'>('new');
  const [loading, setLoading] = useState<boolean>(false);
  const [truck, setTruck] = useState<TruckForm>({
    brand: '',
    model: '',
    licenseNumber: '',
    color: '',
    fuel: '',
    year: '',
    vin: '',
    serviceExpiryDate: '',
    insuranceExpiryDate: '',
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (truckId) {
      setMode('edit');
      getTruckInitialData();
    } else {
      setMode('new');
    }
  }, [truckId]);

  const getTruckInitialData = () => {
    const truck = trucksStore.getById(Number(truckId));
    if (truck) {
      const snapshot = truck.snapshot();
      setTruck({ ...snapshot });
    }
  };

  const onSubmitClick = (form: TruckForm) => {
    setLoading(true);
    if (mode === 'new') {
      trucksStore.addTruck(
        TruckStore.fromSnapshot({
          id: trucksStore.getNextId(),
          ...form,
        })
      );
      toast.success(t('toast.truck_created_successfully'));
    } else {
      trucksStore.updateTruck(
        TruckStore.fromSnapshot({
          id: Number(truckId),
          ...form,
        })
      );
      toast.success(t('toast.truck_updated_successfully'));
    }
    setLoading(false);
    navigate(-1);
  };

  return (
    <BackgroundPage>
      <DetailsPage>
        <div className={styles['TransportTopSection']}>
          <span className={styles['TransportTopLabel']}>
            {mode === 'new' ? t('button.new_truck') : `Id: ${truckId}`}
          </span>
        </div>
        <div
          id="Transport Details"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <TruckDetailsForm
            {...truck}
            loading={loading}
            mode={mode}
            onSave={onSubmitClick}
          />
        </div>
      </DetailsPage>
    </BackgroundPage>
  );
});
