/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import { scaleLinear, scaleTime } from 'd3-scale';
import dayjs from 'dayjs';
import { action, makeAutoObservable } from 'mobx';
import { scaleType } from '../types/Types';
import { areRangesOverlapping, getDatesBetween } from '../utils/DateUtils';
import { employeesStore } from './employeeStore/EmployeesStore';
import { EmployeeStore } from './employeeStore/EmployeeStore';
import { transportsStore } from './transportStore/TransportsStore';
import { TransportStore } from './transportStore/TransportStore';

class SchedulerPageStore {
  constructor(
    private zoom: number = 1000,
    private innerWidth = window.innerWidth,
    private currentPosition: number = new Date().getTime(),
    private showCompletedTransports: boolean = false,
    private chosenDriver: EmployeeStore | null = null,
    private viewWithDriversLines: boolean = false
  ) {
    makeAutoObservable(this);
    this.innerWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth;
    });
  }

  getCurrentPosition() {
    return this.currentPosition;
  }

  getShowCompletedTransports() {
    return this.showCompletedTransports;
  }

  getChosenDriver() {
    return this.chosenDriver;
  }

  getCurrentDate() {
    const date = new Date(this.currentPosition);
    const result = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    return result;
  }

  getViewWithDriversLines() {
    return this.viewWithDriversLines;
  }

  setCurrentPosition(currentDate: Date) {
    const newPosition = currentDate.getTime();
    this.animateValue({
      from: this.currentPosition,
      to: newPosition,
      duration: 1000,
      callback: action((value: number) => {
        this.currentPosition = value;
      }),
    });
  }

  setShowCompletedTransports(showCompletedTransports: boolean) {
    this.showCompletedTransports = showCompletedTransports;
  }

  setChosenDriver(chosenDriver: EmployeeStore | null) {
    this.chosenDriver = chosenDriver;
  }

  setViewWithDriversLines(viewWithDriversLines: boolean) {
    this.viewWithDriversLines = viewWithDriversLines;
  }

  zoomBy(factor: number) {
    this.zoom += factor;
    if (this.zoom < 100) {
      this.zoom = 100;
    }
    if (this.zoom > 10000) {
      this.zoom = 10000;
    }
  }

  moveBy(pixels: number) {
    this.currentPosition += pixels * 60 * 1000 * this.zoom * 0.001;
  }

  getScale(): scaleType {
    const d1 = dayjs(new Date(this.currentPosition))
      .add(-5 * this.zoom * 0.1, 'minute')
      .toDate();
    const d2 = dayjs(new Date(this.currentPosition))
      .add(19 * this.zoom * 0.1, 'minute')
      .toDate();
    const timeScale = scaleTime().domain([d1, d2]).range([0, this.innerWidth]);

    const tickCount = Math.min((24 * this.zoom) / 1000, 36);
    const ticks = timeScale.ticks(tickCount);
    const dayTicks = getDatesBetween(d1, d2);

    return { timeScale, ticks, dayTicks };
  }

  getTransports() {
    const { from, to } = this.dateRangeExtended();

    const result = transportsStore.getTransports().filter((transport) => {
      if (
        !this.showCompletedTransports &&
        transport.getStatus() === 'finished'
      ) {
        return false;
      }
      return (
        transport.getArrivalDate().getTime() >= from.getTime() &&
        transport.getDepartureDate().getTime() <= to.getTime()
      );
    });

    return result;
  }

  getSchedulerLineLevel() {
    const perTransport = new Map<number, number>();

    const calculateLevels = (transports: TransportStore[]) => {
      const levels: Array<TransportStore[]> = [];

      transports
        .sort((a, b) => this.sortByIdAndDate(a, b))
        .forEach((transport) => {
          let level = 0;

          // check all transports from current level if there is place for this
          for (level = 0; level < levels.length; level += 1) {
            if (
              levels[level].every(
                (nestedTransport) =>
                  !areRangesOverlapping(
                    transport.getDepartureDate(),
                    transport.getArrivalDate(),
                    nestedTransport.getDepartureDate(),
                    nestedTransport.getArrivalDate()
                  )
              )
            ) {
              break;
            }
          }
          if (levels.length === level) {
            levels.push([]);
          }
          levels[level].push(transport);
          perTransport.set(transport.getId(), level);
        });
    };

    if (this.viewWithDriversLines) {
      employeesStore.getEmployees().forEach((driver) => {
        calculateLevels(
          this.getTransports().filter(
            (transport) => transport.getDriver().getId() === driver.getId()
          )
        );
      });
    } else {
      calculateLevels(this.getTransports());
    }

    return perTransport;
  }

  private sortByIdAndDate(a: TransportStore, b: TransportStore) {
    const firstDate = dayjs(a.getDepartureDate())
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
    const secondDate = dayjs(b.getDepartureDate())
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
    if (firstDate === secondDate) {
      return a.getId().toString().localeCompare(b.getId().toString());
    }
    return firstDate.getTime() - secondDate.getTime();
  }

  private dateRange() {
    const from = dayjs(this.getScale().timeScale.invert(0))
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate()
      .getTime();
    const to = dayjs(this.getScale().timeScale.invert(this.innerWidth))
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .toDate()
      .getTime();
    return { from, to, position: this.currentPosition };
  }

  private dateRangeExtended() {
    const { from, to } = this.dateRange();
    return {
      from: dayjs(from).subtract(1, 'day').toDate(),
      to: dayjs(to).add(1, 'day').toDate(),
    };
  }

  private animateValue({
    from,
    to,
    duration = 500,
    callback,
  }: {
    from: number;
    to: number;
    duration: number;
    callback: (value: number) => void;
  }) {
    const easing = (t: any) => 1 + --t * t * t * t * t;
    let start: number;

    const scale = scaleLinear().domain([0, 1]).range([from, to]);

    function step(timestamp: number) {
      if (!start) start = timestamp;
      const diff = timestamp - start;
      const progress = duration > 0 ? diff / duration : 0;
      const value = scale(easing(progress));
      callback(value);

      if (progress < 1) requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  }
}

export const schedulerPageStore = new SchedulerPageStore();
