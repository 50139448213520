import React from 'react';

export const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="36px"
    height="36px"
    version="1.1"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    textRendering="geometricPrecision"
    shapeRendering="geometricPrecision"
    viewBox="0 0 36 36"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Warstwa_x0020_dashboard">
      <metadata id="dashboard" />
      <path d="M1.49 0.5l33.02 0c0.55,0 0.99,0.44 0.99,0.99l0 33.02c0,0.55 -0.44,0.99 -0.99,0.99l-33.02 0c-0.55,0 -0.99,-0.44 -0.99,-0.99l0 -33.02c0,-0.55 0.44,-0.99 0.99,-0.99zm2.53 2.5l27.97 0c0.55,0 1.01,0.46 1.01,1.01l0 27.97c0,0.56 -0.46,1.02 -1.01,1.02l-27.97 0c-0.56,0 -1.02,-0.46 -1.02,-1.02l0 -27.97c0,-0.55 0.46,-1.01 1.02,-1.01zm19.86 20.11l7.33 0c0.35,0 0.64,0.29 0.64,0.65l0 7.4c0,0.36 -0.29,0.65 -0.64,0.65l-7.33 0c-0.35,0 -0.64,-0.29 -0.64,-0.65l0 -7.4c0,-0.36 0.29,-0.65 0.64,-0.65zm1.15 4.6c-0.19,-0.19 -0.19,-0.51 0,-0.7 0.2,-0.2 0.51,-0.2 0.71,0l1.15 1.15 2.46 -2.46c0.2,-0.19 0.52,-0.19 0.71,0 0.19,0.2 0.19,0.51 0,0.71l-2.82 2.81c-0.19,0.19 -0.51,0.19 -0.7,0l-1.51 -1.51zm-1.15 -14.01l7.33 0c0.35,0 0.64,0.29 0.64,0.64l0 7.41c0,0.35 -0.29,0.64 -0.64,0.64l-7.33 0c-0.35,0 -0.64,-0.29 -0.64,-0.64l0 -7.41c0,-0.35 0.29,-0.64 0.64,-0.64zm1.15 4.6c-0.19,-0.19 -0.19,-0.51 0,-0.71 0.2,-0.19 0.51,-0.19 0.71,0l1.15 1.16 2.46 -2.47c0.2,-0.19 0.52,-0.19 0.71,0.01 0.19,0.19 0.19,0.51 0,0.7l-2.82 2.82c-0.19,0.19 -0.51,0.19 -0.7,0l-1.51 -1.51zm-1.15 -14.11l7.33 0c0.35,0 0.64,0.29 0.64,0.65l0 7.4c0,0.35 -0.29,0.64 -0.64,0.64l-7.33 0c-0.35,0 -0.64,-0.29 -0.64,-0.64l0 -7.4c0,-0.36 0.29,-0.65 0.64,-0.65zm1.15 4.6c-0.19,-0.19 -0.19,-0.51 0,-0.7 0.2,-0.2 0.51,-0.2 0.71,0l1.15 1.15 2.46 -2.46c0.2,-0.2 0.52,-0.2 0.71,0 0.19,0.19 0.19,0.51 0,0.7l-2.82 2.82c-0.19,0.19 -0.51,0.19 -0.7,0l-1.51 -1.51zm-17.79 14.47c-0.27,0 -0.5,-0.23 -0.5,-0.51 0,-0.27 0.23,-0.5 0.5,-0.5l11.76 0c0.27,0 0.5,0.23 0.5,0.5 0,0.28 -0.23,0.51 -0.5,0.51l-11.76 0zm0 -4.75c-0.27,0 -0.5,-0.22 -0.5,-0.5 0,-0.27 0.23,-0.5 0.5,-0.5l11.76 0c0.27,0 0.5,0.23 0.5,0.5 0,0.28 -0.23,0.5 -0.5,0.5l-11.76 0zm0 -4.74c-0.27,0 -0.5,-0.22 -0.5,-0.5 0,-0.27 0.23,-0.5 0.5,-0.5l11.76 0c0.27,0 0.5,0.23 0.5,0.5 0,0.28 -0.23,0.5 -0.5,0.5l-11.76 0z" />
    </g>
  </svg>
);
