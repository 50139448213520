/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { OutlinedInput, TextField } from '@mui/material';
import { t } from 'i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import { DrivingLicenceSnapshot } from '../../store/employeeStore/DrivingLicenseStore';
import {
  DriverLicenceForm,
  EmployeeForm,
} from '../../containers/DriverDetailsPage';
import styles from './Details.module.scss';
import { ContactPersonForm } from '../../containers/TradePartnerDetailsPage';
import { FormButtonContainer } from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  firstName: string;
  lastName: string;
  onSave(form: ContactPersonForm): void;
  cancel(): void;
  mode: 'new' | 'edit';
}

export const ContactPersonsForm: FunctionComponent<Props> = ({
  firstName,
  lastName,
  onSave,
  mode,
  cancel,
}) => {
  const [_firstName, setFirstName] = useState(firstName);
  const [_lastName, setLastName] = useState(lastName);

  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);

  return (
    <section
      style={{ width: 600 }}
      id="Licnce Details"
      className={styles['SectionContainer']}
    >
      <span style={{ margin: 0 }} className={styles['SectionTitle']}>
        {t('input.licence')}
      </span>
      <div className={styles['LabelWithInput']}>
        <label className={styles['Label']}>{t('input.first_name')}:</label>
        <OutlinedInput
          size="small"
          error={_firstName === ''}
          onChange={handleInputValue(setFirstName)}
          placeholder={t('input.first_name')}
          value={_firstName}
          sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
        />
      </div>
      <div className={styles['LabelWithInput']}>
        <label className={styles['Label']}>{t('input.last_name')}:</label>
        <OutlinedInput
          size="small"
          error={_lastName === ''}
          onChange={handleInputValue(setLastName)}
          placeholder={t('input.last_name')}
          value={_lastName}
          sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
        />
      </div>
      <FormButtonContainer>
        <LoadingButton
          disabled={!allNotEmpty([_firstName, _lastName])}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '200px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              firstName: _firstName,
              lastName: _lastName,
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_contact_person') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '200px',
            alignSelf: 'center',
            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => cancel()}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </section>
  );
};
