/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from '@mui/lab';
import { OutlinedInput, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { AddressSnapshot } from '../../store/tradePartners/AddressStore';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import { FormOutlinedInput, FromTextInput } from '../style/form';
import styles from './Details.module.scss';

type Props = {
  companyName: string;
  NIP: string;
  REGON: string;
  address: AddressSnapshot;
  mailDropAddress: AddressSnapshot;
  paymentDueDateChange: string;
  paymentMethod: string;
  currency: string;
  email: string;
  phoneNumber: string;
  rate: string;
  onCompanyNameChange(value: string): void;
  onNIPChange(value: string): void;
  onREGONChange(value: string): void;
  onAddressChange(value: AddressSnapshot): void;
  onMailDropAddressChange(value: AddressSnapshot): void;
  onPaymentDueDateChange(value: string): void;
  onPaymentMethodChange(value: string): void;
  onCurrencyChange(value: string): void;
  onEmailChange(value: string): void;
  onPhoneNumberChange(value: string): void;
  onRateChange(value: string): void;
};

export const TradePartnerBasicForm: FunctionComponent<Props> = ({
  onCompanyNameChange,
  onNIPChange,
  onREGONChange,
  onAddressChange,
  onMailDropAddressChange,
  onPaymentDueDateChange,
  onPaymentMethodChange,
  onCurrencyChange,
  onEmailChange,
  onPhoneNumberChange,
  onRateChange,
  ...props
}) => {
  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);

  const handleAddressPartChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    func: (value: AddressSnapshot) => void
  ) => {
    const addressCopy: AddressSnapshot = { ...props.address };
    if (key in addressCopy) {
      addressCopy[key] = e.target.value;
      func(addressCopy);
    }
  };

  return (
    <div>
      <span className={styles['SectionTitle']}>{t('input.trade_partner')}</span>
      <section id="Driver Details" className={styles['SectionContainer']}>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.company_name')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.companyName === ''}
            onChange={handleInputValue(onCompanyNameChange)}
            placeholder={t('input.company_name')}
            value={props.companyName}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.nip')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.NIP === ''}
            onChange={handleInputValue(onNIPChange)}
            placeholder={t('input.nip')}
            value={props.NIP}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.regon')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.REGON === ''}
            onChange={handleInputValue(onREGONChange)}
            placeholder={t('input.regon')}
            value={props.REGON}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div
          className={styles['LabelWithInput']}
          style={{ fontWeight: 700, padding: '20px 0' }}
        >
          {t('input.address')}
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.country')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.country === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'country', onAddressChange)
            }
            placeholder={t('input.country')}
            value={props.address.country}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.city')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.city === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'city', onAddressChange)
            }
            placeholder={t('input.city')}
            value={props.address.city}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.post')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.post === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'post', onAddressChange)
            }
            placeholder={t('input.post')}
            value={props.address.post}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.street')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.street === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'street', onAddressChange)
            }
            placeholder={t('input.street')}
            value={props.address.street}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.postcode')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.postcode === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'postcode', onAddressChange)
            }
            placeholder={t('input.postcode')}
            value={props.address.postcode}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.voivodeship')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.address.voivodeship === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'voivodeship', onAddressChange)
            }
            placeholder={t('input.voivodeship')}
            value={props.address.voivodeship}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div
          className={styles['LabelWithInput']}
          style={{ fontWeight: 700, padding: '20px 0' }}
        >
          {t('input.mail_drop_address')}
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.country')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.country === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'country', onMailDropAddressChange)
            }
            placeholder={t('input.country')}
            value={props.mailDropAddress.country}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.city')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.city === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'city', onMailDropAddressChange)
            }
            placeholder={t('input.city')}
            value={props.mailDropAddress.city}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.post')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.post === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'post', onMailDropAddressChange)
            }
            placeholder={t('input.post')}
            value={props.mailDropAddress.post}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.street')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.street === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'street', onMailDropAddressChange)
            }
            placeholder={t('input.street')}
            value={props.mailDropAddress.street}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.postcode')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.postcode === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'postcode', onMailDropAddressChange)
            }
            placeholder={t('input.postcode')}
            value={props.mailDropAddress.postcode}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.voivodeship')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.mailDropAddress.voivodeship === ''}
            onChange={(e) =>
              handleAddressPartChange(e, 'voivodeship', onMailDropAddressChange)
            }
            placeholder={t('input.voivodeship')}
            value={props.mailDropAddress.voivodeship}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <hr />
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.payment_due_date_change')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && onPaymentDueDateChange(e)}
            value={
              props.paymentDueDateChange &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(props.paymentDueDateChange))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.payment_due_date_change')}
              />
            )}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.payment_method')}:
          </label>
          <FormOutlinedInput
            size="small"
            error={props.paymentMethod === ''}
            onChange={handleInputValue(onPaymentMethodChange)}
            placeholder={t('input.payment_method')}
            value={props.paymentMethod}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.currency')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.currency === ''}
            onChange={handleInputValue(onCurrencyChange)}
            placeholder={t('input.currency')}
            value={props.currency}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.email')}:</label>
          <FormOutlinedInput
            size="small"
            error={props.email === ''}
            onChange={handleInputValue(onEmailChange)}
            placeholder={t('input.email')}
            value={props.email}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.phone_number')}:</label>
          <FormOutlinedInput
            size="small"
            type="tel"
            error={props.phoneNumber === ''}
            onChange={handleInputValue(onPhoneNumberChange)}
            placeholder={t('input.phone_number')}
            value={props.phoneNumber}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.rate')}:</label>
          <FormOutlinedInput
            size="small"
            type="number"
            error={props.rate === ''}
            onChange={handleInputValue(onRateChange)}
            placeholder={t('input.rate')}
            value={props.rate}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
      </section>
    </div>
  );
};
