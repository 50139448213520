import { makeAutoObservable } from 'mobx';
import { InMemoryService } from '../../service/InMemoryService';
import { MockService } from '../../service/MockService';
import { TradePartnerService } from '../../service/Service';
import { statusType } from '../../types/Types';
import { TradePartnerStore } from './TradePartnerStore';

class TradePartnersStore {
  constructor(
    private httpService: TradePartnerService,
    private tradePartners: TradePartnerStore[] = [],
    private status: statusType = 'complete'
  ) {
    makeAutoObservable(this);
  }

  getTradePartners() {
    return this.tradePartners;
  }

  getById(id: number) {
    const tradePartner = this.tradePartners.find((x) => x.getId() === id);
    return tradePartner;
  }

  getNextId() {
    const max = Math.max(...this.tradePartners.map((x) => x.getId()));
    return max + 1;
  }

  async fetchTradePartners() {
    try {
      this.status = 'loading';
      this.tradePartners = await this.httpService.getTradePartners();
      this.status = 'complete';
    } catch (error) {
      this.status = 'error';
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  addTradePartner(tradePartner: TradePartnerStore) {
    this.httpService.createTradePartner(tradePartner);
    this.tradePartners.push(tradePartner);
  }

  updateTradePartner(tradePartner: TradePartnerStore) {
    const index = this.tradePartners.findIndex(
      (x) => x.getId() === tradePartner.getId()
    );
    if (index > -1) {
      this.httpService.updateTradePartner(tradePartner);
      this.tradePartners[index] = tradePartner;
    }
  }

  deleteTradePartner(tradePartnerID: number) {
    this.httpService.deleteTradePartner(tradePartnerID);
    this.tradePartners = this.tradePartners.filter(
      (x) => x.getId() !== tradePartnerID
    );
  }
}

export const tradePartnersStore = new TradePartnersStore(new InMemoryService());
