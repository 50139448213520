/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { AddressStore } from '../store/tradePartners/AddressStore';
import { ContactPerson } from '../store/tradePartners/ContactPerson';
import { TradePartnerStore } from '../store/tradePartners/TradePartnerStore';

const mockAddress_1: AddressStore = new AddressStore(
  1,
  'Poland',
  'Częstochowa',
  'Poczta',
  'Dekabrystów',
  '42-200',
  'Śląskie'
);

const mockAddress_2: AddressStore = new AddressStore(
  2,
  'USA',
  'Detroit',
  'Post',
  'Alter Road',
  '48201',
  'Michigan'
);

const mockAddress_3: AddressStore = new AddressStore(
  3,
  'USA',
  'Mountain View',
  'Mountain View',
  '1600 Amphitheatre Parkway',
  '94035',
  'California'
);

const mockContactPerson_1: ContactPerson = new ContactPerson(
  1,
  'Dariusz',
  'Woszczyna'
);

const mockContactPerson_2: ContactPerson = new ContactPerson(
  2,
  'Adam',
  'Jensen'
);

const mockContactPerson_3: ContactPerson = new ContactPerson(
  3,
  'Faridah',
  'Malik'
);

const mockContactPerson_4: ContactPerson = new ContactPerson(4, 'Ben', 'Saxon');

export const mockTradePartner_1: TradePartnerStore = new TradePartnerStore(
  1,
  'Si4',
  '573-29-21-541',
  '38708803500000',
  mockAddress_1,
  mockAddress_1,
  dayjs().add(5, 'month').toDate(),
  'CASH',
  'PLN',
  'contact@si4.pl',
  '+48 533 396 135',
  [mockContactPerson_1],
  5
);

export const mockTradePartner_2: TradePartnerStore = new TradePartnerStore(
  2,
  'Sarif Industries',
  '2007-2028',
  '302010',
  mockAddress_2,
  mockAddress_2,
  dayjs().add(2, 'month').toDate(),
  'Bank Transfer',
  'Credits',
  'contact@sarif.industries.com',
  '+48 555 555 555',
  [mockContactPerson_2],
  5
);

export const mockTradePartner_3: TradePartnerStore = new TradePartnerStore(
  3,
  'Google',
  '5252344078',
  '140182840',
  mockAddress_3,
  mockAddress_3,
  dayjs().add(13, 'month').toDate(),
  'Bank Transfer',
  'Credits',
  'google@gmail.com',
  '+48 001 000 000',
  [mockContactPerson_3, mockContactPerson_4],
  4
);

export const mockTradePartners: TradePartnerStore[] = [
  mockTradePartner_1,
  mockTradePartner_2,
  mockTradePartner_3,
];

export const initTradePartnersLocalStore = () => {
  const initialized = localStorage.getItem('tradePartners');
  if (!initialized) {
    localStorage.setItem('tradePartners', JSON.stringify(mockTradePartners));
  }
};
