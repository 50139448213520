import React from 'react';
import { DashboardPage } from '../containers/DashboardPage';
import { TransportsPage } from '../containers/TransportsPage';
import { SchedulerPage } from '../containers/SchedulerPage';
import { TransportDetailsPage } from '../containers/TransportDetailsPage';
import { navLinkType } from '../types/Types';
import { DriversSettingsPage } from '../containers/DriversSettingsPage';
import { DriverDetailsPage } from '../containers/DriverDetailsPage';
import { TrucksSettingsPage } from '../containers/TrucksSettingsPage';
import { TrailersSettingsPage } from '../containers/TrailersSettingsPage';
import { TradePartnersSettingsPage } from '../containers/TradePartnersSettingsPage';
import { TruckDetailsPage } from '../containers/TruckDetailsPage';
import { TrailerDetailsPage } from '../containers/TrailerDetailsPage';
import { TradePartnerDetailsPage } from '../containers/TradePartnerDetailsPage';

export class Pages {
  static readonly PAGES: navLinkType[] = [
    {
      index: 0,
      to: '/dashboard',
      label: 'Dashboard',
      children: () => <DashboardPage />,
      showInRootNavigation: true,
      nestedPaths: [
        {
          index: 0,
          to: 'transportDetails/:transportId',
          label: 'Trasnsport Details',
          children: () => <TransportDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 1,
      to: '/transports',
      label: 'Transports',
      children: () => <TransportsPage />,
      showInRootNavigation: true,
      nestedPaths: [
        {
          index: 0,
          to: 'transportDetails/:transportId',
          label: 'Trasnsport Details',
          children: () => <TransportDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 2,
      to: '/scheduler',
      label: 'Scheduler',
      children: () => <SchedulerPage />,
      showInRootNavigation: true,
      nestedPaths: [
        {
          index: 0,
          to: 'transportDetails/:transportId',
          label: 'Transport Details',
          children: () => <TransportDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 3,
      to: '/addTransport',
      label: 'Add Transport',
      children: () => <TransportDetailsPage />,
      showInRootNavigation: false,
      nestedPaths: [],
    },
    {
      index: 4,
      to: '/driverSettings',
      label: 'Driver Settings',
      children: () => <DriversSettingsPage />,
      showInRootNavigation: false,
      nestedPaths: [
        {
          index: 0,
          to: 'driverDetails/:driverId',
          label: 'Trasnsport Details',
          children: () => <DriverDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
        {
          index: 1,
          to: 'createDriver',
          label: 'Trasnsport Details',
          children: () => <DriverDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 5,
      to: '/truckSettings',
      label: 'Truck Settings',
      children: () => <TrucksSettingsPage />,
      showInRootNavigation: false,
      nestedPaths: [
        {
          index: 0,
          to: 'truckDetails/:truckId',
          label: 'Trasnsport Details',
          children: () => <TruckDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
        {
          index: 1,
          to: 'createTruck',
          label: 'Trasnsport Details',
          children: () => <TruckDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 6,
      to: '/trailerSettings',
      label: 'Trailer Settings',
      children: () => <TrailersSettingsPage />,
      showInRootNavigation: false,
      nestedPaths: [
        {
          index: 0,
          to: 'trailerDetails/:trailerId',
          label: 'Trailer Details',
          children: () => <TrailerDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
        {
          index: 1,
          to: 'createTrailer',
          label: 'Trailer Details',
          children: () => <TrailerDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
    {
      index: 7,
      to: '/tradePartnersSettings',
      label: 'Trade Partners Settings',
      children: () => <TradePartnersSettingsPage />,
      showInRootNavigation: false,
      nestedPaths: [
        {
          index: 0,
          to: 'tradePartnerDetails/:tradePartnerId',
          label: 'Trade Partner Details',
          children: () => <TradePartnerDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
        {
          index: 1,
          to: 'createTradePartner',
          label: 'Create Trade Partner',
          children: () => <TradePartnerDetailsPage />,
          showInRootNavigation: false,
          nestedPaths: [],
        },
      ],
    },
  ];
}
