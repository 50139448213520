/* eslint-disable react/no-array-index-key */
import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { employeesStore } from '../../store/employeeStore/EmployeesStore';
import { schedulerPageStore } from '../../store/SchedulerPageStore';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';
import { CurrentTimeIndicator } from './currentTimeIndicator/CurrentTimeIndicator';
import { SchedulerLine } from './schedulerLine/SchedulerLine';
import styles from './Scheduler.module.scss';

export const Scheduler = observer(() => {
  const store = schedulerPageStore;
  const { timeScale, ticks, dayTicks } = store.getScale();

  const setCursor = (cursor?: string) => {
    document.body.style.cursor = cursor || '';
  };

  const mouseEvents = {
    onMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      if (e.buttons === 1) {
        setCursor('grab');
        store.moveBy(e.movementX * -1);
      }
    },
    onMouseUp() {
      setCursor();
    },
    onWheel(e: React.WheelEvent<HTMLDivElement>) {
      store.zoomBy(e.deltaY);
    },
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles['SchedulerContainer']} {...mouseEvents}>
      <CurrentTimeIndicator timeScale={timeScale} />
      {ticks.map((tick, i) => (
        <big
          key={`${tick}-${i}`}
          className={styles['HourTick']}
          style={{ left: timeScale(tick) }}
        />
      ))}
      {dayTicks.map((tick, i) => (
        <b
          key={`${tick}-${i}`}
          className={styles['DayTick']}
          style={{ left: timeScale(tick) }}
        />
      ))}

      <header>
        <div className={styles['HeaderDayRow']}>
          {dayTicks.map((tick, i) => (
            <span
              key={`${tick}-${i}-'day`}
              className={styles['DayTickLabel']}
              style={{
                left: timeScale(tick),
                width:
                  timeScale(dayjs(tick).add(1, 'day').toDate()) -
                  timeScale(tick),
              }}
            >
              {getFormattedDateWithNamedDayAndMonth(tick)}
            </span>
          ))}
        </div>
        <div className={styles['HeaderHourRow']}>
          {ticks.map((tick, i) => (
            <span
              key={`${tick}-${i}-'houe`}
              className={styles['HourTickLabel']}
              style={{ left: timeScale(tick) }}
            >
              {tick.getHours()}
            </span>
          ))}
        </div>
      </header>

      <div className={styles['Content']}>
        <div className={styles['SchedulerLines']}>
          {store.getViewWithDriversLines() ? (
            employeesStore
              .getEmployees()
              .map((employee) => (
                <SchedulerLine
                  key={employee.getId()}
                  timeScale={timeScale}
                  driver={employee}
                />
              ))
          ) : (
            <SchedulerLine timeScale={timeScale} />
          )}
        </div>
      </div>
    </div>
  );
});

Scheduler.displayName = 'Scheduler';
