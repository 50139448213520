/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';

import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import { DrivingLicenceSnapshot } from '../../store/employeeStore/DrivingLicenseStore';
import { EmployeeForm } from '../../containers/DriverDetailsPage';
import { DriverBasicForm } from './DriverBasicSection';
import { DriverLicencesTable } from './DriverLicencesTable';
import { Form, FormButtonContainer } from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  firstName: string;
  lastName: string;
  pesel: string;
  idCard: string;
  phoneNumber: string;
  drivingLicenses: DrivingLicenceSnapshot[];
  permissionsExpiryDate: string;
  tachographExpiryDate: string;
  onSave(form: EmployeeForm): void;
  mode: 'new' | 'edit';
  loading: boolean;
}

export const DriverDetailsForm: FunctionComponent<Props> = ({
  firstName,
  lastName,
  idCard,
  phoneNumber,
  drivingLicenses,
  permissionsExpiryDate,
  tachographExpiryDate,
  pesel,
  onSave,
  mode,
  loading,
}) => {
  const [_firstName, setFirstName] = useState(firstName);
  const [_lastName, setLastName] = useState(lastName);
  const [_pesel, setPesel] = useState(pesel);
  const [_idCard, setIdCard] = useState(idCard);
  const [_phoneNumber, setPhoneNumber] = useState(phoneNumber);
  const [_drivingLicenses, setDrivingLicenses] = useState(drivingLicenses);
  const [_permissionsExpiryDate, setPermissionsExpiryDate] = useState(
    permissionsExpiryDate
  );

  const [_tachographExpiryDate, setTachographExpiryDate] =
    useState(tachographExpiryDate);

  const navigate = useNavigate();

  const editLicenceHandler = (licence: DrivingLicenceSnapshot) => {
    setDrivingLicenses((licences) =>
      licences.map((x) => {
        if (x.id === licence.id) {
          return licence;
        }
        return x;
      })
    );
  };
  const deleteLicenceHandler = (licence: DrivingLicenceSnapshot) => {
    setDrivingLicenses((licences) =>
      licences.filter((x) => x.id !== licence.id)
    );
  };
  const createLicencHandler = (licence: DrivingLicenceSnapshot) => {
    setDrivingLicenses((licences) => [...licences, licence]);
  };
  useEffect(() => {
    refreshState();
  }, [
    firstName,
    lastName,
    pesel,
    idCard,
    phoneNumber,
    drivingLicenses,
    permissionsExpiryDate,
    tachographExpiryDate,
  ]);
  const refreshState = () => {
    setFirstName(firstName);
    setLastName(lastName);
    setPesel(pesel);
    setIdCard(idCard);
    setPhoneNumber(phoneNumber);
    setDrivingLicenses(drivingLicenses);
    setPermissionsExpiryDate(permissionsExpiryDate);
    setTachographExpiryDate(tachographExpiryDate);
  };

  return (
    <Form>
      <DriverBasicForm
        firstName={_firstName}
        lastName={_lastName}
        phoneNumber={_phoneNumber}
        pesel={_pesel}
        permissionsExpiryDate={_permissionsExpiryDate}
        tachographExpiryDate={_tachographExpiryDate}
        onFirstnameChange={setFirstName}
        onLastnameChange={setLastName}
        onPermissionsExpiryDateChange={setPermissionsExpiryDate}
        onPhoneNumberChange={setPhoneNumber}
        onTachographExpiryDateCange={setTachographExpiryDate}
        onPeselChange={setPesel}
      />
      <DriverLicencesTable
        onLicenceEdit={editLicenceHandler}
        onLicenceDelete={deleteLicenceHandler}
        onLicenceCreate={createLicencHandler}
        driverLicences={_drivingLicenses}
      />
      <FormButtonContainer>
        <LoadingButton
          disabled={
            !allNotEmpty([
              _firstName,
              _lastName,
              _phoneNumber,
              _permissionsExpiryDate,
              _tachographExpiryDate,
              _pesel,
            ])
          }
          loading={loading}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              firstName: _firstName,
              lastName: _lastName,
              idCard: _idCard,
              pesel: _pesel,
              phoneNumber: _phoneNumber,
              permissionsExpiryDate: _permissionsExpiryDate,
              drivingLicenses: _drivingLicenses,
              tachographExpiryDate: _tachographExpiryDate,
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_driver') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',
            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => navigate(-1)}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </Form>
  );
};
