/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { InMemoryService } from '../../service/InMemoryService';
import { MockService } from '../../service/MockService';
import { TransportService } from '../../service/Service';
import {
  statusType,
  transportTableAdditionalDataType,
} from '../../types/Types';
import { transportsPageStore } from '../TransportsPageStore';
import { TransportStore } from './TransportStore';

class TransportsStore {
  constructor(
    private httpService: TransportService,
    private transports: TransportStore[] = [],
    private status: statusType = 'complete'
  ) {
    makeAutoObservable(this);
  }

  getTransports() {
    return this.transports;
  }

  getStatus() {
    return this.status;
  }

  getTrnasportsForTransportsPage(): Array<
    transportTableAdditionalDataType | TransportStore
  > {
    const selectedDay = transportsPageStore.getCurrentDate();

    const days: Date[] = [selectedDay];

    if (transportsPageStore.getIsNextDayIncluded()) {
      const nextDay = dayjs(selectedDay).add(1, 'day').toDate();
      days.push(nextDay);
    }

    const transports = this.getTransports()
      .filter((transport) => {
        const chosenDriver = transportsPageStore.getChosenDriver();
        if (
          chosenDriver &&
          transport.getDriver().getId() !== chosenDriver.getId()
        ) {
          return false;
        }

        if (
          !transportsPageStore.getShowCompletedTransports() &&
          transport.getStatus() === 'finished'
        ) {
          return false;
        }

        if (transportsPageStore.getIsNextDayIncluded()) {
          const isAfterNextDay = dayjs(transport.getDepartureDate()).isAfter(
            dayjs(selectedDay).add(1, 'day'),
            'day'
          );
          const isSelectedDay = dayjs(transport.getDepartureDate()).isSame(
            selectedDay,
            'day'
          );
          const isNextDay = dayjs(transport.getDepartureDate()).isSame(
            dayjs(selectedDay).add(1, 'day'),
            'day'
          );
          if (isAfterNextDay) {
            return false;
          }
          if (
            !isSelectedDay &&
            !isNextDay &&
            transport.getStatus() === 'finished'
          ) {
            return false;
          }
        } else {
          const isAfterSelectedDay = dayjs(
            transport.getDepartureDate()
          ).isAfter(selectedDay, 'day');
          const isSelectedDay = dayjs(transport.getDepartureDate()).isSame(
            selectedDay,
            'day'
          );
          if (isAfterSelectedDay) {
            return false;
          }
          if (!isSelectedDay && transport.getStatus() === 'finished') {
            return false;
          }
        }
        days.push(transport.getDepartureDate());
        return true;
      })
      .sort(
        (a, b) =>
          a.getDepartureDate().getTime() - b.getDepartureDate().getTime()
      );

    const uniqueDays = days
      .filter(
        (day, index, array) =>
          array.findIndex((t) => dayjs(t).isSame(day, 'day')) === index
      )
      .sort((a, b) => a.getTime() - b.getTime());

    let result: Array<transportTableAdditionalDataType | TransportStore> = [];
    uniqueDays.forEach((date) => {
      const filteredTransports = transports.filter((transport) => {
        const isSameDay = dayjs(transport.getDepartureDate()).isSame(
          date,
          'day'
        );
        if (isSameDay) {
          return true;
        }
        return false;
      });
      const count = filteredTransports.length;
      if (filteredTransports.length > 0) {
        result = result.concat(
          { header: true, empty: false, date: date, count: count },
          ...filteredTransports
        );
      } else {
        result = result.concat(
          { header: true, empty: false, date: date, count: 0 },
          { header: false, empty: true, date: date, count: 0 }
        );
      }
    });
    return result;
  }

  getNewTransportId() {
    const maxId = this.transports.reduce(
      (actual, transport) =>
        (actual = actual > transport.getId() ? actual : transport.getId()),
      0
    );
    const newId = maxId + 1;
    return newId;
  }

  async createTransport(transport: TransportStore) {
    try {
      await this.httpService.createTransport(transport);
      this.transports.push(transport);
      return 'complete';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return 'error';
    }
  }

  updateTransport(newTransport: TransportStore): statusType {
    try {
      this.httpService.updateTransport(newTransport);
      const transportIndex = this.transports.findIndex(
        (transport) => transport.getId() === newTransport.getId()
      );
      this.transports[transportIndex] = newTransport;
      return 'complete';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return 'error';
    }
  }

  getLastDriverTransport(driverId: number): TransportStore | null {
    const driverTransports = this.transports
      .filter((transport) => transport.getDriver().getId() === driverId)
      .sort(
        (a, b) => a.getDepartureDate().getTime() - b.getArrivalDate().getTime()
      );
    if (driverTransports.length) {
      return driverTransports[0];
    }
    return null;
  }

  findById(transportId: number): TransportStore | undefined {
    const transport = this.transports.find(
      (transport) => transport.getId() === transportId
    );
    return transport;
  }

  async fetchTransports() {
    try {
      this.status = 'loading';
      this.transports = await this.httpService.getTransports();
      this.status = 'complete';
    } catch (error) {
      this.status = 'error';
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export const transportsStore = new TransportsStore(new InMemoryService());
