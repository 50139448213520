import { makeAutoObservable } from 'mobx';
import { InMemoryService } from '../../service/InMemoryService';
import { MockService } from '../../service/MockService';
import { TrailerService } from '../../service/Service';
import { statusType } from '../../types/Types';
import { TrailerStore } from './TrailerStore';

class TrailersStore {
  constructor(
    private httpService: TrailerService,
    private trailers: TrailerStore[] = [],
    private status: statusType = 'complete',
  ) {
    makeAutoObservable(this);
  }

  getTrailers() {
    return this.trailers;
  }

  async fetchTrailers() {
    try {
      this.status = 'loading';
      this.trailers = await this.httpService.getTrailers();
      this.status = 'complete';
    } catch (error) {
      this.status = 'error';
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getById(id: number) {
    const trailer = this.trailers.find((x) => x.getId() === id);
    return trailer;
  }

  getNextId() {
    const max = Math.max(...this.trailers.map((x) => x.getId()));
    return max + 1;
  }


  addTrailer(trailer: TrailerStore) {
    this.httpService.createTrailer(trailer);
    this.trailers.push(trailer);
  }

  updateTrailer(trailer: TrailerStore) {
    const index = this.trailers.findIndex(
      (x) => x.getId() === trailer.getId()
    );
    if (index > -1) {
      this.httpService.updateTrailer(trailer);
      this.trailers[index] = trailer;
    }
  }

  deleteTrailer(trailerID:number){
    
    this.httpService.deleteTrailer(trailerID);
    this.trailers = this.trailers.filter((x) => x.getId() !== trailerID);
  
  }

}

export const trailersStore = new TrailersStore(new InMemoryService());
