import { makeAutoObservable } from 'mobx';

export type DrivingLicenceSnapshot = {
  id: number;
  category: string;
  expiryDate: string;
};
export class DrivingLicenceStore {
  constructor(
    private id: number,
    private category: string,
    private expiryDate: Date
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getCategory() {
    return this.category;
  }

  getExpiryDate() {
    return this.expiryDate;
  }

  static fromSnapshot(snapshot: DrivingLicenceSnapshot) {
    return new DrivingLicenceStore(
      snapshot.id,
      snapshot.category,
      new Date(Date.parse(snapshot.expiryDate))
    );
  }

  snapshot(): DrivingLicenceSnapshot {
    return {
      id: this.id,
      category: this.category,
      expiryDate: this.expiryDate.toUTCString(),
    };
  }
}
