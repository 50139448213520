import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TransportStore } from '../../store/transportStore/TransportStore';
import { PlaceAndTime } from '../placeAndTime/PlaceAndTime';
import { TransportTooltip } from '../transportTooltip/TransportTooltip';
import styles from './Truck.module.scss';

interface iTruckProps {
  transport: TransportStore;
}

export const Truck = ({ transport }: iTruckProps) => {
  const navigate = useNavigate();

  return (
    <TransportTooltip transport={transport}>
      <div
        className={styles['TruckContainer']}
        onClick={() => navigate(`transportDetails/${transport.getId()}`)}
      >
        <div className={styles['TrailerWall']}>
          <div className={styles['Trailer']}>
            <div className={styles['TrailerTopSection']}>
              <span className={styles['TransportId']}>{transport.getId()}</span>
              {transport.getPriority() && (
                <div className={styles['Priority']}>
                  <span>P</span>
                </div>
              )}
            </div>
            <div className={styles['TrailerBottomSection']}>
              <PlaceAndTime transport={transport} type="departure" />
              <PlaceAndTime transport={transport} type="arrival" />
            </div>
          </div>
        </div>
        <div className={styles['Floor']}>
          <div className={styles['Lights']} style={{ marginLeft: '5px' }}>
            <div className={styles['WhiteLight']} />
            <div className={styles['RedBigLight']} />
            <div className={styles['YellowLight']} />
            <div className={styles['RedLight']} />
          </div>
          <div className={styles['NumberPlate']}>
            <span>{transport.getDriver().getFirstName()}</span>
            <span>{transport.getDriver().getLastName()}</span>
          </div>
          <div className={styles['Lights']} style={{ marginRight: '5px' }}>
            <div className={styles['RedLight']} />
            <div className={styles['YellowLight']} />
            <div className={styles['RedBigLight']} />
            <div className={styles['WhiteLight']} />
          </div>
        </div>
        <div className={styles['Wheels']}>
          <div className={styles['LeftWheels']}>
            <div className={styles['Wheel']} />
            &nbsp;
            <div className={styles['Wheel']} />
          </div>
          <div className={styles['RightWheels']}>
            <div className={styles['Wheel']} />
            &nbsp;
            <div className={styles['Wheel']} />
          </div>
        </div>
      </div>
    </TransportTooltip>
  );
};
