import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/system';
import { getMuiCustomTheme } from './theme/MuiCustomTheme';
import { useTheme } from './theme/ThemeContext';
import { Header } from './components/header/Header';
import { AppRoutes } from './components/appRoutes/AppRoutes';
import { employeesStore } from './store/employeeStore/EmployeesStore';
import { transportsStore } from './store/transportStore/TransportsStore';
import { trucksStore } from './store/trucksStore/TrucksStore';
import { trailersStore } from './store/trailerStore/TrailersStore';
import { tradePartnersStore } from './store/tradePartners/TradePartnersStore';
import styles from './App.module.scss';
import { initTrucksLocalStore } from './mock/TrucksMock';
import { initEmployeesLocalStore } from './mock/EmployeesMock';
import { initTransportsLocalStore } from './mock/TransportsMock';
import { initTrailersLocalStore } from './mock/TrailersMock';
import { initTradePartnersLocalStore } from './mock/TradePartnersMock';

initTrucksLocalStore();
initEmployeesLocalStore();
initTransportsLocalStore();
initTrailersLocalStore();
initTradePartnersLocalStore();

export const App = () => {
  const { getTheme } = useTheme();

  useEffect(() => {
    employeesStore.fetchEmployees();
    transportsStore.fetchTransports();
    trucksStore.fetchTrucks();
    trailersStore.fetchTrailers();
    tradePartnersStore.fetchTradePartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={getMuiCustomTheme(getTheme())}>
      <div className={styles['MainContainer']}>
        <Header />
        <div className={styles['PageContainer']}>
          <div className={styles['OverflowControl']}>
            <AppRoutes />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
