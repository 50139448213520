import React from 'react';
import { Scheduler } from '../components/scheduler/Scheduler';
import { SchedulerFilterBar } from '../components/scheduler/schedulerFilterBar/SchedulerFilterBar';

export const SchedulerPage = () => (
  <div style={{ display: 'flex', flexDirection: 'column', width: ' 100%' }}>
    <SchedulerFilterBar />
    <Scheduler />
  </div>
);
