import React from 'react';
import { ScaleTime } from 'd3-scale';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { schedulerPageStore } from '../../../store/SchedulerPageStore';
import { TransportStore } from '../../../store/transportStore/TransportStore';
import { getHHmm } from '../../../utils/DateUtils';
import { TransportTooltip } from '../../transportTooltip/TransportTooltip';
import styles from './TransportEntry.module.scss';

interface iTransportEntryProps {
  transport: TransportStore;
  timeScale: ScaleTime<number, number, never>;
}

export const TransportEntry = observer(
  ({ transport, timeScale }: iTransportEntryProps) => {
    const navigate = useNavigate();
    const level =
      schedulerPageStore.getSchedulerLineLevel().get(transport.getId()) || 0;
    const isViewWithDriversLines = schedulerPageStore.getViewWithDriversLines();

    const getTopValue = () => {
      if (isViewWithDriversLines) {
        return level * 10;
      }
      return level === 0 ? 20 : level * 60;
    };

    const shouldBeDimmed = () => {
      const chosenDriver = schedulerPageStore.getChosenDriver();

      return (
        chosenDriver && chosenDriver.getId() !== transport.getDriver().getId()
      );
    };

    return (
      <TransportTooltip transport={transport}>
        <div
          className={`${styles[`TransportEntry--${transport.getStatus()}`]} ${
            shouldBeDimmed() ? styles['TransportEntry--dimmed'] : ''
          }`}
          style={{
            top: getTopValue(),
            left: timeScale(transport.getDepartureDate()),
            width:
              timeScale(transport.getArrivalDate()) -
              timeScale(transport.getDepartureDate()) -
              12,
          }}
          onClick={() => navigate(`transportDetails/${transport.getId()}`)}
        >
          <em className={styles['TimeIndicator--start']}>
            {getHHmm(transport.getDepartureDate())}
          </em>
          <em className={styles['TimeIndicator--end']}>
            {getHHmm(transport.getArrivalDate())}
          </em>
          <div className={styles['Inner']}>
            <div className={styles['TransportId']}>
              <strong>{transport.getId()}</strong>
              {transport.getPriority() && (
                <div className={styles['Priority']}>
                  <span>P</span>
                </div>
              )}
            </div>
            <span>{transport.getSourceTradePartner().getCompanyName()}</span>
            <br />
            <span>
              {transport.getDestinationTradePartner().getCompanyName()}
            </span>
          </div>
        </div>
      </TransportTooltip>
    );
  }
);

TransportEntry.displayName = 'TransportEntry';
