/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { localStorageValueEnum } from '../enums/Enums';
import { themeContextType, themeType } from '../types/Types';

const ThemeContext = React.createContext<themeContextType>({
  getTheme: () => 'light',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeTheme: () => {},
});

const themes = ['darkTheme.css', 'lightTheme.css'];

interface iThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: iThemeProviderProps) => {
  const [theme, setTheme] = useState<themeType>('light');

  const getThemeFromLocalStorage = (): themeType => {
    const savedTheme = localStorage.getItem(localStorageValueEnum.theme);
    if ((savedTheme as themeType | null) === 'light' || !savedTheme) {
      return 'light';
    }
    return 'dark';
  };

  useEffect(() => {
    setTheme(getThemeFromLocalStorage());
  }, []);

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  const getTheme = (): themeType => theme;

  const changeTheme = () => {
    switch (theme) {
      case 'light':
        setTheme('dark');
        break;
      case 'dark':
        setTheme('light');
        break;
      default:
        throw new Error('Wrong theme');
    }
  };

  const applyTheme = (theme: themeType) => {
    const link = document.getElementById('stylesheet');
    switch (theme) {
      case 'light':
        link?.setAttribute('href', themes[1]);
        break;
      case 'dark':
        link?.setAttribute('href', themes[0]);
        break;
      default:
        throw new Error('Wrong theme');
    }
    localStorage.setItem(localStorageValueEnum.theme, theme);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ThemeContext.Provider value={{ getTheme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
