/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
import { AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import { Column, useTable } from 'react-table';
import Popup from 'reactjs-popup';
import { DriverLicenceForm } from '../../containers/DriverDetailsPage';
import { ContactPersonForm } from '../../containers/TradePartnerDetailsPage';
import { ContactPersonSnapshot } from '../../store/tradePartners/ContactPerson';
import { DriverLicencesForm } from '../driver/DriverLicencesForm';
import { DeleteForm } from '../style/form';
import { TableStyles, TextLink } from '../style/table';
import { ContactPersonsForm } from './ContactPersonsForm';
import styles from '../style/Details.module.scss';

type Props = {
  contactPersons: ContactPersonSnapshot[];
  onContactPersonEdit(contactPerson: ContactPersonSnapshot): void;
  onContactPersonDelete(contactPerson: ContactPersonSnapshot): void;
  onContactPersonCreate(contactPerson: ContactPersonSnapshot): void;
};

export const ContactPersonsTable: FunctionComponent<Props> = ({
  contactPersons,
  onContactPersonEdit,
  onContactPersonDelete,
  onContactPersonCreate,
}) => {
  const data = contactPersons;

  const columns = React.useMemo<Column<ContactPersonSnapshot>[]>(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName', // accessor is the "key" in the data
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Edit',
        Cell: (cell: any) => (
          <Popup
            closeOnDocumentClick={false}
            modal={true}
            overlayStyle={{
              backgroundColor: 'rgba(224, 224, 224,.8)',
              zIndex: 1000,
            }}
            trigger={<TextLink>Edit</TextLink>}
          >
            {(close: any) => (
              <ContactPersonsForm
                cancel={close}
                firstName={cell.row.original.firstName}
                lastName={cell.row.original.lastName}
                mode="edit"
                onSave={(form: ContactPersonForm) => {
                  onContactPersonEdit({ ...form, id: cell.row.original.id });
                  close();
                }}
              />
            )}
          </Popup>
        ),
      },

      {
        Header: 'Delete',
        Cell: (cell: any) => (
          <Popup
            closeOnDocumentClick={false}
            modal={true}
            overlayStyle={{
              backgroundColor: 'rgba(224, 224, 224,.8)',
              zIndex: 1000,
            }}
            trigger={<TextLink>Delete</TextLink>}
          >
            {(close: any) => (
              <DeleteForm
                onDelete={() => {
                  onContactPersonDelete({ ...cell.row.original });
                  close();
                }}
                onCancel={close}
                question={t('label.delete_confirmation')}
              />
            )}
          </Popup>
        ),
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <TableStyles>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className={styles['SectionTitle']}>
          {t('table.contact_person')}
        </span>
        <div style={{ flex: 1 }}>{}</div>

        <Popup
          closeOnDocumentClick={false}
          modal={true}
          overlayStyle={{
            backgroundColor: 'rgba(224, 224, 224,.8)',
            zIndex: 1000,
          }}
          trigger={
            <Button
              style={{ marginBottom: 0 }}
              variant="contained"
              sx={{
                marginLeft: 'auto',
                marginRight: '8px',
                borderRadius: '0',
              }}
              startIcon={<AddRounded />}
            >
              {t('button.new_contact_person')}
            </Button>
          }
        >
          {(close: any) => (
            <ContactPersonsForm
              firstName=""
              lastName=""
              mode="new"
              cancel={close}
              onSave={(form: ContactPersonForm) => {
                onContactPersonCreate({
                  ...form,
                  id: Math.max(...contactPersons.map((x) => x.id)) + 1,
                });
                close();
              }}
            />
          )}
        </Popup>
      </div>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableStyles>
  );
};
