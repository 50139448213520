import styled from 'styled-components';

export const BackgroundPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(238, 241, 244);
  height: 100%;
`;

export const DetailsPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(238, 241, 244);
  height: fit-content;
`;
