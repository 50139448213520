import { makeAutoObservable } from 'mobx';

export type AddressSnapshot = {
  id: number;
  country: string;
  city: string;
  post: string;
  street: string;
  postcode: string;
  voivodeship: string;
};

export class AddressStore {
  constructor(
    private id: number,
    private country: string,
    private city: string,
    private post: string,
    private street: string,
    private postcode: string,
    private voivodeship: string
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getCountry() {
    return this.country;
  }

  getCity() {
    return this.city;
  }

  getPost() {
    return this.post;
  }

  getStreet() {
    return this.street;
  }

  getPostcode() {
    return this.postcode;
  }

  getVoivodeship() {
    return this.voivodeship;
  }

  static fromSnapshot(snapshot: AddressSnapshot) {
    return new AddressStore(
      snapshot.id,
      snapshot.country,
      snapshot.city,
      snapshot.post,
      snapshot.street,
      snapshot.postcode,
      snapshot.voivodeship
    );
  }

  snapshot(): AddressSnapshot {
    return {
      id: this.id,
      country: this.country,
      city: this.city,
      post: this.post,
      street: this.street,
      postcode: this.postcode,
      voivodeship: this.voivodeship
    };
  }

}
