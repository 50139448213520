import React from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { schedulerPageStore } from '../../../store/SchedulerPageStore';
import { HorizontalCalendar } from '../../horizontalCalendar/HorizontalCalendar';
import { CalendarTextIcon, CheckAllIcon } from '../../icons/Icons';
import { SelectEmployee } from '../../select/SelectEmployee';
import styles from './SchedulerFilterBar.module.scss';

export const SchedulerFilterBar = observer(() => {
  const store = schedulerPageStore;

  return (
    <div className={styles['FiterBarContainer']}>
      <HorizontalCalendar
        selectedDate={store.getCurrentDate()}
        onChange={(date) => store.setCurrentPosition(date)}
      />
      <div style={{ minWidth: 16, flexGrow: 1 }} />
      <div className={styles['ButtonsContainer']}>
        <button
          name="showDriverLines"
          className={`${styles['IconButton']} ${
            store.getViewWithDriversLines()
              ? `${styles['IconButton--active']}`
              : ''
          }`}
          onClick={(e) =>
            store.setViewWithDriversLines(!store.getViewWithDriversLines())
          }
          title={t('button.show_driver_lines')}
          type="button"
        >
          <CalendarTextIcon active={store.getViewWithDriversLines()} />
        </button>
        <button
          name="showCompletedTransports"
          className={`${styles['IconButton']} ${
            store.getShowCompletedTransports()
              ? `${styles['IconButton--active']}`
              : ''
          }`}
          onClick={(e) =>
            store.setShowCompletedTransports(
              !store.getShowCompletedTransports()
            )
          }
          title={t('button.show_completed_transports')}
          type="button"
        >
          <CheckAllIcon active={store.getShowCompletedTransports()} />
        </button>
      </div>
      <SelectEmployee
        chosenDriver={store.getChosenDriver()}
        onChange={(driver) => store.setChosenDriver(driver)}
      />
    </div>
  );
});

SchedulerFilterBar.displayName = 'SchedulerFilterBar';
