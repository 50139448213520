/* eslint-disable no-shadow */
import React from 'react';
import { OutlinedInput } from '@mui/material';
import { t } from 'i18next';
import { TrailerStore } from '../../store/trailerStore/TrailerStore';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import { SelectTrailer } from '../select/SelectTrailer';
import styles from './Details.module.scss';

interface iTrailerDetailsProps {
  trailer: TrailerStore | null;
  onTrailerChange: (employee: TrailerStore | null) => void;
}

export const TrailerDetails = ({
  trailer,
  onTrailerChange,
}: iTrailerDetailsProps) => (
  <section id="Trailer Details" className={styles['SectionContainer']}>
    <span className={styles['SectionTitle']}>{t('input.trailer')}</span>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.trailer')}:</label>
      <SelectTrailer
        chosenTrailer={trailer}
        onChange={(trailer) => onTrailerChange(trailer)}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.license_number')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.license_number')}
        disabled
        value={trailer ? trailer.getLicenseNumber() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.color')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.color')}
        disabled
        value={trailer ? trailer.getColor() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.year')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.year')}
        disabled
        value={trailer ? trailer.getYear() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.service_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.service_expiry_date')}
        disabled
        value={
          trailer
            ? getFormattedDateWithNamedMonth(trailer.getServiceExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>
        {t('input.insurance_expiry_date')}:
      </label>
      <OutlinedInput
        size="small"
        placeholder={t('input.insurance_expiry_date')}
        disabled
        value={
          trailer
            ? getFormattedDateWithNamedMonth(trailer.getInsuranceExpiryDate())
            : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.type')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.type')}
        disabled
        value={trailer ? trailer.getType() : ''}
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
    <div className={styles['LabelWithInput']}>
      <label className={styles['Label']}>{t('input.capacity')}:</label>
      <OutlinedInput
        size="small"
        placeholder={t('input.capacity')}
        disabled
        value={
          trailer ? `${trailer.getCapacity()} ${trailer.getCapacityUnit()}` : ''
        }
        sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
      />
    </div>
  </section>
);
