import { makeAutoObservable } from 'mobx';
import { AddressStore } from './AddressStore';

export type ContactPersonSnapshot = {
  id: number;
  firstName: string;
  lastName: string;
};

export class ContactPerson {
  constructor(
    private id: number,
    private firstName: string,
    private lastName: string
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  static fromSnapshot(snapshot: ContactPersonSnapshot) {
    return new ContactPerson(
      snapshot.id,
      snapshot.firstName,
      snapshot.lastName
    );
  }

  snapshot(): ContactPersonSnapshot {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }
}
