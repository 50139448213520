/* eslint-disable no-shadow */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { DriverDetailsForm } from '../components/driver/DriverDetailsForm';
import { employeesStore } from '../store/employeeStore/EmployeesStore';
import { EmployeeStore } from '../store/employeeStore/EmployeeStore';
import { DrivingLicenceSnapshot } from '../store/employeeStore/DrivingLicenseStore';
import styles from './Dashboard.module.scss';
import { BackgroundPage, DetailsPage } from '../components/style/detailsPage';

export type EmployeeForm = {
  firstName: string;
  lastName: string;
  pesel: string;
  idCard: string;
  phoneNumber: string;
  drivingLicenses: DrivingLicenceSnapshot[];
  permissionsExpiryDate: string;
  tachographExpiryDate: string;
};

export type DriverLicenceForm = {
  category: string;
  expiryDate: string;
};

export const DriverDetailsPage = observer(() => {
  const { driverId } = useParams();
  const [mode, setMode] = useState<'new' | 'edit'>('new');
  const [loading, setLoading] = useState<boolean>(false);
  const [employee, setEmployee] = useState<EmployeeForm>({
    firstName: '',
    lastName: '',
    pesel: '',
    idCard: '',
    phoneNumber: '',
    drivingLicenses: [],
    permissionsExpiryDate: '',
    tachographExpiryDate: '',
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (driverId) {
      setMode('edit');
      getDriverInitialData();
    } else {
      setMode('new');
    }
  }, [driverId]);

  const getDriverInitialData = () => {
    const employee = employeesStore.getById(Number(driverId));
    if (employee) {
      const snapshot = employee.snapshot();
      setEmployee({ ...snapshot });
    }
  };

  const onSubmitClick = (form: EmployeeForm) => {
    setLoading(true);

    if (mode === 'new') {
      employeesStore.addEmployee(
        EmployeeStore.fromSnapshot({
          id: employeesStore.getNextId(),
          ...form,
        })
      );
      toast.success(t('toast.driver_created_successfully'));
    } else {
      employeesStore.updateEmployee(
        EmployeeStore.fromSnapshot({
          id: Number(driverId),
          ...form,
        })
      );
      toast.success(t('toast.driver_updated_successfully'));
    }
    setLoading(false);
    navigate(-1);
  };

  return (
    <BackgroundPage>
      <DetailsPage>
        <div className={styles['TransportTopSection']}>
          <span className={styles['TransportTopLabel']}>
            {mode === 'new' ? t('button.new_driver') : `Id: ${driverId}`}
          </span>
        </div>
        <div
          id="Transport Details"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <DriverDetailsForm
            {...employee}
            loading={loading}
            mode={mode}
            onSave={onSubmitClick}
          />
        </div>
      </DetailsPage>
    </BackgroundPage>
  );
});
