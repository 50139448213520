/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';

import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { AddRounded, SaveOutlined } from '@mui/icons-material';

import { useNavigate } from 'react-router';
import { AddressSnapshot } from '../../store/tradePartners/AddressStore';
import { ContactPersonSnapshot } from '../../store/tradePartners/ContactPerson';
import { TradePartnerForm } from '../../containers/TradePartnerDetailsPage';
import { TradePartnerBasicForm } from './TradePartnerBasicSection';
import { ContactPersonsTable } from './ContactPersonsTable';
import { Form, FormButtonContainer } from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  companyName: string;
  NIP: string;
  REGON: string;
  address: AddressSnapshot;
  mailDropAddress: AddressSnapshot;
  paymentDueDate: string;
  paymentMethod: string;
  currency: string;
  email: string;
  phoneNumber: string;
  contactPersons: ContactPersonSnapshot[];
  rate: string;
  onSave(form: TradePartnerForm): void;
  mode: 'new' | 'edit';
  loading: boolean;
}

export const TradePartnerDetailsForm: FunctionComponent<Props> = ({
  companyName,
  NIP,
  REGON,
  address,
  mailDropAddress,
  paymentDueDate,
  paymentMethod,
  currency,
  email,
  phoneNumber,
  contactPersons,
  rate,
  onSave,
  mode,
  loading,
}) => {
  const [_companyName, setCompanyName] = useState(companyName);
  const [_NIP, setNIP] = useState(NIP);
  const [_REGON, setREGON] = useState(REGON);
  const [_address, setAddress] = useState(address);
  const [_mailDropAddress, setMailDropAddress] = useState(mailDropAddress);
  const [_paymentDueDate, setPaymentDueDate] = useState(paymentDueDate);
  const [_paymentMethod, setPaymentMethod] = useState(paymentMethod);
  const [_currency, setCurrency] = useState(currency);
  const [_email, setEmail] = useState(email);
  const [_phoneNumber, setPhoneNumber] = useState(phoneNumber);
  const [_contactPersons, setContactPersons] = useState(contactPersons);
  const [_rate, setRate] = useState(rate);
  const navigate = useNavigate();

  const editContactPersonHandler = (contactPerson: ContactPersonSnapshot) => {
    setContactPersons((contacts) =>
      contacts.map((x) => {
        if (x.id === contactPerson.id) {
          return contactPerson;
        }
        return x;
      })
    );
  };
  const deleteContactPersonHandler = (contactPerson: ContactPersonSnapshot) => {
    setContactPersons((contacts) =>
      contacts.filter((x) => x.id !== contactPerson.id)
    );
  };
  const createContactPersonHandler = (contactPerson: ContactPersonSnapshot) => {
    setContactPersons((contacts) => [...contacts, contactPerson]);
  };

  useEffect(() => {
    refreshState();
  }, [
    companyName,
    NIP,
    REGON,
    address,
    mailDropAddress,
    paymentDueDate,
    paymentMethod,
    currency,
    email,
    phoneNumber,
    contactPersons,
    rate,
  ]);

  const refreshState = () => {
    setCompanyName(companyName);
    setNIP(NIP);
    setREGON(REGON);
    setAddress(address);
    setMailDropAddress(mailDropAddress);
    setPaymentDueDate(paymentDueDate);
    setPaymentMethod(paymentMethod);
    setCurrency(currency);
    setEmail(email);
    setPhoneNumber(phoneNumber);
    setContactPersons(contactPersons);
    setRate(rate);
  };

  return (
    <Form>
      <TradePartnerBasicForm
        companyName={_companyName}
        NIP={_NIP}
        REGON={_REGON}
        address={_address}
        mailDropAddress={_mailDropAddress}
        paymentDueDateChange={_paymentDueDate}
        paymentMethod={_paymentMethod}
        currency={_currency}
        email={_email}
        phoneNumber={_phoneNumber}
        rate={_rate}
        onCompanyNameChange={setCompanyName}
        onNIPChange={setNIP}
        onREGONChange={setREGON}
        onAddressChange={setAddress}
        onMailDropAddressChange={setMailDropAddress}
        onPaymentDueDateChange={setPaymentDueDate}
        onPaymentMethodChange={setPaymentMethod}
        onCurrencyChange={setCurrency}
        onEmailChange={setEmail}
        onPhoneNumberChange={setPhoneNumber}
        onRateChange={setRate}
      />
      <ContactPersonsTable
        onContactPersonEdit={editContactPersonHandler}
        onContactPersonDelete={deleteContactPersonHandler}
        onContactPersonCreate={createContactPersonHandler}
        contactPersons={_contactPersons}
      />
      <FormButtonContainer>
        <LoadingButton
          disabled={
            !allNotEmpty([
              _companyName,
              _NIP,
              _REGON,

              _address.country,
              _address.post,
              _address.postcode,
              _address.street,
              _address.voivodeship,

              _mailDropAddress.city,
              _mailDropAddress.country,
              _mailDropAddress.post,
              _mailDropAddress.postcode,
              _mailDropAddress.street,
              _mailDropAddress.voivodeship,
              _phoneNumber,
              _rate,
            ])
          }
          loading={loading}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '190px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              companyName: _companyName,
              NIP: _NIP,
              REGON: _REGON,
              address: _address,
              mailDropAddress: _mailDropAddress,
              paymentDueDate: _paymentDueDate,
              paymentMethod: _paymentMethod,
              currency: _currency,
              email: _email,
              phoneNumber: _phoneNumber,
              contactPersons: _contactPersons,
              rate: _rate,
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_trade_partner') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '190px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => navigate(-1)}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </Form>
  );
};
