import React from 'react';

export const Si4Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 297 210"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    style={{ width: 'auto', height: '60px' }}
  >
    <defs>
      <style type="text/css" />
    </defs>
    <g id="Warstwa_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        fill="#175386"
        d="M132.059 85.8132l-9.8855 0 0 -9.8855 19.771 0 0 69.1771 -9.8855 0 0 -59.2916zm-23.3768 -9.8856l0 9.8856 -24.7031 0c-2.4553,0 -4.6118,0.2775 -6.4693,0.854 -1.8575,0.5551 -3.3948,1.3024 -4.6332,2.2419 -1.217,0.9394 -2.1564,1.9856 -2.7756,3.16 -0.6192,1.1529 -0.9394,2.3699 -0.9394,3.6082 0,1.2597 0.3202,2.4767 0.9394,3.6297 0.6192,1.1742 1.5586,2.2205 2.7756,3.1599 1.2384,0.9394 2.7595,1.744 4.6332,2.2418 6.8787,1.8276 14.88,0.4276 22.5466,5.1457 1.6654,1.0248 3.1386,2.2632 4.3983,3.715 1.2811,1.4519 2.3059,3.16 3.0746,5.0602 0.7686,1.9216 1.1529,4.1207 1.1529,6.5761 0,2.4981 -0.5979,4.9535 -1.7721,7.3234 -1.1957,2.3699 -2.8611,4.5051 -5.0175,6.3839 -2.1351,1.8789 -4.74,3.3735 -7.7717,4.5051 -3.0532,1.1316 -6.4267,1.6867 -10.1418,1.6867l-24.703 0 0 -9.8855 24.703 0c2.4554,0 4.6119,-0.2776 6.4694,-0.854 1.8575,-0.5552 3.3948,-1.3024 4.6332,-2.2419 1.217,-0.9394 2.1564,-2.0069 2.7756,-3.2239 0.6191,-1.217 0.9394,-2.434 0.9394,-3.6938 0,-1.217 -0.3203,-2.3913 -0.9394,-3.5656 -0.6192,-1.1743 -1.5586,-2.1991 -2.7756,-3.1173 -1.2384,-0.918 -2.7757,-1.6653 -4.6332,-2.2204 -7.7623,-2.4093 -14.8518,-0.4743 -22.5466,-5.2096 -1.6654,-1.0036 -3.1386,-2.242 -4.3983,-3.7152 -1.2811,-1.4519 -2.306,-3.1599 -3.0746,-5.1028 -0.7686,-1.9217 -1.1529,-4.1208 -1.1529,-6.5975 0,-2.4553 0.5977,-4.868 1.7721,-7.238 1.1957,-2.3913 2.861,-4.5051 5.0175,-6.3625 2.135,-1.8362 4.7398,-3.3308 7.7717,-4.4624 3.0532,-1.1316 6.4267,-1.6868 10.1417,-1.6868l24.7031 0zm-52.1095 -18.7397l75.4863 0 0 8.8543 9.8855 0 0 -8.8543 2.3609 0c4.6256,0 8.4101,3.7844 8.4101,8.41l0 87.7327c0,4.6256 -3.7845,8.4101 -8.4101,8.4101l-87.7327 0c-4.6256,0 -8.4101,-3.7845 -8.4101,-8.4101l0 -87.7327c0,-4.6256 3.7845,-8.41 8.4101,-8.41z"
      />
      <path
        fill="#4A4F57"
        fillRule="nonzero"
        d="M235.794 131.759l13.7553 0 0 12.5904c0,1.2125 -0.3991,2.2384 -1.1667,3.0778 -0.7677,0.8705 -1.8423,1.2745 -3.224,1.2745l-9.3646 0 0 13.9352 -18.8829 0 0 -13.9352 -48.4816 0c-1.3816,0 -2.6406,-0.4353 -3.7459,-1.3057 -1.1052,-0.8704 -2.0962,-1.9873 -2.1185,-3.4196l-0.2319 -14.8385 52.7662 -72.4249 20.6946 0 0 75.046zm-18.8829 -37.4298c0,-1.7409 0.0306,-3.6683 0.1227,-5.7823 0.0921,-2.0829 0.2764,-4.259 0.5835,-6.4974l-34.4499 49.7095 33.7437 0 0 -37.4298z"
      />
    </g>
  </svg>
);
