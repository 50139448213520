/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { Column, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import Popup from 'reactjs-popup';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { TableStyles, TextLink } from '../style/table';
import { TruckSnapshot, TruckStore } from '../../store/trucksStore/TruckStore';
import { trucksStore } from '../../store/trucksStore/TrucksStore';
import { DeleteForm } from '../style/form';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';

type Props = {
  trucks: TruckStore[];
};

export const TrucksTable: FunctionComponent<Props> = observer(({ trucks }) => {
  const data = trucks.map((x) => ({
    id: x.getId(),
    brand: x.getBrand(),
    model: x.getModel(),
    licenseNumber: x.getLicenseNumber(),
    color: x.getColor(),
    fuel: x.getFuel(),
    year: x.getYear().toString(),
    vin: x.getVin(),
    serviceExpiryDate: x.getServiceExpiryDate().toString(),
    insuranceExpiryDate: x.getInsuranceExpiryDate().toString(),
    refObject: TruckStore.fromSnapshot(x.snapshot()),
  }));

  const columns = React.useMemo<Column<TruckSnapshot>[]>(
    () => [
      {
        Header: 'Brand',
        accessor: 'brand', // accessor is the "key" in the data
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'License number',
        accessor: 'licenseNumber',
      },
      {
        Header: 'Color',
        accessor: 'color',
      },
      {
        Header: 'Fuel',
        accessor: 'fuel',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Vin',
        accessor: 'vin',
      },
      {
        Header: 'Service Expiry Date',
        accessor: 'serviceExpiryDate',
        Cell: (cell: any) => (
          <div>
            {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
          </div>
        ),
      },
      {
        Header: 'Insurance Expiry Date',
        accessor: 'insuranceExpiryDate',
        Cell: (cell: any) => (
          <div>
            {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: (cell: any) => (
          <TextLink onClick={(e) => handleRowClick(cell)}>Edit</TextLink>
        ),
      },
      {
        Header: '  ',
        Cell: (cell: any) => (
          <Popup
            closeOnDocumentClick={false}
            modal={true}
            overlayStyle={{
              backgroundColor: 'rgba(224, 224, 224,.8)',
              zIndex: 10000,
            }}
            trigger={<TextLink>Delete</TextLink>}
          >
            {(close: any) => (
              <DeleteForm
                onDelete={() => {
                  trucksStore.deleteTruck(cell.row.original.id);
                  close();
                }}
                onCancel={close}
                question={t('label.delete_confirmation')}
              />
            )}
          </Popup>
        ),
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const navigate = useNavigate();

  const handleRowClick = (cell: any) => {
    navigate(`truckDetails/${cell.row.original.id}`);
  };

  return (
    <TableStyles>
      <h1>Trucks Settings</h1>
      <Button
        variant="contained"
        sx={{
          marginLeft: 'auto',
          marginRight: '8px',
          borderRadius: '0',
        }}
        onClick={() => navigate('createTruck')}
        startIcon={<AddRounded />}
      >
        {t('button.new_truck')}
      </Button>
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </TableStyles>
  );
});
