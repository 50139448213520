/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import Popover from '@mui/material/Popover';
import {
  getDatesBetween,
  getShortMonth,
  getShortWeekday,
} from '../../utils/DateUtils';
import { DatePicker } from '../DatePicker/DatePicker';
import { DotsHorizontalIcon, LeftArrow, RightArrow } from '../icons/Icons';
import styles from './HorizontalCalendar.module.scss';

interface iHorizontalCalendarProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
  includeNextDay?: boolean;
}

export const HorizontalCalendar = observer(
  ({
    selectedDate,
    onChange,
    includeNextDay = false,
  }: iHorizontalCalendarProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const firstDate: Date = dayjs().subtract(3, 'day').toDate();
    const lastDate: Date = dayjs().add(6, 'day').toDate();
    const days: Date[] = getDatesBetween(firstDate, lastDate);
    const nextDayAfterSelected = dayjs(selectedDate).add(1, 'day');

    const handleDotsClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleDatePickerClose = () => {
      setAnchorEl(null);
    };

    const onBackArrowClick = () => {
      const newDate = dayjs(selectedDate).subtract(1, 'day').toDate();
      onChange(newDate);
    };

    const onForwardArrowClick = () => {
      const newDate = dayjs(selectedDate).add(1, 'day').toDate();
      onChange(newDate);
    };

    const onDateChange = (date: Date) => {
      setAnchorEl(null);
      onChange(date);
    };

    const renderDots = () => (
      <div
        className={styles['Day']}
        onClick={(e) => handleDotsClick(e)}
        role="button"
      >
        <DotsHorizontalIcon />
      </div>
    );

    const renderDay = (day: Date) => {
      const getClassName = () => {
        const isToday = dayjs(day).isSame(dayjs(), 'day');
        const isSunday = dayjs(day).day() === 0;
        const isSelected = dayjs(day).isSame(dayjs(selectedDate), 'day');
        const isNextDayIncluded = includeNextDay;
        const isNextDayAfterSelected = dayjs(day).isSame(
          dayjs(nextDayAfterSelected),
          'day'
        );

        let className = styles['Day'];

        if (isToday) {
          className = `${className} ${styles['Day--today']}`;
        }

        if (isSunday) {
          className = `${className} ${styles['Day--sunday']}`;
        }

        if (isSelected || (isNextDayIncluded && isNextDayAfterSelected)) {
          className = `${className} ${styles['Day--selected']}`;
        }

        return className;
      };

      return (
        <div
          key={day.getTime()}
          onClick={() => onChange(day)}
          className={getClassName()}
          role="button"
        >
          <small>{getShortWeekday(dayjs(day).day())}</small>
          <strong>{day.getDate()}</strong>
          <small>{getShortMonth(dayjs(day).month())}</small>
        </div>
      );
    };

    const openDatePicker = Boolean(anchorEl);
    const datePickerId = openDatePicker ? 'datepicker-popover' : undefined;

    return (
      <>
        <Popover
          id={datePickerId}
          open={openDatePicker}
          anchorEl={anchorEl}
          onClose={() => handleDatePickerClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DatePicker
            value={selectedDate}
            onChange={(date) => onDateChange(date)}
          />
        </Popover>
        <div className={styles['CalendarContainer']}>
          <div
            className={styles['Day']}
            onClick={() => onBackArrowClick()}
            role="button"
          >
            <LeftArrow />
          </div>
          {dayjs(selectedDate).isBefore(firstDate, 'day') ? (
            <>
              {renderDay(selectedDate)}
              {!dayjs(selectedDate)
                .add(1, 'day')
                .isSame(dayjs(firstDate), 'day') && renderDots()}
            </>
          ) : (
            renderDots()
          )}
          {days.map(renderDay)}
          {dayjs(selectedDate).isAfter(lastDate, 'day') ? (
            <>
              {!dayjs(selectedDate)
                .subtract(1, 'day')
                .isSame(dayjs(lastDate), 'day') && renderDots()}
              {renderDay(selectedDate)}
            </>
          ) : (
            renderDots()
          )}
          <div
            className={styles['Day']}
            onClick={() => onForwardArrowClick()}
            role="button"
          >
            <RightArrow />
          </div>
        </div>
      </>
    );
  }
);

HorizontalCalendar.displayName = 'HorizontalCalendar';
