/* eslint-disable @typescript-eslint/ban-ts-comment */
import createTheme from '@mui/material/styles/createTheme';
import { themeType } from '../types/Types';

const darkPallete = () => ({
  components: {
    MuiButton: {
      styleOverrides: {
        // @ts-ignore
        contained: ({ ownerState, theme }) => ({
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...(ownerState.variant === 'contained' && {
            color: '#f7f7f7',
          }),
        }),
        root: {
          textTransform: 'none' as const,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#175386',
    },
  },
});

const lightPallete = () => ({
  components: {
    MuiButton: {
      styleOverrides: {
        // @ts-ignore
        contained: ({ ownerState, theme }) => ({
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...(ownerState.variant === 'contained' && {
            color: '#ffffff',
          }),
        }),
        root: {
          textTransform: 'none' as const,
        },
      },
    },
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       fontSize: '2em',
    //       color: 'yellow',
    //       backgroundColor: 'red',
    //     },
    //   },
    // },
  },
  palette: {
    primary: {
      main: '#175386',
    },
  },
});

const getPallete = (theme: themeType) =>
  theme === 'light' ? lightPallete() : darkPallete();

export const getMuiCustomTheme = (theme: themeType) =>
  createTheme(getPallete(theme));
