/* eslint-disable react/no-array-index-key */
import React from 'react';
import { PendingActionsOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { transportsStore } from '../../store/transportStore/TransportsStore';
import { TransportStore } from '../../store/transportStore/TransportStore';
import { transportStatusType } from '../../types/Types';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import {
  CheckboxMarkedCircleOutlineIcon,
  TruckDeliveryIcon,
} from '../icons/Icons';
import { PlaceAndTime } from '../placeAndTime/PlaceAndTime';
import styles from './Table.module.scss';

export const Table = observer(() => {
  const navigate = useNavigate();

  const getStatusIcon = (status: transportStatusType) => {
    switch (status) {
      case 'delayed':
        return <span className={styles['DelayedIcon']}>!</span>;
      case 'finished':
        return <CheckboxMarkedCircleOutlineIcon />;
      case 'inProgress':
        return <TruckDeliveryIcon />;
      case 'planned':
        return <PendingActionsOutlined sx={{ color: '#ffffff' }} />;
      default:
        throw new Error('Wrong status');
    }
  };

  return (
    <div className={styles['TableContainer']}>
      <table className={styles['Table']}>
        <thead>
          <tr>
            <th className={styles['ColumnId']}>{t('table.id')}</th>
            <th className={styles['ColumnStatus']}>{t('table.status')}</th>
            <th className={styles['ColumnPlaceAndTime']}>
              {t('table.transport_from')}
            </th>
            <th className={styles['ColumnPlaceAndTime']}>
              {t('table.transport_to')}
            </th>
            <th className={styles['ColumnDriver']}>{t('table.driver')}</th>
          </tr>
        </thead>
        <tbody>
          {transportsStore
            .getTrnasportsForTransportsPage()
            .map((item, index) => {
              const isTransport = item instanceof TransportStore;
              if (!isTransport) {
                if (item.header) {
                  return (
                    <tr
                      key={`${item.date}--${index}--header`}
                      className={styles['DateHeader']}
                    >
                      <th colSpan={4}>
                        {getFormattedDateWithNamedMonth(item.date)}
                      </th>
                      <th colSpan={1}>
                        <span>
                          {t('table.number_of_transports')} {item.count}
                        </span>
                      </th>
                    </tr>
                  );
                }
                return (
                  <tr
                    key={`${item.date}--${index}`}
                    className={styles['Empty']}
                  >
                    <td colSpan={5} />
                  </tr>
                );
              }
              return (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={`id-${item.getId()}_index-${index}`}
                  className={styles[`TableRow--${item.getStatus()}`]}
                  onClick={() => navigate(`transportDetails/${item.getId()}`)}
                >
                  <td className={styles['ColumnId']}>
                    <div style={{ display: 'flex' }}>
                      {item.getId()}&nbsp;
                      {item.getPriority() && (
                        <div className={styles['Priority']}>
                          <span>P</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className={styles[`ColumnStatus--${item.getStatus()}`]}>
                    {getStatusIcon(item.getStatus())}
                  </td>
                  <td className={styles['ColumnPlaceAndTime']}>
                    <PlaceAndTime transport={item} type="departure" />
                  </td>
                  <td className={styles['ColumnPlaceAndTime']}>
                    <PlaceAndTime transport={item} type="arrival" />
                  </td>
                  <td className={styles['ColumnDriver']}>
                    {item.getDriver().getFirstName()}&nbsp;
                    {item.getDriver().getLastName()}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
});

Table.displayName = 'Table';
