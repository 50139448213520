/* eslint-disable no-shadow */
import React from 'react';
import { ScaleTime } from 'd3-scale';
import { observer } from 'mobx-react-lite';
import { EmployeeStore } from '../../../store/employeeStore/EmployeeStore';
import { schedulerPageStore } from '../../../store/SchedulerPageStore';
import { TransportEntry } from '../transportEntry/TransportEntry';
import styles from './SchedulerLine.module.scss';

interface iSchedulerLineProps {
  timeScale: ScaleTime<number, number, never>;
  driver?: EmployeeStore;
}

export const SchedulerLine = observer(
  ({ timeScale, driver }: iSchedulerLineProps) => {
    const store = schedulerPageStore;
    const viewWithDriversLines = store.getViewWithDriversLines();

    function renderTransportsEntries(driver?: EmployeeStore) {
      const transports =
        driver !== undefined
          ? store
              .getTransports()
              .filter(
                (transport) => transport.getDriver().getId() === driver.getId()
              )
          : store.getTransports();
      return transports.map((transport) => (
        <TransportEntry
          key={transport.getId()}
          transport={transport}
          timeScale={timeScale}
        />
      ));
    }

    if (viewWithDriversLines && driver) {
      return (
        <div className={styles['SchedulerLine']}>
          <header
            className={styles['DriverNameLineGroup']}
          >{`${driver.getFirstName()}\n${driver.getLastName()}`}</header>
          {renderTransportsEntries(driver)}
        </div>
      );
    }
    return (
      <div className={styles['SchedulerOnlyOneLine']}>
        {renderTransportsEntries()}
      </div>
    );
  }
);

SchedulerLine.displayName = 'SchedulerLine';
