import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '../../theme/ThemeContext';
import { linkToType } from '../../types/Types';
import { Pages } from '../../utils/Pages';
import { DashboardIcon } from './svgsForNavigation/DashboardIcon';
import { LoadingsIcon } from './svgsForNavigation/LoadingsIcon';
import { SchedulerIcon } from './svgsForNavigation/SchedulerIcon';
import styles from './MainRootsNavigation.module.scss';

interface iMainRootsNavigationProps {
  type: 'mobile' | 'desktop';
}

export const MainRootsNavigation = ({ type }: iMainRootsNavigationProps) => {
  const { getTheme } = useTheme();

  const getSvg = (page: linkToType) => {
    switch (page) {
      case '/dashboard':
        return <DashboardIcon />;
      case '/transports':
        return <LoadingsIcon />;
      case '/scheduler':
        return <SchedulerIcon />;
      default:
        throw new Error('Unhadled option for this link');
    }
  };

  const links = Pages.PAGES.filter((page) => page.showInRootNavigation).map(
    (page, idx) => (
      <li key={page.to.toString() + idx.toString()} style={{ display: 'flex' }}>
        <NavLink
          to={page.to}
          className={({ isActive }) =>
            `${styles[`Link--${getTheme()}`]} ${styles['SvgColor']} ${
              isActive
                ? `${styles[`${`Link--${getTheme()}--active`}`]} ${
                    styles['SvgColor--active']
                  }`
                : ''
            }`
          }
        >
          {getSvg(page.to)}
          <span className={styles['Link__Label']}>{page.label}</span>
        </NavLink>
      </li>
    )
  );

  return (
    <ul
      className={
        type === 'desktop'
          ? styles['LinkList--desktop']
          : styles['LinkList--mobile']
      }
    >
      {links}
    </ul>
  );
};
