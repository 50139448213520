import React from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { transportsPageStore } from '../../store/TransportsPageStore';
import { HorizontalCalendar } from '../horizontalCalendar/HorizontalCalendar';
import { CalendarMultipleIcon, CheckAllIcon } from '../icons/Icons';
import { SelectEmployee } from '../select/SelectEmployee';
import styles from './TransportsFilterBar.module.scss';

export const TransportsFilterBar = observer(() => {
  const store = transportsPageStore;
  // eslint-disable-next-line no-undef
  const onIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    switch (e.currentTarget.name) {
      case 'showCompletedTransports':
        store.setShowCompletedTransports(!store.getShowCompletedTransports());
        break;
      case 'showNextDay':
        store.setIsNextDayIncluded(!store.getIsNextDayIncluded());
        break;
      default:
        throw new Error('Wrong element name');
    }
  };

  return (
    <div className={styles['FiterBarContainer']}>
      <HorizontalCalendar
        selectedDate={store.getCurrentDate()}
        includeNextDay={store.getIsNextDayIncluded()}
        onChange={(date) => store.setCurrentDate(date)}
      />
      <div style={{ minWidth: 16, flexGrow: 1 }} />
      <div className={styles['ButtonsContainer']}>
        <button
          name="showCompletedTransports"
          className={`${styles['IconButton']} ${
            store.getShowCompletedTransports()
              ? `${styles['IconButton--active']}`
              : ''
          }`}
          onClick={(e) => onIconClick(e)}
          title={t('button.show_completed_transports')}
          type="button"
        >
          <CheckAllIcon active={store.getShowCompletedTransports()} />
        </button>
        <button
          name="showNextDay"
          className={`${styles['IconButton']} ${
            store.getIsNextDayIncluded()
              ? `${styles['IconButton--active']}`
              : ''
          }`}
          onClick={(e) => onIconClick(e)}
          title={t('button.show_next_day')}
          type="button"
        >
          <CalendarMultipleIcon active={store.getIsNextDayIncluded()} />
        </button>
      </div>
      <SelectEmployee
        chosenDriver={store.getChosenDriver()}
        onChange={(driver) => store.setChosenDriver(driver)}
      />
    </div>
  );
});

TransportsFilterBar.displayName = 'TransportsFilterBar';
