import React, { useState } from 'react';
import { InputAdornment, OutlinedInput, Popover } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { TrailerStore } from '../../store/trailerStore/TrailerStore';
import { trailersStore } from '../../store/trailerStore/TrailersStore';
import styles from './Select.module.scss';

interface iSelectTrailerProps {
  chosenTrailer: TrailerStore | null;
  onChange: (driver: TrailerStore | null) => void;
}

export const SelectTrailer = observer(
  ({ chosenTrailer, onChange }: iSelectTrailerProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [searchString, setSearchString] = useState<string>('');

    const handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
      setAnchorEl(null);
      setSearchString('');
    };

    const onValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSearchString(event.target.value);
    };

    const getTrailers = (): TrailerStore[] => {
      const trailers = trailersStore.getTrailers().filter((trailer) => {
        const trailerDetails = `${trailer.getBrand().toLowerCase()} ${trailer
          .getLicenseNumber()
          .toLowerCase()}`;
        if (trailerDetails.includes(searchString.toLowerCase())) {
          return true;
        }
        return false;
      });
      return trailers;
    };

    const onListItemClick = (value: TrailerStore | null) => {
      setAnchorEl(null);
      setSearchString('');
      onChange(value);
    };

    const openSelect = Boolean(anchorEl);
    const selectId = openSelect ? 'select-popover' : undefined;

    return (
      <>
        <div
          className={styles['SelectContainer']}
          onClick={(e) => handleSelectClick(e)}
        >
          {chosenTrailer ? (
            <span>{chosenTrailer.getBrand()}&nbsp;</span>
          ) : (
            <span className={styles['Placeholder']}>{t('button.trailer')}</span>
          )}
        </div>
        <Popover
          id={selectId}
          open={openSelect}
          anchorEl={anchorEl}
          onClose={() => handleSelectClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ marginTop: '14px' }}
        >
          <div className={styles['PopOverContent']}>
            <div className={styles['SearchContent']}>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon sx={{ color: '#175386' }} />
                  </InputAdornment>
                }
                size="small"
                placeholder={t('input.search')}
                sx={{ margin: '4px' }}
                value={searchString}
                onChange={(e) => onValueChange(e)}
              />
              <div
                className={styles['ItemResult--clear']}
                onClick={() => onListItemClick(null)}
              >
                <span>{t('input.clear')}</span>
              </div>
              {getTrailers().map((trailer) => (
                <div
                  className={
                    trailer.getId() === chosenTrailer?.getId()
                      ? styles['ItemResult--active']
                      : styles['ItemResult']
                  }
                  key={trailer.getId()}
                  onClick={() => onListItemClick(trailer)}
                >
                  <span>
                    {trailer.getBrand()}&nbsp;
                    {trailer.getLicenseNumber()}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles['Arrow']} />
          </div>
        </Popover>
      </>
    );
  }
);

SelectTrailer.displayName = 'SelectTrailer';
