import React from 'react';
import { ScaleTime } from 'd3-scale';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { now } from 'mobx-utils';
import { getHHmm } from '../../../utils/DateUtils';
import styles from './CurrentTimeIndicator.module.scss';

interface iCurrentTimeIndicatorProps {
  timeScale: ScaleTime<number, number>;
}

export const CurrentTimeIndicator = observer(
  ({ timeScale }: iCurrentTimeIndicatorProps) => (
    <div
      className={styles['CurrentTimeIndicator']}
      style={{ left: timeScale(new Date(now())) }}
    >
      <label>{getHHmm(dayjs().toDate())}</label>
      <div className={styles['Line']} />
    </div>
  )
);
