import React from 'react';

export const LoadingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="36px"
    height="36px"
    version="1.1"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    textRendering="geometricPrecision"
    shapeRendering="geometricPrecision"
    viewBox="0 0 36 36"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Warstwa_x0020_loadings">
      <metadata id="loadings" />
      <path d="M1.49 0.5l33.02 0c0.55,0 0.99,0.44 0.99,0.99l0 33.02c0,0.55 -0.44,0.99 -0.99,0.99l-33.02 0c-0.55,0 -0.99,-0.44 -0.99,-0.99l0 -33.02c0,-0.55 0.44,-0.99 0.99,-0.99zm2.53 2.5l27.97 0c0.56,0 1.01,0.46 1.01,1.01l0 27.97c0,0.56 -0.45,1.02 -1.01,1.02l-27.97 0c-0.56,0 -1.02,-0.46 -1.02,-1.02l0 -27.97c0,-0.55 0.46,-1.01 1.02,-1.01zm10.93 3.5l6.1 0c0.3,0 0.54,0.25 0.54,0.54l0 6.16c0,0.3 -0.24,0.54 -0.54,0.54l-6.1 0c-0.29,0 -0.53,-0.24 -0.53,-0.54l0 -6.16c0,-0.29 0.24,-0.54 0.53,-0.54zm-3.87 7.92l6.1 0c0.29,0 0.53,0.24 0.53,0.54l0 6.16c0,0.29 -0.24,0.54 -0.53,0.54l-6.1 0c-0.29,0 -0.53,-0.25 -0.53,-0.54l0 -6.16c0,-0.3 0.24,-0.54 0.53,-0.54zm7.75 0l6.09 0c0.3,0 0.54,0.24 0.54,0.54l0 6.16c0,0.29 -0.24,0.54 -0.54,0.54l-6.09 0c-0.3,0 -0.54,-0.25 -0.54,-0.54l0 -6.16c0,-0.3 0.24,-0.54 0.54,-0.54zm3.87 7.84l6.09 0c0.3,0 0.54,0.24 0.54,0.53l0 6.17c0,0.29 -0.24,0.53 -0.54,0.53l-6.09 0c-0.3,0 -0.54,-0.24 -0.54,-0.53l0 -6.17c0,-0.29 0.24,-0.53 0.54,-0.53zm-15.49 0l6.1 0c0.29,0 0.53,0.24 0.53,0.53l0 6.17c0,0.29 -0.24,0.53 -0.53,0.53l-6.1 0c-0.3,0 -0.54,-0.24 -0.54,-0.53l0 -6.17c0,-0.29 0.24,-0.53 0.54,-0.53zm7.74 0l6.1 0c0.3,0 0.54,0.24 0.54,0.53l0 6.17c0,0.29 -0.24,0.53 -0.54,0.53l-6.1 0c-0.29,0 -0.53,-0.24 -0.53,-0.53l0 -6.17c0,-0.29 0.24,-0.53 0.53,-0.53z" />
    </g>
  </svg>
);
