import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { HashRouter as Router } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { ThemeProvider } from './theme/ThemeContext';
import { App } from './App';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider>
          <ToastContainer />
          <App />
        </ThemeProvider>
      </LocalizationProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
