import { observer } from 'mobx-react-lite';
import React from 'react';
import { TrailersTable } from '../components/trailer/TrailersTable';

import { trailersStore } from '../store/trailerStore/TrailersStore';

export const TrailersSettingsPage = observer(() => {
  const trailers = trailersStore.getTrailers();
  return <TrailersTable trailers={[...trailers]} />;
});
