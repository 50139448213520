import { makeAutoObservable } from 'mobx';

import {
  DrivingLicenceSnapshot,
  DrivingLicenceStore,
} from './DrivingLicenseStore';

export type EmployeeSnapshot = {
  id: number;
  firstName: string;
  lastName: string;
  pesel: string;
  idCard: string;
  phoneNumber: string;
  drivingLicenses: DrivingLicenceSnapshot[];
  permissionsExpiryDate: string;
  tachographExpiryDate: string;
};
export class EmployeeStore {
  constructor(
    private id: number,
    private firstName: string,
    private lastName: string,
    private pesel: string,
    private idCard: string,
    private phoneNumber: string,
    private drivingLicenses: DrivingLicenceStore[],
    private permissionsExpiryDate: Date,
    private tachographExpiryDate: Date
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getPesel() {
    return this.pesel;
  }

  getIdCard() {
    return this.idCard;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getDrivingLicense() {
    return this.drivingLicenses;
  }

  getPermissionsExpiryDate() {
    return this.permissionsExpiryDate;
  }

  getTachograpghExpiryDate() {
    return this.tachographExpiryDate;
  }

  updateLicence(licence: DrivingLicenceStore) {
    const index = this.drivingLicenses.findIndex(
      (x) => x.getId() === licence.getId()
    );
    if (index > -1) {
      this.drivingLicenses[index] = licence;
    }
  }

  static fromSnapshot(snapshot: EmployeeSnapshot) {
    return new EmployeeStore(
      snapshot.id,
      snapshot.firstName,
      snapshot.lastName,
      snapshot.pesel,
      snapshot.idCard,
      snapshot.phoneNumber,
      snapshot.drivingLicenses.map((x) => DrivingLicenceStore.fromSnapshot(x)),
      new Date(Date.parse(snapshot.permissionsExpiryDate)),
      new Date(Date.parse(snapshot.tachographExpiryDate))
    );
  }

  snapshot(): EmployeeSnapshot {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      pesel: this.pesel,
      idCard: this.idCard,
      phoneNumber: this.phoneNumber,
      drivingLicenses: this.drivingLicenses.map((x) => x.snapshot()),
      permissionsExpiryDate: this.permissionsExpiryDate.toUTCString(),
      tachographExpiryDate: this.tachographExpiryDate.toUTCString(),
    };
  }
}
