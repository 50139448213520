/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */

import { AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Column, useTable } from 'react-table';
import Popup from 'reactjs-popup';
import { employeesStore } from '../../store/employeeStore/EmployeesStore';
import {
  EmployeeSnapshot,
  EmployeeStore,
} from '../../store/employeeStore/EmployeeStore';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';
import { DeleteForm } from '../style/form';
import { TableStyles, TextLink } from '../style/table';

type Props = {
  drivers: EmployeeStore[];
};

export const DriversTable: FunctionComponent<Props> = observer(
  ({ drivers }) => {
    const navigate = useNavigate();

    const data = drivers.map((x) => x.snapshot());

    const columns = React.useMemo<Column<EmployeeSnapshot>[]>(
      () => [
        {
          Header: 'First Name',
          accessor: 'firstName', // accessor is the "key" in the data
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
        },
        {
          Header: 'PESEL',
          accessor: 'pesel',
        },
        {
          Header: 'Phone Number',
          accessor: 'phoneNumber',
        },
        {
          Header: 'Permissions Expiry Date',
          accessor: 'permissionsExpiryDate',
          Cell: (cell: any) => (
            <div>
              {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
            </div>
          ),
        },
        {
          Header: 'Tachograph Expiry Date',
          accessor: 'tachographExpiryDate',
          Cell: (cell: any) => (
            <div>
              {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
            </div>
          ),
        },
        {
          Header: 'Edit',
          Cell: (cell: any) => (
            <TextLink onClick={(e) => handleOnEdit(cell)}>Edit</TextLink>
          ),
        },
        {
          Header: 'Delete',
          Cell: (cell: any) => (
            <Popup
              closeOnDocumentClick={false}
              modal={true}
              overlayStyle={{
                backgroundColor: 'rgba(224, 224, 224,.8)',
                zIndex: 10000,
              }}
              trigger={<TextLink>Delete</TextLink>}
            >
              {(close: any) => (
                <DeleteForm
                  onDelete={() => {
                    employeesStore.deleteEmployee(cell.row.original.id);
                    close();
                  }}
                  onCancel={close}
                  question={t('label.delete_confirmation')}
                />
              )}
            </Popup>
          ),
        },
      ],
      []
    );
    const handleOnEdit = (cell: any) => {
      navigate(`driverDetails/${cell.row.original.id}`);
    };
    const tableInstance = useTable({
      columns,
      data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;
    return (
      <TableStyles>
        <h1>Driver Settings</h1>
        <Button
          variant="contained"
          sx={{
            marginLeft: 'auto',
            marginRight: '8px',
            borderRadius: '0',
          }}
          onClick={() => navigate('createDriver')}
          startIcon={<AddRounded />}
        >
          {t('button.new_driver')}
        </Button>
        <table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => (
                        // Apply the cell props
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </TableStyles>
    );
  }
);
