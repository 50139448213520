import { makeAutoObservable } from 'mobx';
import { EmployeeStore } from './employeeStore/EmployeeStore';

class TransportsPageStore {
  constructor(
    private currentDate: Date = new Date(),
    private isNextDayIncluded: boolean = false,
    private showCompletedTransports: boolean = false,
    private chosenDriver: EmployeeStore | null = null
  ) {
    makeAutoObservable(this);
  }

  getCurrentDate() {
    return this.currentDate;
  }

  getIsNextDayIncluded() {
    return this.isNextDayIncluded;
  }

  getShowCompletedTransports() {
    return this.showCompletedTransports;
  }

  getChosenDriver() {
    return this.chosenDriver;
  }

  setCurrentDate(currentDate: Date) {
    this.currentDate = currentDate;
  }

  setIsNextDayIncluded(isNextDayIncluded: boolean) {
    this.isNextDayIncluded = isNextDayIncluded;
  }

  setShowCompletedTransports(showCompletedTransports: boolean) {
    this.showCompletedTransports = showCompletedTransports;
  }

  setChosenDriver(chosenDriver: EmployeeStore | null) {
    this.chosenDriver = chosenDriver;
  }
}

export const transportsPageStore = new TransportsPageStore();
