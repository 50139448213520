import React, { useState } from 'react';
import { InputAdornment, OutlinedInput, Popover } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { employeesStore } from '../../store/employeeStore/EmployeesStore';
import { EmployeeStore } from '../../store/employeeStore/EmployeeStore';
import styles from './Select.module.scss';

interface iSelectEmployeeProps {
  chosenDriver: EmployeeStore | null;
  onChange: (driver: EmployeeStore | null) => void;
}

export const SelectEmployee = observer(
  ({ chosenDriver, onChange }: iSelectEmployeeProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [searchString, setSearchString] = useState<string>('');

    const handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
      setAnchorEl(null);
      setSearchString('');
    };

    const onValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSearchString(event.target.value);
    };

    const getEmployees = (): EmployeeStore[] => {
      const employees = employeesStore.getEmployees().filter((employee) => {
        const fullName = `${employee.getFirstName().toLowerCase()} ${employee
          .getLastName()
          .toLowerCase()}`;
        if (fullName.includes(searchString.toLowerCase())) {
          return true;
        }
        return false;
      });
      return employees;
    };

    const onListItemClick = (value: EmployeeStore | null) => {
      setAnchorEl(null);
      setSearchString('');
      onChange(value);
    };

    const openSelect = Boolean(anchorEl);
    const selectId = openSelect ? 'select-popover' : undefined;

    return (
      <>
        <div
          className={styles['SelectContainer']}
          onClick={(e) => handleSelectClick(e)}
        >
          {chosenDriver ? (
            <span>
              {chosenDriver.getFirstName()}&nbsp;{chosenDriver.getLastName()}
            </span>
          ) : (
            <span className={styles['Placeholder']}>{t('button.driver')}</span>
          )}
        </div>
        <Popover
          id={selectId}
          open={openSelect}
          anchorEl={anchorEl}
          onClose={() => handleSelectClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ marginTop: '14px' }}
        >
          <div className={styles['PopOverContent']}>
            <div className={styles['SearchContent']}>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon sx={{ color: '#175386' }} />
                  </InputAdornment>
                }
                size="small"
                placeholder={t('input.search')}
                sx={{ margin: '4px' }}
                value={searchString}
                onChange={(e) => onValueChange(e)}
              />
              <div
                className={styles['ItemResult--clear']}
                onClick={() => onListItemClick(null)}
              >
                <span>{t('input.clear')}</span>
              </div>
              {getEmployees().map((employee) => (
                <div
                  className={
                    employee.getId() === chosenDriver?.getId()
                      ? styles['ItemResult--active']
                      : styles['ItemResult']
                  }
                  key={employee.getId()}
                  onClick={() => onListItemClick(employee)}
                >
                  <span>
                    {employee.getFirstName()} {employee.getLastName()}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles['Arrow']} />
          </div>
        </Popover>
      </>
    );
  }
);

SelectEmployee.displayName = 'SelectEmployee';
