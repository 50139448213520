import { makeAutoObservable } from 'mobx';
import { transportStatusType } from '../../types/Types';
import {
  EmployeeSnapshot,
  EmployeeStore,
} from '../employeeStore/EmployeeStore';
import {
  TradePartnerSnapshot,
  TradePartnerStore,
} from '../tradePartners/TradePartnerStore';
import { TrailerSnapshot, TrailerStore } from '../trailerStore/TrailerStore';
import { TruckSnapshot, TruckStore } from '../trucksStore/TruckStore';

export type TransportSnapshot = {
  id: number;
  driver: EmployeeSnapshot;
  truck: TruckSnapshot;
  trailer: TrailerSnapshot;
  priority: boolean;
  departureDate: string;
  arrivalDate: string;
  sourceTradePartner: TradePartnerSnapshot;
  destinationTradePartner: TradePartnerSnapshot;
  status: transportStatusType;
};
export class TransportStore {
  constructor(
    private id: number,
    private driver: EmployeeStore,
    private truck: TruckStore,
    private trailer: TrailerStore,
    private priority: boolean,
    private departureDate: Date,
    private arrivalDate: Date,
    private sourceTradePartner: TradePartnerStore,
    private destinationTradePartner: TradePartnerStore,
    private status: transportStatusType
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getDriver() {
    return this.driver;
  }

  getTruck() {
    return this.truck;
  }

  getTrailer() {
    return this.trailer;
  }

  getPriority() {
    return this.priority;
  }

  getDepartureDate() {
    return this.departureDate;
  }

  getArrivalDate() {
    return this.arrivalDate;
  }

  getSourceTradePartner() {
    return this.sourceTradePartner;
  }

  getDestinationTradePartner() {
    return this.destinationTradePartner;
  }

  getStatus() {
    return this.status;
  }

  static fromSnapshot(snapshot: TransportSnapshot) {
    return new TransportStore(
      snapshot.id,
      EmployeeStore.fromSnapshot(snapshot.driver),
      TruckStore.fromSnapshot(snapshot.truck),
      TrailerStore.fromSnapshot(snapshot.trailer),
      snapshot.priority,
      new Date(Date.parse(snapshot.departureDate)),
      new Date(Date.parse(snapshot.arrivalDate)),
      TradePartnerStore.fromSnapshot(snapshot.sourceTradePartner),
      TradePartnerStore.fromSnapshot(snapshot.destinationTradePartner),
      snapshot.status
    );
  }

  snapshot(): TransportSnapshot {
    return {
      id: this.id,
      driver: this.driver.snapshot(),
      truck: this.truck.snapshot(),
      trailer: this.trailer.snapshot(),
      priority: this.priority,
      departureDate: this.departureDate.toUTCString(),
      arrivalDate: this.arrivalDate.toUTCString(),
      sourceTradePartner: this.sourceTradePartner.snapshot(),
      destinationTradePartner: this.destinationTradePartner.snapshot(),
      status: this.status,
    };
  }
}
