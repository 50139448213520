/* eslint-disable class-methods-use-this */
import {
  EmployeeSnapshot,
  EmployeeStore,
} from '../store/employeeStore/EmployeeStore';
import {
  TradePartnerSnapshot,
  TradePartnerStore,
} from '../store/tradePartners/TradePartnerStore';
import {
  TrailerSnapshot,
  TrailerStore,
} from '../store/trailerStore/TrailerStore';
import {
  TransportSnapshot,
  TransportStore,
} from '../store/transportStore/TransportStore';
import { TruckSnapshot, TruckStore } from '../store/trucksStore/TruckStore';
import { simulateRequest } from '../utils/SimulateRequest';
import {
  EmployeeService,
  TradePartnerService,
  TrailerService,
  TransportService,
  TruckService,
} from './Service';

export class InMemoryService
  implements
    EmployeeService,
    TruckService,
    TrailerService,
    TradePartnerService,
    TransportService
{
  getTransports = async (): Promise<TransportStore[]> => {
    await simulateRequest();
    return this.loadSaveTransports();
  };

  createTransport = async (transport: TransportStore) => {
    await simulateRequest();
    const saved = this.loadSaveTransports();
    saved.push(transport);
    this.saveTransports(saved);
  };

  updateTransport = async (transport: TransportStore) => {
    await simulateRequest();
    const saved = this.loadSaveTransports();
    const index = saved.findIndex((x) => x.getId() === transport.getId());
    if (index > -1) {
      saved[index] = transport;
    }
    this.saveTransports(saved);
  };

  deleteTransport = async (transportID: number) => {
    await simulateRequest();
    const saved = this.loadSaveTransports().filter(
      (x) => x.getId() !== transportID
    );
    this.saveTransports(saved);
  };

  deleteEmployee = async (employeeID: number) => {
    await simulateRequest();
    const saved = this.loadSaveEmployees().filter(
      (x) => x.getId() !== employeeID
    );
    this.saveEmployees(saved);
  };

  updateEmployee = async (employee: EmployeeStore) => {
    await simulateRequest();
    const saved = this.loadSaveEmployees();
    const index = saved.findIndex((x) => x.getId() === employee.getId());
    if (index > -1) {
      saved[index] = employee;
    }
    this.saveEmployees(saved);
  };

  creatEmployee = async (employee: EmployeeStore) => {
    await simulateRequest();
    const saved = this.loadSaveEmployees();
    saved.push(employee);
    this.saveEmployees(saved);
  };

  getEmployees = async (): Promise<EmployeeStore[]> => {
    await simulateRequest();
    return this.loadSaveEmployees();
  };

  updateTruck = async (truck: TruckStore) => {
    await simulateRequest();
    const saved = this.loadSaveTrucks();
    const index = saved.findIndex((x) => x.getId() === truck.getId());
    if (index > -1) {
      saved[index] = truck;
    }
    this.saveTrucks(saved);
  };

  deleteTruck = async (truckID: number) => {
    await simulateRequest();
    const saved = this.loadSaveTrucks().filter((x) => x.getId() !== truckID);
    this.saveTrucks(saved);
  };

  createTruck = async (truck: TruckStore) => {
    await simulateRequest();
    const saved = this.loadSaveTrucks();
    saved.push(truck);
    this.saveTrucks(saved);
  };

  getTrucks = async (): Promise<TruckStore[]> => {
    await simulateRequest();
    const saved = this.loadFromMemory('trucks');
    return saved
      ? (JSON.parse(saved) as TruckSnapshot[]).map((x) =>
          TruckStore.fromSnapshot(x)
        )
      : [];
  };

  updateTrailer = async (trailer: TrailerStore) => {
    await simulateRequest();
    const saved = this.loadSaveTrailers();
    const index = saved.findIndex((x) => x.getId() === trailer.getId());
    if (index > -1) {
      saved[index] = trailer;
    }
    this.saveTrailers(saved);
  };

  deleteTrailer = async (trailerID: number) => {
    await simulateRequest();
    const saved = this.loadSaveTrailers().filter(
      (x) => x.getId() !== trailerID
    );
    this.saveTrailers(saved);
  };

  createTrailer = async (trailer: TrailerStore) => {
    await simulateRequest();
    const saved = this.loadSaveTrailers();
    saved.push(trailer);
    this.saveTrailers(saved);
  };

  getTrailers = async (): Promise<TrailerStore[]> => {
    await simulateRequest();
    const saved = this.loadFromMemory('trailers');
    return saved
      ? (JSON.parse(saved) as TrailerSnapshot[]).map((x) =>
          TrailerStore.fromSnapshot(x)
        )
      : [];
  };

  deleteTradePartner = async (tradePartnerID: number) => {
    await simulateRequest();
    const saved = this.loadSaveTradePartners().filter(
      (x) => x.getId() !== tradePartnerID
    );
    this.saveTradePartners(saved);
  };

  updateTradePartner = async (tradePartner: TradePartnerStore) => {
    await simulateRequest();
    const saved = this.loadSaveTradePartners();
    const index = saved.findIndex((x) => x.getId() === tradePartner.getId());
    if (index > -1) {
      saved[index] = tradePartner;
    }
    this.saveTradePartners(saved);
  };

  createTradePartner = async (tradePartner: TradePartnerStore) => {
    await simulateRequest();
    const saved = this.loadSaveTradePartners();
    saved.push(tradePartner);
    this.saveTradePartners(saved);
  };

  getTradePartners = async (): Promise<TradePartnerStore[]> => {
    await simulateRequest();
    const saved = this.loadFromMemory('tradePartners');
    return saved
      ? (JSON.parse(saved) as TradePartnerSnapshot[]).map((x) =>
          TradePartnerStore.fromSnapshot(x)
        )
      : [];
  };

  private loadFromMemory(key: string) {
    return localStorage.getItem(key);
  }

  private save(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  private loadSaveEmployees(): EmployeeStore[] {
    const saved = this.loadFromMemory('employees');
    return saved
      ? (JSON.parse(saved) as EmployeeSnapshot[]).map((x) =>
          EmployeeStore.fromSnapshot(x)
        )
      : [];
  }

  private loadSaveTransports(): TransportStore[] {
    const saved = this.loadFromMemory('transports');
    return saved
      ? (JSON.parse(saved) as TransportSnapshot[]).map((x) =>
          TransportStore.fromSnapshot(x)
        )
      : [];
  }

  private loadSaveTrucks(): TruckStore[] {
    const saved = this.loadFromMemory('trucks');
    return saved
      ? (JSON.parse(saved) as TruckSnapshot[]).map((x) =>
          TruckStore.fromSnapshot(x)
        )
      : [];
  }

  private loadSaveTrailers(): TrailerStore[] {
    const saved = this.loadFromMemory('trailers');
    return saved
      ? (JSON.parse(saved) as TrailerSnapshot[]).map((x) =>
          TrailerStore.fromSnapshot(x)
        )
      : [];
  }

  private loadSaveTradePartners(): TradePartnerStore[] {
    const saved = this.loadFromMemory('tradePartners');
    return saved
      ? (JSON.parse(saved) as TradePartnerSnapshot[]).map((x) =>
          TradePartnerStore.fromSnapshot(x)
        )
      : [];
  }

  saveTransports(transports: TransportStore[]) {
    this.save('transports', transports);
  }

  saveEmployees(employees: EmployeeStore[]) {
    this.save('employees', employees);
  }

  saveTrucks(trucks: TruckStore[]) {
    this.save('trucks', trucks);
  }

  saveTrailers(trailers: TrailerStore[]) {
    this.save('trailers', trailers);
  }

  saveTradePartners(tradePartners: TradePartnerStore[]) {
    this.save('tradePartners', tradePartners);
  }
}
