import React, { useState } from 'react';
import { InputAdornment, OutlinedInput, Popover } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { TradePartnerStore } from '../../store/tradePartners/TradePartnerStore';
import { tradePartnersStore } from '../../store/tradePartners/TradePartnersStore';
import styles from './Select.module.scss';

interface iSelectTradePartnerProps {
  chosenTradePartner: TradePartnerStore | null;
  onChange: (driver: TradePartnerStore | null) => void;
}

export const SelectTradePartner = observer(
  ({ chosenTradePartner, onChange }: iSelectTradePartnerProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [searchString, setSearchString] = useState<string>('');

    const handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
      setAnchorEl(null);
      setSearchString('');
    };

    const onValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSearchString(event.target.value);
    };

    const getTradePartners = (): TradePartnerStore[] => {
      const tradePartners = tradePartnersStore
        .getTradePartners()
        .filter((tradePartner) => {
          const tradePartnerDetails = tradePartner
            .getCompanyName()
            .toLowerCase();
          if (tradePartnerDetails.includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        });
      return tradePartners;
    };

    const onListItemClick = (value: TradePartnerStore | null) => {
      setAnchorEl(null);
      setSearchString('');
      onChange(value);
    };

    const openSelect = Boolean(anchorEl);
    const selectId = openSelect ? 'select-popover' : undefined;

    return (
      <>
        <div
          className={styles['SelectContainer']}
          onClick={(e) => handleSelectClick(e)}
        >
          {chosenTradePartner ? (
            <span>{chosenTradePartner.getCompanyName()}</span>
          ) : (
            <span className={styles['Placeholder']}>
              {t('button.trade_partner')}
            </span>
          )}
        </div>
        <Popover
          id={selectId}
          open={openSelect}
          anchorEl={anchorEl}
          onClose={() => handleSelectClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ marginTop: '14px' }}
        >
          <div className={styles['PopOverContent']}>
            <div className={styles['SearchContent']}>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon sx={{ color: '#175386' }} />
                  </InputAdornment>
                }
                size="small"
                placeholder={t('input.search')}
                sx={{ margin: '4px' }}
                value={searchString}
                onChange={(e) => onValueChange(e)}
              />
              <div
                className={styles['ItemResult--clear']}
                onClick={() => onListItemClick(null)}
              >
                <span>{t('input.clear')}</span>
              </div>
              {getTradePartners().map((tradePartner) => (
                <div
                  className={
                    tradePartner.getId() === chosenTradePartner?.getId()
                      ? styles['ItemResult--active']
                      : styles['ItemResult']
                  }
                  key={tradePartner.getId()}
                  onClick={() => onListItemClick(tradePartner)}
                >
                  <span>{tradePartner.getCompanyName()}</span>
                </div>
              ))}
            </div>
            <div className={styles['Arrow']} />
          </div>
        </Popover>
      </>
    );
  }
);

SelectTradePartner.displayName = 'SelectTradePartner';
