import React from 'react';
import { Table } from '../components/table/Table';
import { TransportsFilterBar } from '../components/transportsFilterBar/TransportsFilterBar';

export const TransportsPage = () => (
  <div style={{ display: 'flex', flexDirection: 'column', width: ' 100%' }}>
    <TransportsFilterBar />
    <Table />
  </div>
);
