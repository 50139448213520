import React from 'react';
import { observer } from 'mobx-react-lite';
import { Truck } from '../components/truck/Truck';
import { transportsStore } from '../store/transportStore/TransportsStore';
import styles from './Dashboard.module.scss';

export const DashboardPage = observer(() => {
  const store = transportsStore;

  return (
    <div className={styles['TrucksContainer']}>
      {store.getTransports().map((transport) => (
        <Truck transport={transport} key={transport.getId()} />
      ))}
    </div>
  );
});

DashboardPage.displayName = 'DashboardPage';
