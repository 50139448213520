import React from 'react';
import styles from './SidebarToggleButton.module.scss';

interface iSidebarToggleButton {
  isSidebarVisible: boolean;
  setIsSidebarVisible: () => void;
}

export const SidebarToggleButton = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: iSidebarToggleButton) => (
  <button
    className={`${styles.hamburger} ${styles['hamburger--squeeze']} ${
      isSidebarVisible ? styles['is-active'] : ''
    }`}
    title="Toggle Menu"
    type="button"
    onClick={() => setIsSidebarVisible()}
  >
    <span className={styles['hamburger-box']}>
      <span className={styles['hamburger-inner']} />
    </span>
  </button>
);
