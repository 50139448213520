import React, { useState } from 'react';
import { InputAdornment, OutlinedInput, Popover } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { TruckStore } from '../../store/trucksStore/TruckStore';
import { trucksStore } from '../../store/trucksStore/TrucksStore';
import styles from './Select.module.scss';

interface iSelectTruckProps {
  chosenTruck: TruckStore | null;
  onChange: (driver: TruckStore | null) => void;
}

export const SelectTruck = observer(
  ({ chosenTruck, onChange }: iSelectTruckProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [searchString, setSearchString] = useState<string>('');

    // eslint-disable-next-line no-undef
    const handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
      setAnchorEl(null);
      setSearchString('');
    };

    const onValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSearchString(event.target.value);
    };

    const getTrucks = (): TruckStore[] => {
      const trucks = trucksStore.getTrucks().filter((truck) => {
        const truckDetails = `${truck.getBrand().toLowerCase()} ${truck
          .getModel()
          .toLowerCase()} ${truck.getLicenseNumber().toLowerCase()}`;
        if (truckDetails.includes(searchString.toLowerCase())) {
          return true;
        }
        return false;
      });
      return trucks;
    };

    const onListItemClick = (value: TruckStore | null) => {
      setAnchorEl(null);
      setSearchString('');
      onChange(value);
    };

    const openSelect = Boolean(anchorEl);
    const selectId = openSelect ? 'select-popover' : undefined;

    return (
      <>
        <div
          className={styles['SelectContainer']}
          onClick={(e) => handleSelectClick(e)}
        >
          {chosenTruck ? (
            <span>
              {chosenTruck.getBrand()}&nbsp;
              {chosenTruck.getModel()}&nbsp;
            </span>
          ) : (
            <span className={styles['Placeholder']}>{t('button.truck')}</span>
          )}
        </div>
        <Popover
          id={selectId}
          open={openSelect}
          anchorEl={anchorEl}
          onClose={() => handleSelectClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ marginTop: '14px' }}
        >
          <div className={styles['PopOverContent']}>
            <div className={styles['SearchContent']}>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon sx={{ color: '#175386' }} />
                  </InputAdornment>
                }
                size="small"
                placeholder={t('input.search')}
                sx={{ margin: '4px' }}
                value={searchString}
                onChange={(e) => onValueChange(e)}
              />
              <div
                className={styles['ItemResult--clear']}
                onClick={() => onListItemClick(null)}
              >
                <span>{t('input.clear')}</span>
              </div>
              {getTrucks().map((truck) => (
                <div
                  className={
                    truck.getId() === chosenTruck?.getId()
                      ? styles['ItemResult--active']
                      : styles['ItemResult']
                  }
                  key={truck.getId()}
                  onClick={() => onListItemClick(truck)}
                >
                  <span>
                    {truck.getBrand()}&nbsp;
                    {truck.getModel()}&nbsp;
                    {truck.getLicenseNumber()}
                  </span>
                </div>
              ))}
            </div>
            <div className={styles['Arrow']} />
          </div>
        </Popover>
      </>
    );
  }
);

SelectTruck.displayName = 'SelectTruck';
