/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { Column, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { t } from 'i18next';
import Popup from 'reactjs-popup';
import {
  TradePartnerSnapshot,
  TradePartnerStore,
} from '../../store/tradePartners/TradePartnerStore';
import { tradePartnersStore } from '../../store/tradePartners/TradePartnersStore';
import { TableStyles, TextLink } from '../style/table';
import { DeleteForm } from '../style/form';
import { getFormattedDateWithNamedDayAndMonth } from '../../utils/DateUtils';

type Props = {
  tradePartners: TradePartnerStore[];
};

export const TradePartnersTable: FunctionComponent<Props> = observer(
  ({ tradePartners }) => {
    const data = tradePartners.map((x) => ({
      id: x.getId(),
      companyName: x.getCompanyName(),
      NIP: x.getNIP(),
      REGON: x.getREGON(),
      address: x.getAddress().snapshot(),
      mailDropAddress: x.getMailDropAddress().snapshot(),
      paymentDueDate: x.getPaymentDueDate().toString(),
      paymentMethod: x.getPaymentMethod(),
      currency: x.getCurrency(),
      email: x.getEmail(),
      phoneNumber: x.getPhoneNumber(),
      contactPersons: x.getContactPersons().map((y) => y.snapshot()),
      rate: x.getRate().toString(),
      refObject: TradePartnerStore.fromSnapshot(x.snapshot()),
    }));

    const columns = React.useMemo<Column<TradePartnerSnapshot>[]>(
      () => [
        {
          Header: 'Company name',
          accessor: 'companyName', // accessor is the "key" in the data
        },
        {
          Header: 'NIP',
          accessor: 'NIP',
        },
        {
          Header: 'REGON',
          accessor: 'REGON',
        },
        {
          Header: 'Payment Due Date',
          accessor: 'paymentDueDate',
          Cell: (cell: any) => (
            <div>
              {getFormattedDateWithNamedDayAndMonth(new Date(cell.value))}
            </div>
          ),
        },
        {
          Header: 'Payment Method',
          accessor: 'paymentMethod',
        },
        {
          Header: 'Currency',
          accessor: 'currency',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Phone number',
          accessor: 'phoneNumber',
        },
        {
          Header: 'Rate',
          accessor: 'rate',
        },
        {
          Header: ' ',
          Cell: (cell: any) => (
            <TextLink onClick={(e) => handleOnEdit(cell)}>Edit</TextLink>
          ),
        },
        {
          Header: '  ',
          Cell: (cell: any) => (
            <Popup
              closeOnDocumentClick={false}
              modal={true}
              overlayStyle={{
                backgroundColor: 'rgba(224, 224, 224,.8)',
                zIndex: 1000,
              }}
              trigger={<TextLink>Delete</TextLink>}
            >
              {(close: any) => (
                <DeleteForm
                  onDelete={() => {
                    tradePartnersStore.deleteTradePartner(cell.row.original.id);
                    close();
                  }}
                  onCancel={close}
                  question={t('label.delete_confirmation')}
                />
              )}
            </Popup>
          ),
        },
      ],
      []
    );

    const handleOnEdit = (cell: any) => {
      navigate(`tradePartnerDetails/${cell.row.original.id}`);
    };

    const tableInstance = useTable({
      columns,
      data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;

    const navigate = useNavigate();

    return (
      <TableStyles>
        <h1>Trade Partners Settings</h1>
        <Button
          variant="contained"
          sx={{
            marginLeft: 'auto',
            marginRight: '8px',
            borderRadius: '0',
          }}
          onClick={() => navigate('createTradePartner')}
          startIcon={<AddRounded />}
        >
          {t('button.new_trade_partner')}
        </Button>
        <table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => (
                        // Apply the cell props
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </TableStyles>
    );
  }
);
