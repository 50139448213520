import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Drawer } from '@mui/material';
import { MainRootsNavigation } from '../mainRootsNavigation/MainRootsNavigation';
import { UserInfo } from '../userInfo/UserInfo';
import styles from './NavigationSidebar.module.scss';

interface iNavigationSidebarProps {
  isExpanded: boolean;
  close: () => void;
}

export const NavigationSidebar = ({
  isExpanded,
  close,
}: iNavigationSidebarProps) => (
  <Drawer
    anchor="right"
    open={isExpanded}
    onClose={() => close()}
    sx={{ minWidth: '350px' }}
  >
    <div className={styles['switchAndUserinfoContainer']}>
      {/* <ThemeSwitcher /> */}
      <UserInfo />
      <CloseRoundedIcon
        fontSize="large"
        onClick={() => close()}
        sx={{ cursor: 'pointer' }}
      />
    </div>
    <MainRootsNavigation type="mobile" />
  </Drawer>
);
