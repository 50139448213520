/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { OutlinedInput, TextField } from '@mui/material';
import { t } from 'i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import styles from './Truck.module.scss';
import { TruckForm } from '../../containers/TruckDetailsPage';
import { FormButtonContainer, FormOutlinedInput, FromTextInput } from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  brand: string;
  model: string;
  licenseNumber: string;
  color: string;
  fuel: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
  onSave(form: TruckForm): void;
  mode: 'new' | 'edit';
  loading: boolean;
}

export const TruckDetailsForm: FunctionComponent<Props> = ({
  brand,
  model,
  licenseNumber,
  color,
  fuel,
  year,
  vin,
  serviceExpiryDate,
  insuranceExpiryDate,
  onSave,
  mode,
  loading,
}) => {
  const [_brand, setBrand] = useState(brand);
  const [_model, setModel] = useState(model);
  const [_licenseNumber, setLicenseNumber] = useState(licenseNumber);
  const [_color, setColor] = useState(color);
  const [_fuel, setFuel] = useState(fuel);
  const [_year, setYear] = useState(year);
  const [_vin, setVin] = useState(vin);
  const [_serviceExpiryDate, setServiceExpiryDate] =
    useState(serviceExpiryDate);
  const [_insuranceExpiryDate, setInsuranceExpiryDate] =
    useState(insuranceExpiryDate);

  useEffect(() => {
    refreshState();
  }, [
    brand,
    model,
    licenseNumber,
    color,
    fuel,
    year,
    vin,
    serviceExpiryDate,
    insuranceExpiryDate,
  ]);
  const refreshState = () => {
    setBrand(brand);
    setModel(model);
    setLicenseNumber(licenseNumber);
    setColor(color);
    setFuel(fuel);
    setYear(year);
    setVin(vin);
    setServiceExpiryDate(serviceExpiryDate);
    setInsuranceExpiryDate(insuranceExpiryDate);
  };
  const navigate = useNavigate();
  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);

  return (
    <form style={{ width: '40%' }}>
      <span className={styles['SectionTitle']}>{t('input.truck')}</span>
      <section id="Driver Details" className={styles['SectionContainer']}>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.brand')}:</label>
          <FormOutlinedInput
            size="small"
            error={_brand === ''}
            onChange={handleInputValue(setBrand)}
            placeholder={t('input.brand')}
            value={_brand}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.model')}:</label>
          <FormOutlinedInput
            size="small"
            error={_model === ''}
            onChange={handleInputValue(setModel)}
            placeholder={t('input.model')}
            value={_model}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.license_number')}:
          </label>
          <FormOutlinedInput
            size="small"
            type="small"
            error={_licenseNumber === ''}
            onChange={handleInputValue(setLicenseNumber)}
            placeholder={t('input.license_number')}
            value={_licenseNumber}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.color')}:</label>
          <FormOutlinedInput
            size="small"
            type="small"
            error={_color === ''}
            onChange={handleInputValue(setColor)}
            placeholder={t('input.color')}
            value={_color}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.fuel')}:</label>
          <FormOutlinedInput
            size="small"
            type="small"
            error={_fuel === ''}
            onChange={handleInputValue(setFuel)}
            placeholder={t('input.fuel')}
            value={_fuel}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.year')}:</label>
          <FormOutlinedInput
            size="small"
            type="number"
            inputProps={{ min: 0 }}
            error={_year === ''}
            onChange={handleInputValue(setYear)}
            placeholder={t('input.year')}
            value={_year}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.vin')}:</label>
          <FormOutlinedInput
            size="small"
            type="snall"
            error={_vin === ''}
            onChange={handleInputValue(setVin)}
            placeholder={t('input.vin')}
            value={_vin}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.service_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && setServiceExpiryDate(e)}
            value={
              _serviceExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(_serviceExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.service_expiry_date')}
              />
            )}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.insurance_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && setInsuranceExpiryDate(e)}
            value={
              _insuranceExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(_insuranceExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.insurance_expiry_date')}
              />
            )}
          />
        </div>
      </section>
      <FormButtonContainer>
        <LoadingButton
          disabled={
            !allNotEmpty([
              _brand,
              _model,
              _licenseNumber,
              _color,
              _fuel,
              _year,
              _vin,
              _serviceExpiryDate,
              _insuranceExpiryDate,
            ])
          }
          loading={loading}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              brand: _brand,
              model: _model,
              licenseNumber: _licenseNumber,
              color: _color,
              fuel: _fuel,
              year: _year,
              vin: _vin,
              serviceExpiryDate: _serviceExpiryDate,
              insuranceExpiryDate: _insuranceExpiryDate,
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_truck') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => navigate(-1)}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </form>
  );
};
