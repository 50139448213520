import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        weekday_x_short: {
          sunday: 'Su',
          monday: 'Mo',
          tuesday: 'Tu',
          wednesday: 'We',
          thursday: 'Th',
          friday: 'Fr',
          saturday: 'Sa',
        },
        weekday_short: {
          sunday: 'Sun',
          monday: 'Mon',
          tuesday: 'Tue',
          wednesday: 'Wed',
          thursday: 'Tue',
          friday: 'Fri',
          saturday: 'Sat',
        },
        weekday: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
        },
        month_short: {
          january: 'Jan',
          february: 'Feb',
          march: 'Mar',
          april: 'Apr',
          may: 'May',
          june: 'Jun',
          july: 'Jul',
          august: 'Aug',
          september: 'Sep',
          october: 'Oct',
          november: 'Nov',
          december: 'Dec',
        },
        month: {
          january: 'January',
          february: 'February',
          march: 'March',
          april: 'April',
          may: 'May',
          june: 'June',
          july: 'July',
          august: 'August',
          september: 'September',
          october: 'October',
          november: 'November',
          december: 'December',
        },
        button: {
          show_delayed_transports: 'Show/Hide delayed transports',
          show_completed_transports: 'Show/Hide completed transports',
          show_next_day: 'Show/Hide next day',
          show_driver_lines: 'Show/Hide groups by drivers',
          driver: 'Driver',
          truck: 'Truck',
          trailer: 'Trailer',
          trade_partner: 'Trade Partner',
          new_transport: 'New Transport',
          save: 'Save',
          new_driver: 'New Driver',
          new_Licence: 'New Licence',
          new_contact_person: 'New Contact Person',
          new_truck: 'New Truck',
          new_trailer: 'New Trailer',
          new_trade_partner: 'New Trade Partner',
          cancel: 'Cancel',
          delete: 'Delete',
        },
        input: {
          search: 'Search...',
          clear: 'Clear',
          driver: 'Driver',
          licence: 'Licence',
          truck: 'Truck',
          trailer: 'Trailer',
          trade_partner: 'Trade Partner',
          phone_number: 'Phone number',
          driving_licenses: 'Driving Licenses',
          category: 'Category',
          expiry_date: 'Expiry Date',
          permissions_expiry_date: 'Permissions Expiry Date',
          tachograph_expiry_date: 'Tachograph Expiry Date',
          license_number: 'License Number',
          color: 'Color',
          fuel: 'Fuel',
          year: 'Year',
          service_expiry_date: 'Service Expiry Date',
          insurance_expiry_date: 'Insurance Expiry Date',
          type: 'Type',
          capacity: 'Capacity',
          capacity_unit: 'Capacity Unit',
          unit: 'Unit',
          company_name: 'Company Name',
          nip: 'NIP',
          regon: 'Regon',
          email: 'Email',
          contact_persons: 'Contact Persons',
          person: 'Person',
          priority: 'Priority',
          date: 'Date',
          time: 'Time',
          firstname: 'Firstname',
          lastname: 'Lastname',
          brand: 'Brand',
          model: 'Model',
          vin: 'VIN',
          address: 'Address',
          mail_drop_address: 'Mail Drop Address',
          country: 'Country',
          city: 'City',
          post: 'Post',
          street: 'Street',
          postcode: 'Post Code',
          voivodeship: 'Voivodeship',
          payment_due_date_change: 'Payment Due Date Change',
          currency: 'Currency',
          rate: 'Rate',
          first_name: 'First Name',
          last_name: 'Last Name',
          pesel: 'PESEL',
          payment_method: 'Payment method',
        },

        table: {
          id: 'Id',
          status: 'Status',
          transport_from: 'From',
          transport_to: 'To',
          driver: 'Driver',
          number_of_transports: 'Number of transports:',
          driver_licences: 'Driver Licences',
          contact_person: 'Contact person',
        },
        tooltip: {
          driver: 'Driver',
          truck: 'Truck',
          trailer: 'Trailer',
          phone_number: 'Phone No.',
          transport_from: 'From',
          transport_to: 'To',
        },
        label: {
          new_transport: 'New Transport',
          delete_confirmation: 'Are you sure you want to delete?',
        },
        toast: {
          please_fill_all_fields: 'Please fill all fields',
          transport_created_successfully: 'Transport Created Successfully',
          transport_updated_successfully: 'Transport Updated Successfully',
          truck_created_successfully: 'Truck Created Successfully',
          truck_updated_successfully: 'Truck Updated Successfully',
          trailer_created_successfully: 'Trailer Created Successfully',
          trailer_updated_successfully: 'Trailer Updated Successfully',
          trade_partner_created_successfully:
            'Trade Partner Created Successfully',
          trade_partner_updated_successfully:
            'Trade Partner Updated Successfully',
          start_date_can_not_be_lower:
            "Start date can't be less than or equal to the end date",
          driver_created_successfully: 'Driver Created Successfully',
          driver_updated_successfully: 'Driver Updated Successfully',
        },
        navbar: {
          configuration: 'Configuration',
          drivers: 'Drivers',
          trucks: 'Trucks',
          trailers: 'Trailers',
          trade_partners: 'Trade Partners',
        },
      },
    },
    pl: {
      weekday_x_short: {
        sunday: 'Ni',
        monday: 'Po',
        tuesday: 'Wt',
        wednesday: 'Śr',
        thursday: 'Cz',
        friday: 'Pi',
        saturday: 'So',
      },
      weekday_short: {
        sunday: 'Nie',
        monday: 'Pon',
        tuesday: 'Wto',
        wednesday: 'Śro',
        thursday: 'Czw',
        friday: 'Pią',
        saturday: 'Sob',
      },
      weekday: {
        sunday: 'Niedziela',
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
      },
      month_short: {
        january: 'Sty',
        february: 'Lut',
        march: 'Mar',
        april: 'Kwi',
        may: 'Maj',
        june: 'Cze',
        july: 'Lip',
        august: 'Sie',
        september: 'Wrz',
        october: 'Paź',
        november: 'Lis',
        december: 'Gru',
      },
      month: {
        january: 'Styczeń',
        february: 'Luty',
        march: 'Marzec',
        april: 'Kwiecień',
        may: 'Maj',
        june: 'Czerwiec',
        july: 'Lipiec',
        august: 'Sierpień',
        september: 'Wrzesień',
        october: 'Październik',
        november: 'Listopad',
        december: 'Grudzień',
      },
      button: {
        show_delayed_transports: 'Pokaż/Ukryj opóźnione transporty',
        show_completed_transports: 'Pokaż/Ukryj ukończone transporty',
        show_next_day: 'Pokaż/Ukryj następny dzień',
        show_driver_lines: 'Pokaż/Ukryj grupowanie po kierowcach',
        driver: 'Kierowca',
        truck: 'Ciężarówka',
        trailer: 'Przyczepa',
        trade_partner: 'Kontrahent',
        new_transport: 'Dodaj Transport',
        save: 'Zapisz',
        new_driver: 'Nowy Kierowca',
        new_Licence: 'Nowa Licencja',
        new_contact_person: 'Nowy kontakt',
        new_truck: 'Nowa ciężarówka',
        new_trailer: 'Nowa Przyczepa',
        new_trade_partner: 'Nowy partner handlowy',
        cancel: 'Anuluj',
        delete: 'Usuń',
      },
      input: {
        search: 'Szukaj...',
        clear: 'Wyczyść',
        driver: 'Kierowca',
        licence: 'Licencja',
        truck: 'Ciężarówka',
        trailer: 'Przyczepa',
        trade_partner: 'Kontrahent',
        phone_number: 'Numer Telefonu',
        driving_licenses: 'Prawa jazdy',
        category: 'Kategoria',
        expiry_date: 'Data ważności',
        permissions_expiry_date: 'Data ważności uprawnień',
        tachograph_expiry_date: 'Data ważności karty do tachografu',
        license_number: 'Numer Rejestracyjny',
        color: 'Kolor',
        fuel: 'Paliwo',
        year: 'Rok',
        service_expiry_date: 'Data ważności przeglądu',
        insurance_expiry_date: 'Data ważności ubezpieczenia ',
        type: 'Typ',
        capacity: 'Ładowność',
        capacity_unit: 'Jednostka ładowności',
        unit: 'Jednostka',
        company_name: 'Nazwa Firmy',
        nip: 'NIP',
        regon: 'Regon',
        email: 'Email',
        contact_persons: 'Osoby do Kontaktu',
        person: 'Osoba',
        priority: 'Priorytet',
        date: 'Data',
        time: 'Godzina',
        firstname: 'Imie',
        lastname: 'Nazwisko',
        brand: 'Marka',
        model: 'Model',
        vin: 'VIN',
        address: 'Adres',
        mail_drop_address: 'Adres do wysyłania poczty',
        country: 'Państwo',
        city: 'Miasto',
        post: 'Poczta',
        street: 'Ulica',
        postcode: 'Kod pocztowy',
        voivodeship: 'Województwo',
        payment_due_date_change: 'Zmiana terminu płatności',
        currency: 'Waluta',
        rate: 'Kurs',
        first_name: 'Imię',
        last_name: 'Nazwisko',
        pesel: 'PESEL',
        payment_method: 'Metoda płatności',
      },
      table: {
        id: 'Id',
        status: 'Status',
        transport_from: 'Z',
        transport_to: 'Do',
        driver: 'Kierowca',
        number_of_transports: 'Ilość dostaw:',
        driver_licences: 'Licencje kierowcy',
        contact_person: 'Osoba kontaktowa',
      },
      tooltip: {
        driver: 'Kierowca',
        truck: 'Ciężarówka',
        trailer: 'Przyczepa',
        phone_number: 'Nr. Telefonu',
        transport_from: 'From',
        transport_to: 'To',
      },
      label: {
        new_transport: 'Nowy Transport',
        delete_confirmation: 'Czy na pewno chcesz usunąć?',
      },
      toast: {
        please_fill_all_fields: 'Proszę uzupełnić wszystkie pola',
        transport_created_successfully: 'Utworzono pomyślnie',
        transport_updated_successfully: 'Zaktualizowano pomyślnie',
        truck_created_successfully: 'Utworzono pomyślnie',
        truck_updated_successfully: 'Zaktualizowano pomyślnie',
        trailer_created_successfully: 'Utworzono pomyślnie',
        trailer_updated_successfully: 'Zaktualizowano pomyślnie',
        trade_partner_created_successfully: 'Utworzono pomyślnie',
        trade_partner_updated_successfully: 'Zaktualizowano pomyślnie',
        start_date_can_not_be_lower:
          'Początkowa data nie może być mniejsza bądź równa końcowej',
        driver_created_successfully: 'Utworzono pomyślnie',
        driver_updated_successfully: 'Zaktualizowano pomyślnie',
      },
      navbar: {
        configuration: 'Konfiguracja',
        drivers: 'Kierowcy',
        trucks: 'Ciężarówki',
        trailers: 'Przyczepy',
        trade_partners: 'Kontrahenci',
      },
    },
  },
});

export default i18n;
