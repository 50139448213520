import React, { useState } from 'react';
import TimePicker from '@mui/lab/TimePicker';
import { Popover, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import { DatePicker } from '../DatePicker/DatePicker';
import styles from './Select.module.scss';

interface iSelectDateTimeProps {
  value: Date;
  onChange: (date: Date) => void;
}

export const SelectDateTime = ({ value, onChange }: iSelectDateTimeProps) => {
  const [anchorElDate, setAnchorElDate] = useState<HTMLDivElement | null>(null);

  const handleDateClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleDatePickerClose = () => {
    setAnchorElDate(null);
  };

  const onDateChange = (newDate: Date) => {
    setAnchorElDate(null);
    const dayjsCurrentDate = dayjs(value);
    const dayjsNewDate = dayjs(newDate);

    const date = dayjsCurrentDate
      .year(dayjsNewDate.year())
      .month(dayjsNewDate.month())
      .date(dayjsNewDate.date())
      .toDate();

    onChange(date);
  };

  const onTimeChange = (newTime: Date | null) => {
    if (!newTime) {
      return;
    }

    const dayjsCurrentDate = dayjs(value);
    const dayjsNewDate = dayjs(newTime);

    const date = dayjsCurrentDate
      .hour(dayjsNewDate.hour())
      .minute(dayjsNewDate.minute())
      .toDate();

    onChange(date);
  };

  const openDatePicker = Boolean(anchorElDate);
  const datePickerId = openDatePicker ? 'datepicker-popover' : undefined;

  return (
    <div className={styles['DateTimeContainer']}>
      <div
        className={styles['SelectContainer']}
        onClick={(e) => handleDateClick(e)}
      >
        {getFormattedDateWithNamedMonth(value)}
      </div>
      <Popover
        id={datePickerId}
        open={openDatePicker}
        anchorEl={anchorElDate}
        onClose={() => handleDatePickerClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker value={value} onChange={(date) => onDateChange(date)} />
      </Popover>
      <TimePicker
        value={value}
        onChange={(newValue) => {
          onTimeChange(newValue);
        }}
        ampm={false}
        className={styles['TimePicker']}
        renderInput={(props) => (
          <TextField
            size="small"
            placeholder={t('input.time')}
            value={value}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            sx={{
              borderRadius: '0',
              marginLeft: '4px',
            }}
          />
        )}
      />
    </div>
  );
};
