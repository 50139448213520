/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { DrivingLicenceStore } from '../store/employeeStore/DrivingLicenseStore';
import { EmployeeStore } from '../store/employeeStore/EmployeeStore';

const mockDrivingLicense_1: DrivingLicenceStore[] = [
  new DrivingLicenceStore(1, 'A', dayjs().add(15, 'month').toDate()),
  new DrivingLicenceStore(2, 'B', dayjs().add(21, 'month').toDate()),
];

const mockDrivingLicense_2: DrivingLicenceStore[] = [
  new DrivingLicenceStore(1, 'C', dayjs().add(4, 'year').toDate()),
  new DrivingLicenceStore(2, 'D', dayjs().add(5, 'year').toDate()),
];

const mockDrivingLicense_3: DrivingLicenceStore[] = [
  new DrivingLicenceStore(1, 'C+E', dayjs().add(15, 'day').toDate()),
  new DrivingLicenceStore(2, 'T', dayjs().add(21, 'day').toDate()),
];

const mockDrivingLicense_4: DrivingLicenceStore[] = [
  new DrivingLicenceStore(1, 'C1', dayjs().add(3, 'year').toDate()),
  new DrivingLicenceStore(2, 'D1', dayjs().add(2, 'year').toDate()),
];

export const mockEmployee_1: EmployeeStore = new EmployeeStore(
  1,
  'Dariusz',
  'Woszczyna',
  '94041702515',
  'ABC123',
  '+48 768 856 884',
  mockDrivingLicense_1,
  dayjs().add(4, 'month').toDate(),
  dayjs().add(5, 'month').toDate()
);

export const mockEmployee_2: EmployeeStore = new EmployeeStore(
  2,
  'Mariusz',
  'Kierowca',
  '72071409017',
  'DBT555',
  '+48 768 856 000',
  mockDrivingLicense_2,
  dayjs().add(2, 'year').toDate(),
  dayjs().add(6, 'month').toDate()
);

export const mockEmployee_3: EmployeeStore = new EmployeeStore(
  3,
  'Wojciech',
  'Kosmonauta',
  '89030707790',
  'VOY411',
  '+48 768 129 000',
  mockDrivingLicense_3,
  dayjs().add(2, 'month').toDate(),
  dayjs().add(6, 'year').toDate()
);

export const mockEmployee_4: EmployeeStore = new EmployeeStore(
  4,
  'Kamil',
  'Zdun',
  '88030707472',
  'KBX555',
  '+48 768 899 001',
  mockDrivingLicense_4,
  dayjs().add(4, 'year').toDate(),
  dayjs().add(2, 'month').toDate()
);

export const mockEmployees: EmployeeStore[] = [
  mockEmployee_1,
  mockEmployee_2,
  mockEmployee_3,
  mockEmployee_4,
];

export const initEmployeesLocalStore = () => {
  const initialized = localStorage.getItem('employees');
  if (!initialized) {
    localStorage.setItem('employees', JSON.stringify(mockEmployees));
  }
};
