import { makeAutoObservable, set } from 'mobx';
import { AddressSnapshot, AddressStore } from './AddressStore';
import { ContactPerson, ContactPersonSnapshot } from './ContactPerson';


export type TradePartnerSnapshot = {
  id: number;
  companyName: string;
  NIP: string;
  REGON: string;
  address: AddressSnapshot;
  mailDropAddress: AddressSnapshot;
  paymentDueDate: string;
  paymentMethod: string;
  currency: string;
  email: string;
  phoneNumber: string;
  contactPersons: ContactPersonSnapshot[];
  rate: string;
};

export class TradePartnerStore {
  constructor(
    private id: number,
    private companyName: string,
    private NIP: string,
    private REGON: string,
    private address: AddressStore,
    private mailDropAddress: AddressStore,
    private paymentDueDate: Date,
    private paymentMethod: string,
    private currency: string,
    private email: string,
    private phoneNumber: string,
    private contactPersons: ContactPerson[],
    private rate: number
  ) {
    makeAutoObservable(this);
  }

  getId() {
    return this.id;
  }

  getCompanyName() {
    return this.companyName;
  }

  getNIP() {
    return this.NIP;
  }

  getREGON() {
    return this.REGON;
  }

  getAddress() {
    return this.address;
  }

  getMailDropAddress() {
    return this.mailDropAddress;
  }

  getPaymentDueDate() {
    return this.paymentDueDate;
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  getCurrency() {
    return this.currency;
  }

  getEmail() {
    return this.email;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getContactPersons() {
    return this.contactPersons;
  }

  getRate() {
    return this.rate;
  }

  updateByKey(value: string, key?: string) {
    const copy: any = {
      ...this,
    };
    if (key) copy[key] = value;
    set(this, {
      ...copy,
    });
  }

  static fromSnapshot(snapshot: TradePartnerSnapshot) {
    return new TradePartnerStore(
      snapshot.id,
      snapshot.companyName,
      snapshot.NIP,
      snapshot.REGON,
      AddressStore.fromSnapshot(snapshot.address),
      AddressStore.fromSnapshot(snapshot.mailDropAddress),
      new Date(Date.parse(snapshot.paymentDueDate)),
      snapshot.paymentMethod,
      snapshot.currency,
      snapshot.email,
      snapshot.phoneNumber,
      snapshot.contactPersons.map((x) => ContactPerson.fromSnapshot(x)),
      Number(snapshot.rate),
    );
  }

  snapshot(): TradePartnerSnapshot {
    return {
      id: this.id,
      companyName: this.companyName,
      NIP: this.NIP,
      REGON: this.REGON,
      address: this.address.snapshot(),
      mailDropAddress: this.mailDropAddress.snapshot(),
      paymentDueDate: this.paymentDueDate.toUTCString(),
      paymentMethod: this.paymentMethod,
      currency: this.currency,
      email: this.email,
      phoneNumber: this.phoneNumber,
      contactPersons: this.contactPersons.map((x) => x.snapshot()),
      rate: this.rate.toString()
    };
  }

}
