import { makeAutoObservable, set } from 'mobx';


export type TrailerSnapshot = {
  id: number;
  brand: string;
  licenseNumber: string;
  color: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
  type: string;
  capacity: string;
  capacityUnit: string;
};


export class TrailerStore {
  constructor(
    private id: number,
    private brand: string,
    private licenseNumber: string,
    private color: string,
    private year: number,
    private vin: string,
    private serviceExpiryDate: Date,
    private insuranceExpiryDate: Date,
    private type: string,
    private capacity: number,
    private capacityUnit: string
  ) {
    makeAutoObservable(this);
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number): void {
    this.id = id;
  }

  public getBrand(): string {
    return this.brand;
  }

  public setBrand(brand: string): void {
    this.brand = brand;
  }

  public getLicenseNumber(): string {
    return this.licenseNumber;
  }

  public setLicenseNumber(licenseNumber: string): void {
    this.licenseNumber = licenseNumber;
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): void {
    this.color = color;
  }

  public getYear(): number {
    return this.year;
  }

  public setYear(year: number): void {
    this.year = year;
  }

  public getVin(): string {
    return this.vin;
  }

  public setVin(vin: string): void {
    this.vin = vin;
  }

  public getServiceExpiryDate(): Date {
    return this.serviceExpiryDate;
  }

  public setServiceExpiryDate(serviceExpiryDate: Date): void {
    this.serviceExpiryDate = serviceExpiryDate;
  }

  public getInsuranceExpiryDate(): Date {
    return this.insuranceExpiryDate;
  }

  public setInsuranceExpiryDate(insuranceExpiryDate: Date): void {
    this.insuranceExpiryDate = insuranceExpiryDate;
  }

  public getType(): string {
    return this.type;
  }

  public setType(type: string): void {
    this.type = type;
  }

  public getCapacity(): number {
    return this.capacity;
  }

  public setCapacity(capacity: number): void {
    this.capacity = capacity;
  }

  public getCapacityUnit(): string {
    return this.capacityUnit;
  }

  public setCapacityUnit(capacityUnit: string): void {
    this.capacityUnit = capacityUnit;
  }

  updateByKey(value: string, key?: string) {
    const copy: any = {
      ...this,
    };
    if (key) copy[key] = value;
    set(this, {
      ...copy,
    });
  }

  static fromSnapshot(snapshot: TrailerSnapshot) {
    return new TrailerStore(
      snapshot.id,
      snapshot.brand,
      snapshot.licenseNumber,
      snapshot.color,
      Number(snapshot.year),
      snapshot.vin,
      new Date(Date.parse(snapshot.serviceExpiryDate)),
      new Date(Date.parse(snapshot.insuranceExpiryDate)),
      snapshot.type,
      Number(snapshot.capacity),
      snapshot.capacityUnit
    );
  }

  snapshot(): TrailerSnapshot {
    return {
      id: this.id,
      brand: this.brand,
      licenseNumber: this.licenseNumber,
      color: this.color,
      year: this.year.toString(),
      vin: this.vin,
      serviceExpiryDate: this.serviceExpiryDate.toUTCString(),
      insuranceExpiryDate: this.insuranceExpiryDate.toUTCString(),
      type: this.type,
      capacity: this.capacity.toString(),
      capacityUnit: this.capacityUnit
    };
  }

}
