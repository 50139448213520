import { makeAutoObservable } from 'mobx';
import { InMemoryService } from '../../service/InMemoryService';
import { MockService } from '../../service/MockService';
import { statusType } from '../../types/Types';
import { TruckStore } from './TruckStore';
import { TruckService } from '../../service/Service';

class TrucksStore {
  constructor(
    private httpService: TruckService,
    private trucks: TruckStore[] = [],
    private status: statusType = 'complete'
  ) {
    makeAutoObservable(this);
  }

  getTrucks() {
    return this.trucks;
  }

  async fetchTrucks() {
    try {
      this.status = 'loading';
      this.trucks = await this.httpService.getTrucks();
      this.status = 'complete';
    } catch (error) {
      this.status = 'error';
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  getById(id: number) {
    const truck = this.trucks.find((x) => x.getId() === id);
    return truck;
  }

  getNextId() {
    const max = Math.max(...this.trucks.map((x) => x.getId()));
    return max + 1;
  }

  addTruck(truck: TruckStore) {
    this.httpService.createTruck(truck);
    this.trucks.push(truck);
  }

  updateTruck(truck: TruckStore) {
    const index = this.trucks.findIndex((x) => x.getId() === truck.getId());
    if (index > -1) {
      this.httpService.updateTruck(truck);
      this.trucks[index] = truck;
    }
  }

  deleteTruck(truckID: number) {
    this.httpService.deleteTruck(truckID);
    this.trucks = this.trucks.filter((x) => x.getId() !== truckID);
  }
}

export const trucksStore = new TrucksStore(new InMemoryService());
