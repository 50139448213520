/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useEffect, useState } from 'react';

import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { DatePicker, LoadingButton } from '@mui/lab';
import { AddRounded, SaveOutlined } from '@mui/icons-material';
import { getFormattedDateWithNamedMonth } from '../../utils/DateUtils';
import styles from './Trailer.module.scss';
import { TrailerForm } from '../../containers/TrailerDetailsPage';
import {
  FormButtonContainer,
  FormOutlinedInput,
  FromTextInput,
} from '../style/form';
import { allNotEmpty } from '../../utils/Check';

interface Props {
  brand: string;
  licenseNumber: string;
  color: string;
  year: string;
  vin: string;
  serviceExpiryDate: string;
  insuranceExpiryDate: string;
  type: string;
  capacity: string;
  capacityUnit: string;
  onSave(form: TrailerForm): void;
  mode: 'new' | 'edit';
  loading: boolean;
}

export const TrailerDetailsForm: FunctionComponent<Props> = ({
  brand,
  licenseNumber,
  color,
  year,
  vin,
  serviceExpiryDate,
  insuranceExpiryDate,
  type,
  capacity,
  capacityUnit,
  onSave,
  mode,
  loading,
}) => {
  const [_brand, setBrand] = useState(brand);
  const [_licenseNumber, setLicenseNumber] = useState(licenseNumber);
  const [_color, setColor] = useState(color);
  const [_year, setYear] = useState(year);
  const [_vin, setVin] = useState(vin);
  const [_serviceExpiryDate, setServiceExpiryDate] =
    useState(serviceExpiryDate);
  const [_insuranceExpiryDate, setInsuranceExpiryDate] =
    useState(insuranceExpiryDate);
  const [_type, setType] = useState(type);
  const [_capacity, setCapacity] = useState(capacity);
  const [_capacityUnit, setCapacityUnit] = useState(capacityUnit);
  const navigate = useNavigate();

  useEffect(() => {
    refreshState();
  }, [
    brand,
    licenseNumber,
    color,
    year,
    vin,
    serviceExpiryDate,
    insuranceExpiryDate,
    type,
    capacity,
    capacityUnit,
  ]);
  const refreshState = () => {
    setBrand(brand);
    setLicenseNumber(licenseNumber);
    setColor(color);
    setYear(year);
    setVin(vin);
    setServiceExpiryDate(serviceExpiryDate);
    setInsuranceExpiryDate(insuranceExpiryDate);
    setType(type);
    setCapacity(capacity);
    setCapacityUnit(capacityUnit);
  };

  const handleInputValue =
    (f: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
      f(e.target.value);

  return (
    <form style={{ width: '40%' }}>
      <span className={styles['SectionTitle']}>{t('input.truck')}</span>
      <section id="Driver Details" className={styles['SectionContainer']}>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.brand')}:</label>
          <FormOutlinedInput
            size="small"
            error={_brand === ''}
            onChange={handleInputValue(setBrand)}
            placeholder={t('input.brand')}
            value={_brand}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.license_number')}:
          </label>
          <FormOutlinedInput
            size="small"
            type="small"
            error={_licenseNumber === ''}
            onChange={handleInputValue(setLicenseNumber)}
            placeholder={t('input.license_number')}
            value={_licenseNumber}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.color')}:</label>
          <FormOutlinedInput
            size="small"
            type="small"
            error={_color === ''}
            onChange={handleInputValue(setColor)}
            placeholder={t('input.color')}
            value={_color}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.year')}:</label>
          <FormOutlinedInput
            inputProps={{ min: 0 }}
            size="small"
            type="number"
            error={_year === ''}
            onChange={handleInputValue(setYear)}
            placeholder={t('input.year')}
            value={_year}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.vin')}:</label>
          <FormOutlinedInput
            size="small"
            type="snall"
            error={_vin === ''}
            onChange={handleInputValue(setVin)}
            placeholder={t('input.vin')}
            value={_vin}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.service_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && setServiceExpiryDate(e)}
            value={
              _serviceExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(_serviceExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.service_expiry_date')}
              />
            )}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>
            {t('input.insurance_expiry_date')}:
          </label>
          <DatePicker
            mask="__.__.____"
            inputFormat="DD.MM.YYYY"
            onChange={(e) => e && setInsuranceExpiryDate(e)}
            value={
              _insuranceExpiryDate &&
              getFormattedDateWithNamedMonth(
                new Date(Date.parse(_insuranceExpiryDate))
              )
            }
            renderInput={(params) => (
              <FromTextInput
                {...params}
                helperText={t('input.insurance_expiry_date')}
              />
            )}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.type')}:</label>
          <FormOutlinedInput
            size="small"
            type="snall"
            error={_type === ''}
            onChange={handleInputValue(setType)}
            placeholder={t('input.type')}
            value={_type}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.capacity')}:</label>
          <FormOutlinedInput
            inputProps={{ min: 0 }}
            size="small"
            type="number"
            error={_capacity === ''}
            onChange={handleInputValue(setCapacity)}
            placeholder={t('input.capacity')}
            value={_capacity}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
        <div className={styles['LabelWithInput']}>
          <label className={styles['Label']}>{t('input.capacity_unit')}:</label>
          <FormOutlinedInput
            size="small"
            type="snall"
            error={_capacityUnit === ''}
            onChange={handleInputValue(setCapacityUnit)}
            placeholder={t('input.capacity_unit')}
            value={_capacityUnit}
            sx={{ borderRadius: '0', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
          />
        </div>
      </section>
      <FormButtonContainer>
        <LoadingButton
          disabled={
            !allNotEmpty([
              _brand,
              _licenseNumber,
              _color,
              _year,
              _vin,
              _serviceExpiryDate,
              _insuranceExpiryDate,
              _type,
              _capacity,
              _capacityUnit,
            ])
          }
          loading={loading}
          loadingPosition="start"
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() =>
            onSave({
              brand: _brand,
              licenseNumber: _licenseNumber,
              color: _color,
              year: _year,
              vin: _vin,
              serviceExpiryDate: _serviceExpiryDate,
              insuranceExpiryDate: _insuranceExpiryDate,
              type: _type,
              capacity: _capacity,
              capacityUnit: _capacityUnit,
            })
          }
          startIcon={mode === 'new' ? <AddRounded /> : <SaveOutlined />}
        >
          {mode === 'new' ? t('button.new_trailer') : t('button.save')}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            borderRadius: '0',
            width: '170px',
            alignSelf: 'center',

            display: 'flex',
            margin: 'auto',
          }}
          onClick={() => navigate(-1)}
        >
          {t('button.cancel')}
        </LoadingButton>
      </FormButtonContainer>
    </form>
  );
};
