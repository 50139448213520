import { observer } from 'mobx-react-lite';
import React from 'react';
import { TradePartnersTable } from '../components/tradePartners/TradePartnersTable';

import { tradePartnersStore } from '../store/tradePartners/TradePartnersStore';

export const TradePartnersSettingsPage = observer(() => {
  const tradePartners = tradePartnersStore.getTradePartners();
  return <TradePartnersTable tradePartners={[...tradePartners]} />;
});
